import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsTeamComplete, getPlayerById} from "modules/selectors";
import {removePlayerFromTeam} from "modules/actions";
import {Exist} from "components/Exist";
import styled from "styled-components";
import {PureButton} from "components/styled";
import {IconInjury, IconRemove, IconRollback} from "components/Icons";
import {media} from "assets/media";
import {useMediaQuery} from "@mui/material";
import {PlayerStatus} from "modules/types/enums";

const ControlButton = styled(PureButton)`
	background: #dddddd;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -14px;
	left: 4px;
	z-index: 6;

	&.under {
		z-index: 4;
	}

	&:hover {
		background: #959595;
	}

	@media screen and (max-width: ${media.md}) {
		width: 12px;
		height: 12px;
	}
`;

interface IPLayerActionsProps {
	playerId: number;
	isRemoveDisabled?: boolean;
	isTransferDisabled?: boolean;
	onClickTransfer?: (playerID: number) => void;
}

export const PlayerActions: React.FC<IPLayerActionsProps> = ({
	playerId,
	isRemoveDisabled,
	isTransferDisabled,
	onClickTransfer,
}) => {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(media.lessThanMD);
	const actionIconSize = isMobile ? 8 : 14;

	const player = useSelector(getPlayerById)(playerId);
	const isInjured = player?.status === PlayerStatus.Injured;

	const onRemovePlayer = useCallback(() => {
		dispatch(removePlayerFromTeam(playerId));
	}, [dispatch, playerId]);

	const onTransferPlayer = useCallback(() => {
		if (onClickTransfer) {
			onClickTransfer(playerId);
		}
	}, [onClickTransfer, playerId]);

	const isTeamComplete = useSelector(getIsTeamComplete);

	if (!isTeamComplete) {
		return (
			<Exist when={!player.locked}>
				<ControlButton onClick={onRemovePlayer}>
					<IconRemove width={actionIconSize} height={actionIconSize} />
				</ControlButton>
			</Exist>
		);
	}

	return (
		<React.Fragment>
			<Exist when={isInjured}>
				<ControlButton className="under" disabled={true}>
					<IconInjury width={24} height={24} />
				</ControlButton>
			</Exist>
			<Exist when={Boolean(!isRemoveDisabled && !player.locked)}>
				<ControlButton onClick={onRemovePlayer}>
					<IconRemove width={actionIconSize} height={actionIconSize} />
				</ControlButton>
			</Exist>
			<Exist when={Boolean(!isTransferDisabled && !player.locked)}>
				<ControlButton onClick={onTransferPlayer}>
					<IconRollback width={actionIconSize + 1} height={actionIconSize} />
				</ControlButton>
			</Exist>
		</React.Fragment>
	);
};
