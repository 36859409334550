import React, {forwardRef, useCallback} from "react";
import styled from "styled-components";
import {FieldWrapper} from "components/styled";
import {FormationPicker} from "components/Team/FormationPicker";
import {Legend} from "components/Team/Legend";
import {FieldRow, PlayingField} from "components/Team/styled";
import {get, range} from "lodash";
import {Player} from "components";
import {useDispatch, useSelector} from "react-redux";
import {
	getShownStats,
	getTeam,
	getTeamForGame,
	isClassicGame,
	isGameDayGame,
	isGameDayTeamLockout,
	isReachedTransferOutLimit,
} from "modules/selectors";
import {getIsTeamLockout} from "modules/selectors/lockout";
import {useNavigate, useParams} from "react-router-dom";
import {removePlayerFromTeam, tradeOutPlayer} from "modules/actions";
import {Exist} from "components/Exist";

const Wrapper = styled.div`
	position: relative;
`;

export const LegendContainer = styled.div`
	width: 100%;
	position: absolute;
	bottom: 18px;
	display: flex;
	justify-content: center;

	@media (max-width: 560px) {
		bottom: 16px;
	}
`;

interface IProps {
	onClickEmptyCell: (position: number) => void;
}

const ComponentTeamField = forwardRef<HTMLDivElement, IProps>(
	({onClickEmptyCell, ...props}, ref) => {
		const dispatch = useDispatch();
		const navigate = useNavigate();
		const params = useParams();
		const id = params.id;
		const isClassic = useSelector(isClassicGame);
		const isGameDay = useSelector(isGameDayGame);
		const classicTeam = useSelector(getTeam);
		const team = useSelector(getTeamForGame);
		const isLockout = useSelector(getIsTeamLockout);
		const isGameDayLockout = useSelector(isGameDayTeamLockout);
		const isRemoveDisabled = isGameDay ? isGameDayLockout : isLockout;
		const isTransferOutDisabled = useSelector(isReachedTransferOutLimit) || !isLockout;
		const formationClass = classicTeam?.formation?.includes("5") ? "wide" : "";
		const shownStats = useSelector(getShownStats);
		const onClickEmptyPosition = useCallback(
			(position: number) => () => {
				onClickEmptyCell(position);
			},
			[onClickEmptyCell]
		);

		const onClickTransfer = useCallback(
			(playerID: number) => {
				if (id) {
					dispatch(removePlayerFromTeam(playerID));
					dispatch(tradeOutPlayer(playerID));
					return;
				}
				navigate(`transfers/${playerID}`);
			},
			[navigate, dispatch, id]
		);

		const fieldRowClassName = isGameDay ? "game-day" : "classic";

		const players = range(1, 5).map((row_index) => (
			<FieldRow key={row_index} className={`field-row ${fieldRowClassName}`}>
				{get<number[], number[]>(team?.lineup, row_index, []).map(
					(playerID, cell_index) => (
						<Player
							isRemoveDisabled={isRemoveDisabled}
							isTransferDisabled={isGameDay ? true : isTransferOutDisabled}
							onClickEmptyCell={onClickEmptyPosition(row_index)}
							onClickTransfer={onClickTransfer}
							key={cell_index}
							playerID={playerID}
							showStats={shownStats}
							isPublicTeam={true}
						/>
					)
				)}
			</FieldRow>
		));

		return (
			<React.Fragment>
				<Wrapper ref={ref} {...props}>
					<FieldWrapper>
						<PlayingField className={formationClass}>
							<Exist when={isClassic}>
								<FormationPicker activeFormation={classicTeam?.formation} />
							</Exist>
							{players}
							<LegendContainer>
								<Legend />
							</LegendContainer>
						</PlayingField>
					</FieldWrapper>
				</Wrapper>
			</React.Fragment>
		);
	}
);

ComponentTeamField.displayName = "TeamField";

export const TeamField = ComponentTeamField;
