import React from "react";
import {useSelector} from "react-redux";
import {getProject, getSport} from "modules/selectors";
import {HeaderNavLink} from "components/styled";

interface IProps {
	to: string;
	end?: boolean;
	className?: string;
}

export const HeaderSportLink: React.FC<IProps> = ({to, end, children, className}) => {
	const sport = useSelector(getSport);
	const project = useSelector(getProject);

	const link = `/${project}/${sport}${to}`;
	return (
		<HeaderNavLink end={end} to={link} className={className}>
			{children}
		</HeaderNavLink>
	);
};
