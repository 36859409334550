import * as React from "react";
import {HOCTimer} from "./HOCTimer";
import {Exist} from "components/Exist";
import {identity, padStart} from "lodash";
import styled from "styled-components";
import {media} from "assets/media";

const TimerContainer = styled.div`
	color: #fff;
	display: inline-flex;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	position: relative;
	line-height: 1;
	margin-top: 4px;
	justify-content: center;
	gap: 6px;
`;

const Flex = styled.div`
	display: inline-flex;
`;

const TimeUnit = styled(Flex)`
	flex-flow: column;
	padding: 0 3px;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
`;

const TimeUnitItem = styled.div`
	color: #ffffff;
	font-size: 30px;
	letter-spacing: 0;
	line-height: 30px;
	font-weight: normal;

	@media screen and ${media.lessThanMD} {
		font-size: 20px;
		line-height: 14px;
	}
`;

const TimeUnitLabel = styled.div`
	color: #ffffff;
	font-size: 10px;
	letter-spacing: 0;
	line-height: 10px;
	text-align: center;
	margin-top: 4px;
	font-weight: normal;

	@media screen and ${media.lessThanMD} {
		font-size: 8px;
		line-height: 10px;
	}
`;

interface IProps {
	date?: string | number | Date;
	onComplete?: () => void;
	onTick?: (time_to_end: number) => void;
}

export const Timer: React.FC<IProps> = ({date, onComplete, onTick, ...rest}) => {
	return date ? (
		<HOCTimer onTick={onTick} onComplete={onComplete} date={date}>
			{({days, hours, minutes, seconds}) => {
				const has_days_or_hours = [days, hours].some(identity);

				return (
					<TimerContainer {...rest}>
						<Exist when={Boolean(days)}>
							<TimeUnit>
								<TimeUnitItem>{padStart(String(days), 2, "0")}</TimeUnitItem>
								<TimeUnitLabel>DAYS</TimeUnitLabel>
							</TimeUnit>
						</Exist>
						<Exist when={has_days_or_hours}>
							<TimeUnit>
								<TimeUnitItem>{padStart(String(hours), 2, "0")}</TimeUnitItem>
								<TimeUnitLabel>HRS</TimeUnitLabel>
							</TimeUnit>
						</Exist>
						<TimeUnit>
							<TimeUnitItem>{padStart(String(minutes), 2, "0")}</TimeUnitItem>
							<TimeUnitLabel>MINS</TimeUnitLabel>
						</TimeUnit>
						<TimeUnit>
							<TimeUnitItem>{padStart(String(seconds), 2, "0")}</TimeUnitItem>
							<TimeUnitLabel>SECS</TimeUnitLabel>
						</TimeUnit>
					</TimerContainer>
				);
			}}
		</HOCTimer>
	) : null;
};
