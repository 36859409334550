import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPlayerById, getSquadById} from "modules/selectors";
import {currencyFormatDecimal} from "modules/utils";
import {resetTradeByIndex} from "modules/actions";
import {PlayerJersey} from "components/PlayerJersey";
import {Exist} from "components/Exist";
import {PureButton} from "components/styled";
import {IconRollback, IconTransfer} from "components/Icons";
import styled from "styled-components";
import {media} from "assets/media";
import {getDisplayName} from "modules/utils/players";

const PlayerPrice = styled.p`
	color: ${({theme}) => theme.text_color};
	font-size: 16px;
	letter-spacing: 0;
	line-height: 19px;
	text-transform: uppercase;
	margin-left: auto;

	&.reverse {
		margin-right: auto;
		margin-left: 0;
	}

	@media screen and ${media.lessThanMD} {
		display: none;
	}

	&.invisible {
		visibility: hidden;
	}
`;

const PlayerInfo = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	padding: 6px 0;
	box-sizing: border-box;

	img {
		top: 0;
		height: 100%;
		width: auto !important;
		margin: 0 14px;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin: 0;

		b {
			font-size: 13px;
		}
	}
	.price {
		text-transform: uppercase;
	}

	&.reverse {
		text-align: right;
		flex-direction: row-reverse;
	}
`;

const PlayerWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 40px;

	.price {
		//margin-left: auto;
	}

	&.reverse {
		flex-direction: row-reverse;

		.price {
			//margin-right: auto;
			//margin-left: 0;
		}
	}
`;

interface ITransferProps {
	tradeIn?: number;
	tradeOut?: number;
	tradeIndex: number;
	hasAction?: boolean;
}

export const TransferRow: React.FC<ITransferProps> = ({
	tradeIn,
	tradeOut,
	tradeIndex,
	hasAction = false,
}) => {
	const dispatch = useDispatch();
	const tradeInPlayerEntity = useSelector(getPlayerById)(Number(tradeIn));
	const tradeOutPlayerEntity = useSelector(getPlayerById)(Number(tradeOut));

	const tradeInPlayerSquad = useSelector(getSquadById)(tradeInPlayerEntity?.squadId);
	const tradeOutPlayerSquad = useSelector(getSquadById)(tradeOutPlayerEntity?.squadId);

	const inPrice = currencyFormatDecimal(tradeInPlayerEntity?.cost, true);
	const outPrice = currencyFormatDecimal(tradeOutPlayerEntity?.cost, true);

	const rollbackTransfer = useCallback(() => {
		dispatch(resetTradeByIndex(tradeIndex));
	}, [dispatch, tradeIndex]);

	if (!tradeOutPlayerEntity) {
		return null;
	}

	return (
		<tr>
			<td className="out">
				<PlayerWrapper>
					<PlayerInfo>
						<PlayerJersey squadId={tradeOutPlayerEntity?.squadId} />
						<div>
							<p>
								<b>{getDisplayName(tradeOutPlayerEntity, !hasAction)}</b>
							</p>
							<Exist when={hasAction}>
								<p className="desktop-only">{tradeOutPlayerSquad?.name}</p>
							</Exist>
							<p className="price mobile-only">RM{outPrice}</p>
						</div>
					</PlayerInfo>
					<PlayerPrice className="price">RM{outPrice}</PlayerPrice>
				</PlayerWrapper>
			</td>

			<td className="icon">
				<IconTransfer />
			</td>

			<Exist when={Boolean(tradeInPlayerEntity?.id)}>
				<td className="in">
					<PlayerWrapper className="reverse">
						<PlayerInfo className="reverse">
							<PlayerJersey squadId={tradeInPlayerEntity?.squadId} />
							<div>
								<p>
									<b>{getDisplayName(tradeInPlayerEntity, !hasAction)}</b>
								</p>
								<Exist when={hasAction}>
									<p className="desktop-only">{tradeInPlayerSquad?.name}</p>
								</Exist>
								<p className="price mobile-only">RM{inPrice}</p>
							</div>
						</PlayerInfo>
						<PlayerPrice className="price reverse">RM{inPrice}</PlayerPrice>
					</PlayerWrapper>
				</td>
			</Exist>

			<Exist when={!tradeInPlayerEntity?.id}>
				<td className="in">
					<PlayerWrapper className="reverse">
						<PlayerInfo className="reverse">
							<PlayerJersey squadId={0} />
							<div>
								<p>
									<b>Not Selected</b>
								</p>
								{/*<p className="mobile-only">RMN/A</p>*/}
								<p className="desktop-only">-</p>
							</div>
						</PlayerInfo>
						<PlayerPrice className="price reverse invisible">RMN/A</PlayerPrice>
					</PlayerWrapper>
				</td>
			</Exist>
			<Exist when={Boolean(hasAction)}>
				<td className="reset">
					<PureButton onClick={rollbackTransfer}>
						<IconRollback />
					</PureButton>
				</td>
			</Exist>
		</tr>
	);
};
