import styled from "styled-components";
import {IModalContentStyledProps} from "modules/types/modal";
import {media} from "assets/media";
import {Link} from "react-router-dom";
import {PureButton} from "components/styled";

export const ModalBackdrop = styled.div`
	box-sizing: border-box;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	padding-top: 140px;
	padding-bottom: 24px;
	z-index: 100;
	height: 100vh;
	max-height: 100vh;

	display: flex;
	justify-content: center;
	align-items: flex-start;
	overflow: auto;

	&.fade {
		background: rgba(0, 0, 0, 0.5);
	}

	@media screen and (max-width: ${media.sm}) {
		padding-top: 0;
		padding-bottom: 0;
		align-items: flex-start;
	}
`;
export const ModalBackdropClickHandler = styled(PureButton)`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: pointer;
	z-index: 4;
	width: 100%;
	height: 100%;
`;
export const ModalContent = styled.div<IModalContentStyledProps>`
	position: relative;
	z-index: 12;
	box-sizing: border-box;
	background-color: #fefefe;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
	max-width: ${(props) => props.maxWidth || "600px"};
	width: 100%;
	padding: 24px;
	border-radius: 4px;

	@media screen and (max-width: ${media.sm}) {
		padding-top: 24px;
		align-items: flex-start;
		min-height: unset;
		margin: 24px;

		&.full {
			min-height: 100vh;
			margin: 0;
		}
	}

	&.no-padding {
		padding: 0;
	}
`;

export const ModalClose = styled(Link)`
	position: absolute;
	right: 14px;
	top: 14px;
`;

export const ModalCloseButton = styled(PureButton)`
	position: absolute;
	right: 14px;
	top: 14px;
`;

export const ModalActions = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 24px;
	padding: 20px 0;

	button,
	a {
		box-sizing: border-box;
		max-width: unset;
		width: 100%;
		text-align: center;
		max-height: 44px;
		font-size: 13px;
	}

	@media screen and (max-width: ${media.md}) {
		flex-direction: column;
		align-items: center;
		padding-bottom: 0;
		gap: 20px;
	}
`;

export const ModalHeaderTitle = styled.h3`
	color: ${({theme}) => theme.text_color};
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
	text-align: center;
	margin-bottom: 0;
`;

export const ModalText = styled.p`
	color: ${({theme}) => theme.text_color};
	font-size: 16px;
	letter-spacing: 0;
	line-height: 24px;
	text-align: center;
`;
