import React, {useCallback} from "react";
import {ISortBy} from "modules/types/team";
import {useDispatch, useSelector} from "react-redux";
import {
	getIsGameDayTeamCompleted,
	getIsTeamPosFilledGameDay,
	getPlayerById,
	getPlayerInTeamGameDayCheckSelector,
	isGameDayTeamLockout,
	isTeamFullFilledGameDay,
} from "modules/selectors";
import styled from "styled-components";

import {PlayerPoolName, PlayerPoolStatus, PlayersPoolCell} from "./styled";
import {Exist, IconPlus, IconRemove, SquadLogo} from "components";
import {PureButton} from "components/styled";
import {pickPlayerToTeam, removePlayerFromTeam} from "modules/actions";
import {openPlayerInfoModal} from "modules/actions/modals";
import IconInfo from "assets/images/icons/info.svg";
import {getDisplayName} from "modules/utils/players";
import {IncompleteTeamGameDayActions} from "components/Team/PlayerPoll/IncompleteTeamGameDayActions";

const PlayerContainer = styled.div`
	display: flex;
	align-items: center;

	${PlayersPoolCell} {
		height: 45px;
		border-top: 1px solid #dddddd;
	}

	&.in-team {
		background: #ffffff;
	}
`;

const SquadLogoWrapper = styled.div`
	width: 32px;
	height: 24px;

	img {
		height: 100%;
		width: auto;
	}
`;

const PlayersPoolCellFirst = styled(PlayersPoolCell)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

interface IProps {
	readonly playerId: number;
	readonly sortBy: ISortBy;
	readonly style: object;
}

const getRowClassName = (isInTeam: boolean) => {
	return isInTeam ? "in-team" : "";
};

const positionMap: Record<number, string> = {
	1: "Goalkeeper",
	2: "Defender",
	3: "Midfielder",
	4: "Forward",
};

export const PlayerGameDayPoolRow: React.FC<IProps> = ({playerId, sortBy, style}) => {
	const dispatch = useDispatch();
	const player = useSelector(getPlayerById)(playerId);
	const {stats, id, squadId, position} = player;

	const getClassName = useCallback(
		(field) => {
			return sortBy.sortBy === field ? "active" : "";
		},
		[sortBy]
	);

	const showPlayerPopup = useCallback(() => {
		dispatch(openPlayerInfoModal(id));
	}, [id, dispatch]);

	return (
		<PlayerContainer className={getRowClassName(false)} style={style}>
			<PlayersPoolCellFirst className={"max-width"}>
				<PlayerPoolStatus className="pointer">
					<PureButton
						// gtmId="player-pool-player-popup"
						onClick={showPlayerPopup}>
						<img src={IconInfo} alt="i" />
					</PureButton>
				</PlayerPoolStatus>
				<PlayerPoolName onClick={showPlayerPopup}>
					<p>
						<b>{getDisplayName(player)}</b>
					</p>
					<p className="squad">{positionMap[position] ?? "-"}</p>
				</PlayerPoolName>
			</PlayersPoolCellFirst>
			<PlayersPoolCell>
				<SquadLogoWrapper>
					<SquadLogo squadId={squadId} />
				</SquadLogoWrapper>
			</PlayersPoolCell>
			<PlayersPoolCell className={getClassName("stats.total_points")}>
				{stats?.total_points ?? "-"}
			</PlayersPoolCell>
			<PlayersPoolCell className={getClassName("stats.picked_by")}>
				{stats?.picked_by ?? "-"}
			</PlayersPoolCell>
			<PlayerActions playerId={playerId} />
		</PlayerContainer>
	);
};

interface IButtonProps {
	color?: string;
}

const ActionButton = styled(PureButton)<IButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;

	> div {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: ${(props) => props.color ?? "#000151"};
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

interface IPlayerActionsProps {
	playerId: number;
}

const PlayerActions: React.FC<IPlayerActionsProps> = ({playerId}) => {
	const dispatch = useDispatch();
	const {id, locked} = useSelector(getPlayerById)(playerId);
	const isInTeamGameDay = useSelector(getPlayerInTeamGameDayCheckSelector)(playerId);
	const isLockout = useSelector(isGameDayTeamLockout);
	const isTeamComplete = useSelector(getIsGameDayTeamCompleted);

	const removePlayer = useCallback(() => {
		dispatch(removePlayerFromTeam(id));
	}, [dispatch, id]);

	if (!isTeamComplete) {
		return (
			<PlayersPoolCell>
				<IncompleteTeamGameDayActions playerId={playerId} />
			</PlayersPoolCell>
		);
	}

	return (
		<PlayersPoolCell>
			<Exist when={!locked}>
				<Exist when={isInTeamGameDay}>
					<React.Fragment>
						<Exist when={!isLockout}>
							<ActionButton onClick={removePlayer} color="#F02F42">
								<div>
									<IconRemove width={8} height={8} color="#FFFFFF" />
								</div>
							</ActionButton>
						</Exist>
					</React.Fragment>
				</Exist>
				<AddToTeamButton id={id} />
			</Exist>
		</PlayersPoolCell>
	);
};

interface IAddProps {
	id: number;
}

const AddToTeamButton: React.FC<IAddProps> = ({id}) => {
	const dispatch = useDispatch();
	const isCanBeAddedToTeamGameDay = !useSelector(isTeamFullFilledGameDay);
	const isInTeamGameDay = useSelector(getPlayerInTeamGameDayCheckSelector)(id);
	const isTeamPosFilledGameDay: boolean = useSelector(getIsTeamPosFilledGameDay)(id);

	const isAddVisible = !isInTeamGameDay && isCanBeAddedToTeamGameDay;
	const addPlayerToTeam = useCallback(() => {
		dispatch(pickPlayerToTeam(id));
	}, [dispatch, id]);

	if (isTeamPosFilledGameDay) {
		return null;
	}

	return (
		<Exist when={isAddVisible}>
			<ActionButton onClick={addPlayerToTeam}>
				<div>
					<IconPlus width={14} height={14} color="#FFFFFF" />
				</div>
			</ActionButton>
		</Exist>
	);
};
