import React, {useCallback, useEffect, useRef} from "react";
import {ModalBackdrop, ModalCloseButton, ModalContent} from "components";
import {IconRemove} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import {getErrorMessage} from "modules/selectors/errors";
import warningImage from "assets/images/icons/warning.png";
import styled from "styled-components";
import {WarningButton} from "components/styled";
import {clearGlobalError} from "modules/actions";

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		width: 64px;
		height: auto;
		margin: 30px 0 24px 0;
	}

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin: 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin-bottom: 30px;
	}
`;

const SWarningButton = styled(WarningButton)`
	max-width: 260px;
	width: 100%;
	margin: 0 auto;
`;

export const GlobalErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const ref = useRef<HTMLButtonElement>(null);
	const errorMessage = useSelector(getErrorMessage);

	const closeModal = useCallback(() => {
		dispatch(clearGlobalError());
	}, [dispatch]);

	useEffect(() => {
		if (ref?.current && errorMessage) {
			ref.current.focus();
		}
	}, [ref, errorMessage]);

	if (!errorMessage) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerContainer>
					<img src={warningImage} alt="warning" />
					<h3>Error</h3>
					{/* eslint-disable-next-line react/no-danger */}
					<p dangerouslySetInnerHTML={{__html: errorMessage ?? ""}} />
					<SWarningButton onClick={closeModal} ref={ref}>
						Close
					</SWarningButton>
				</InnerContainer>
			</ModalContent>
		</ModalBackdrop>
	);
};
