import React from "react";
import styled from "styled-components";
import {
	HeaderContainer,
	HeaderLogo,
	HeaderNavLink,
	LogoWrapper,
	MockSponsorLogo,
} from "components/styled";
import {DesktopSubMenu} from "./DesktopSubMenu";
import {HeaderLogoImage} from "./HeaderLogoImage";
import {useLocation} from "react-router-dom";
import {Exist} from "components";
import SponsorLogo from "assets/images/SponsorLogo.svg";
import {HeaderSportLink} from "components/Header/HeaderSportLink";
import {useSelector} from "react-redux";
import {getIsUserLogged} from "modules/selectors";
import {SportSelector} from "components/Header/SportSelector";
import {APP_IS_AD_ENABLED} from "modules/constants";

const AppMenuWrapper = styled.div`
	position: relative;
	height: 80px;
	width: 100%;
	background: url(${({theme}) => theme.header.right_image}),
		${({theme}) => theme.header.main.background};
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	color: ${({theme}) => theme.header.main.color};
	z-index: 5;
`;

const InnerContainer = styled(HeaderContainer)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	//gap: 13px;
`;

const AppSubMenuWrapper = styled.div`
	height: 52px;
	width: 100%;
	background: ${({theme}) => theme.header.sub.background};
	color: ${({theme}) => theme.header.sub.color};
`;

const SHeaderNavLink = styled(HeaderNavLink)`
	margin-left: auto;
`;

const Relative = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	gap: 32px;
	padding-right: 32px;
`;

const PATHS_HAVE_SUB_MENU = ["/leagues", "/help", "/account"];

export const DesktopMenu: React.FC = () => {
	const location = useLocation();
	const isSubMenuVisible = PATHS_HAVE_SUB_MENU.some((path) => location.pathname.includes(path));
	const isLogged = useSelector(getIsUserLogged);
	return (
		<React.Fragment>
			<AppMenuWrapper>
				<InnerContainer>
					<Relative>
						<LogoWrapper>
							<HeaderLogo to={"/"}>
								<HeaderLogoImage />
							</HeaderLogo>
							<Exist when={APP_IS_AD_ENABLED}>
								<div>
									<p>Sponsored by</p>
									<MockSponsorLogo src={SponsorLogo} alt="Sponsor logo" />
								</div>
							</Exist>
						</LogoWrapper>
						<SportSelector />
					</Relative>
					<HeaderSportLink to="/team">My Team</HeaderSportLink>
					<Exist when={isLogged}>
						<HeaderSportLink to="/leagues">Leagues</HeaderSportLink>
						<HeaderSportLink to="/leaderboard">Leaderboard</HeaderSportLink>
						<HeaderSportLink to="/stats-centre">Stats Centre</HeaderSportLink>
					</Exist>

					<HeaderSportLink to="/help">Help</HeaderSportLink>
					<DesktopActionMenu />
				</InnerContainer>
			</AppMenuWrapper>

			<Exist when={isSubMenuVisible}>
				<AppSubMenuWrapper>
					<DesktopSubMenu visibilityPath="/leagues">
						<HeaderSportLink end to="/leagues">
							My Leagues
						</HeaderSportLink>
						<HeaderSportLink to="/leagues/create">Create a league</HeaderSportLink>
						<HeaderSportLink to="/leagues/join">Join a league</HeaderSportLink>
					</DesktopSubMenu>
					<DesktopSubMenu visibilityPath="/help">
						<HeaderSportLink end to="/help/guidelines">
							Game Guidelines
						</HeaderSportLink>
						{/*<HeaderNavLink to="/help/prizes">Prizes</HeaderNavLink>*/}
						<HeaderSportLink to="/help/faqs">FAQs</HeaderSportLink>
						<HeaderSportLink to="/help/contact-us">Contact us</HeaderSportLink>
						<HeaderSportLink to="/help/terms">Terms and Conditions</HeaderSportLink>
					</DesktopSubMenu>
					<DesktopSubMenu visibilityPath="/account">
						<HeaderSportLink end to="/account">
							Details
						</HeaderSportLink>
						<HeaderSportLink to="/account/password">Change password</HeaderSportLink>
					</DesktopSubMenu>
				</AppSubMenuWrapper>
			</Exist>
		</React.Fragment>
	);
};

const DesktopActionMenu: React.FC = () => {
	const isLogged = useSelector(getIsUserLogged);

	return (
		<React.Fragment>
			<Exist when={!isLogged}>
				<SHeaderNavLink to="/login">Log in</SHeaderNavLink>
			</Exist>
			<Exist when={isLogged}>
				<HeaderSportLink className="align-right" to="/account">
					My Account
				</HeaderSportLink>
			</Exist>
		</React.Fragment>
	);
};
