import React, {useCallback, useEffect, useState} from "react";
import {
	ModalBackdrop,
	ModalBackdropClickHandler,
	ModalCloseButton,
	ModalContent,
} from "components/Modals/styled";
import {IconRemove} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {PrimaryButton, PureButton} from "components/styled";
import {Exist} from "components/Exist";
import {PlayerDetails, PlayerFixtures, PlayerOverview} from "components/PlayerProfile";
import {
	fetchPlayerStatsRequest,
	playerStatsClear,
	removePlayerFromTeam,
	tradeInPlayer,
	tradeOutPlayer,
} from "modules/actions";
import {closePlayerInfoModal} from "modules/actions/modals";
import {
	getIsTeamLockout,
	getPlayerById,
	getPlayerInfoModalState,
	getPlayerInTeamCheckSelector,
	getSquadById,
	isReachedTransferOutLimit,
	isTeamFullFilled,
	isTransfersLeftLimit,
} from "modules/selectors";
import {PlayerJersey, SquadLogo} from "components";
import {media} from "assets/media";
import {useIsPathIncludes} from "modules/utils/hooks";
import {useNavigate} from "react-router-dom";
import {SALARY_CUP_DELIMITER} from "modules/constants";
import {getDisplayName} from "modules/utils/players";

const TabsWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-bottom: 1px solid #d8d8d8;
`;

const TabBody = styled.div`
	overflow: auto;
	height: auto;
`;

const TabItem = styled(PureButton)`
	max-width: 120px;
	width: 100%;
	color: ${({theme}) => theme.text_color};
	font-size: 14px;
	letter-spacing: 0;
	line-height: 17px;
	text-align: center;
	padding-bottom: 6px;
	margin-bottom: -1px;
	border-bottom: 4px solid transparent;

	&.active {
		border-bottom: 4px solid ${({theme}) => theme.player_profile.active_tab_border};
	}
`;

const SModalContent = styled(ModalContent)`
	height: 600px;
	display: flex;
	flex-direction: column;

	> div {
		width: 100%;
	}
`;

enum Tab {
	Overview = "Overview",
	Detail = "Detail",
	Fixtures = "Fixtures",
}

export const PlayerProfileModal: React.FC = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(Tab.Overview);
	const {isShow, playerId} = useSelector(getPlayerInfoModalState);

	useEffect(() => {
		if (playerId) {
			dispatch(fetchPlayerStatsRequest(playerId));
		}
	}, [dispatch, playerId]);

	const setTab = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const tab = e.currentTarget.dataset.tab;

		if (!tab) {
			return;
		}

		setActiveTab(tab as Tab);
	};

	const getIsTabActive = (tab: Tab): boolean => {
		return activeTab === tab;
	};

	const getTabClass = (tab: Tab): string => {
		return getIsTabActive(tab) ? "active" : "";
	};

	const closeModal = useCallback(() => {
		dispatch(playerStatsClear());
		dispatch(closePlayerInfoModal());
		setActiveTab(Tab.Overview);
	}, [dispatch]);

	if (!isShow || !playerId) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalBackdropClickHandler onClick={closeModal} />
			<SModalContent className="no-padding full">
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>

				<PlayerInfoBlock playerId={playerId} closeModal={closeModal} />

				<TabsWrapper>
					<TabItem
						className={getTabClass(Tab.Overview)}
						onClick={setTab}
						data-tab={Tab.Overview}>
						Overview
					</TabItem>
					<TabItem
						className={getTabClass(Tab.Detail)}
						onClick={setTab}
						data-tab={Tab.Detail}>
						Detail
					</TabItem>
					<TabItem
						className={getTabClass(Tab.Fixtures)}
						onClick={setTab}
						data-tab={Tab.Fixtures}>
						Fixture
					</TabItem>
				</TabsWrapper>
				<TabBody>
					<Exist when={getIsTabActive(Tab.Overview)}>
						<PlayerOverview playerId={playerId} />
					</Exist>
					<Exist when={getIsTabActive(Tab.Detail)}>
						<PlayerDetails playerId={playerId} />
					</Exist>
					<Exist when={getIsTabActive(Tab.Fixtures)}>
						<PlayerFixtures playerId={playerId} />
					</Exist>
				</TabBody>
			</SModalContent>
		</ModalBackdrop>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	gap: 16px;
	height: 180px;

	.jersey {
		box-sizing: border-box;
		height: 100%;
		padding: 30px 45px 12px 45px;
		position: relative;
		z-index: 2;

		img {
			height: 100%;
			width: auto;
		}
	}

	.info {
		padding-top: 18px;

		h4,
		h3,
		p {
			margin: 0;
		}

		h4 {
			color: ${({theme}) => theme.text_color};
			font-size: 16px;
			letter-spacing: 0;
			line-height: 19px;
			font-weight: normal;
			margin-bottom: 4px;
		}

		h3 {
			color: ${({theme}) => theme.text_color};
			font-size: 36px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 36px;
			margin-bottom: 4px;
		}

		p {
			color: ${({theme}) => theme.text_color};
			font-size: 14px;
			letter-spacing: 0;
			line-height: 18px;
		}
	}

	.transfer-button {
		display: flex;
		align-items: flex-end;
		padding-right: 12px;
		height: 100%;

		button {
			margin-bottom: 32px;
		}
	}

	@media screen and ${media.lessThanSM} {
		flex-wrap: wrap;
		height: 200px;
		gap: 6px;

		.jersey {
			max-height: 130px;
			height: unset;
			padding-bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 35%;
			box-sizing: border-box;
			padding-right: 0;
			padding-left: 0;
		}

		.info {
			box-sizing: border-box;
			height: unset;
			width: 60%;

			h4 {
				font-size: 14px;
				line-height: 17px;
			}

			h3 {
				font-size: 20px;
				line-height: 24px;
			}

			p {
				font-size: 12px;
				letter-spacing: 0;
				line-height: 18px;
			}
		}

		.transfer-button {
			height: unset;
			width: 100%;
			padding-right: 0;

			button {
				max-width: 140px;
				width: 100%;
			}
		}
	}
`;

const SquadLogoWrapper = styled.div`
	position: absolute;
	left: 15px;
	top: 26px;
	height: 120px;
	opacity: 0.2;
	z-index: 1;

	img {
		height: 100%;
		width: auto;
	}
`;

interface IPlayerStats {
	playerId: number;
	closeModal: () => void;
}

const PlayerInfoBlock: React.FC<IPlayerStats> = ({playerId, closeModal}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const player = useSelector(getPlayerById)(playerId);
	const squad = useSelector(getSquadById)(player.squadId);
	const isLockout = useSelector(getIsTeamLockout);
	const isInTeam = useSelector(getPlayerInTeamCheckSelector)(playerId);
	const isTransferPage = useIsPathIncludes("transfer");

	const isSeasonOutLimit = useSelector(isTransfersLeftLimit);
	const isTransferOutDisabled = useSelector(isReachedTransferOutLimit) || player?.locked;
	const canTransferOutPlayer = isLockout && !isTransferOutDisabled && !isSeasonOutLimit;
	const isTeamFull = useSelector(isTeamFullFilled);
	const playerCost = player?.cost / SALARY_CUP_DELIMITER;

	const canBeAdded = !isTeamFull && !isInTeam;

	const transferOutPlayer = useCallback(() => {
		if (!isTransferPage) {
			navigate(`transfers/${playerId}`);
			closeModal();
			return;
		}
		dispatch(tradeOutPlayer(playerId));
		dispatch(removePlayerFromTeam(playerId));
		closeModal();
	}, [isTransferPage, dispatch, navigate, playerId, closeModal]);

	const transferInPlayer = useCallback(() => {
		if (!isTransferPage) {
			navigate(`transfers/${playerId}`);
			closeModal();
			return;
		}
		dispatch(tradeInPlayer(playerId));
		closeModal();
	}, [dispatch, isTransferPage, navigate, playerId, closeModal]);

	return (
		<Wrapper>
			<div className="jersey">
				{
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				}
				<PlayerJersey squadId={player.squadId} alt={getDisplayName(player)} />
			</div>
			<div className="info">
				<SquadLogoWrapper>
					<SquadLogo squadId={squad?.id} />
				</SquadLogoWrapper>
				<h3>{getDisplayName(player)}</h3>
				<p>{squad?.name || "-"}</p>
				<p>Value: RM{playerCost}m</p>
				<p>Selected: {player.stats.picked_by || 0}%</p>
			</div>
			<div className="transfer-button">
				<Exist when={isLockout}>
					<Exist when={isInTeam}>
						<PrimaryButton disabled={!canTransferOutPlayer} onClick={transferOutPlayer}>
							Transfer Out
						</PrimaryButton>
					</Exist>
					<Exist when={!isInTeam}>
						<PrimaryButton disabled={!canBeAdded} onClick={transferInPlayer}>
							Transfer In
						</PrimaryButton>
					</Exist>
				</Exist>
			</div>
		</Wrapper>
	);
};
