import {SportType} from "./enums";

export enum ShareTarget {
	Twitter = "tw",
	Facebook = "fb",
}

export enum ShareType {
	General = "general",
	League = "league",
}

export interface IShare {
	target: ShareTarget;
	type: ShareType;
	sport?: SportType;
	leagueId?: number;
	t?: number;
	code?: string;
	game?: string;
}
