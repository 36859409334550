import {
	Exist,
	IconRemove,
	ModalBackdrop,
	ModalClose,
	ModalCloseButton,
	ModalContent,
} from "components";
import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {
	AuthLogo,
	Input,
	NavigateToAnotherAuthPageLink,
	PrimaryButton,
	PureButton,
	SubmitButtonWrapper,
	TextFieldControl,
} from "components/styled";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {handleAuthModal, userLogin} from "modules/actions";
import {EAuthModalType} from "modules/types/enums";
import {getIsSavingMode} from "modules/selectors";

import LogoIcon from "assets/images/LogoColor.png";

const Form = styled.form`
	margin-top: 40px;

	h1 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}
`;

const ForgotPasswordLink = styled.div`
	display: block;
	max-width: 300px;
	width: 100%;
	margin: 0 auto;
	text-align: right;

	a {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 18px;
		text-decoration: none;
	}
`;

export const ModalLogin: React.FC = () => {
	const dispatch = useDispatch();
	const isSaveMode = useSelector(getIsSavingMode);
	const [loginValues, setLoginValues] = useState({
		email: "",
		password: "",
	});

	const onSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(userLogin(loginValues));
	};

	const updateField = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			const fieldName = e.target.name || e.target.dataset["field"];

			if (!fieldName) {
				return;
			}

			setLoginValues({
				...loginValues,
				[fieldName]: value,
			});
		},
		[loginValues]
	);

	const toggleModal = useCallback(() => {
		dispatch(handleAuthModal({authModalToShow: EAuthModalType.REGISTRATION}));
	}, [dispatch]);

	const closeModal = useCallback(() => {
		dispatch(handleAuthModal({showAuthModal: false}));
	}, [dispatch]);

	const modalHasFadeClass = isSaveMode ? "fade" : "";

	return (
		<ModalBackdrop className={modalHasFadeClass}>
			<ModalContent className="full">
				<Exist when={isSaveMode}>
					<ModalCloseButton onClick={closeModal}>
						<IconRemove />
					</ModalCloseButton>
				</Exist>
				<Exist when={!isSaveMode}>
					<ModalClose to="/">
						<IconRemove />
					</ModalClose>
				</Exist>
				<AuthLogo src={LogoIcon} alt="logo" />
				<Form onSubmit={onSubmit}>
					<h1>Log in to your Malaysian Fantasy Football account:</h1>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label="Email"
							type="email"
							inputProps={{"data-field": "email"}}
							onChange={updateField}
							value={loginValues.email}
							required={true}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label="Password"
							type="password"
							inputProps={{"data-field": "password"}}
							value={loginValues.password}
							onChange={updateField}
							required={true}
						/>
					</TextFieldControl>
					<ForgotPasswordLink>
						<Link to="/forgot-password">Forgot Password?</Link>
					</ForgotPasswordLink>
					<SubmitButtonWrapper>
						<PrimaryButton>Log in</PrimaryButton>
					</SubmitButtonWrapper>
					<NavigateToAnotherAuthPageLink>
						<Exist when={!isSaveMode}>
							New? <Link to="/register">Register Now</Link>
						</Exist>
						<Exist when={isSaveMode}>
							New?{" "}
							<PureButton type="button" onClick={toggleModal}>
								Register Now
							</PureButton>
						</Exist>
					</NavigateToAnotherAuthPageLink>
				</Form>
			</ModalContent>
		</ModalBackdrop>
	);
};
