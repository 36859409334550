import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	fetchPlayers,
	fetchRounds,
	fetchSquads,
	fetchTeam,
	fetchTeamGameDay,
	teamFetchComplete,
} from "modules/actions";
import {getIsUserChecked, getIsUserLogged, getSport, isGameDayGame} from "modules/selectors";

export const HOCMain: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const sport = useSelector(getSport);
	const isLogged = useSelector(getIsUserLogged);
	const isChecked = useSelector(getIsUserChecked);
	const isGameDay = useSelector(isGameDayGame);

	useEffect(() => {
		if (isLogged && isChecked) {
			if (isGameDay) {
				dispatch(fetchTeamGameDay());
			} else {
				dispatch(fetchTeam());
			}
		}
		if (isChecked && !isLogged) {
			dispatch(teamFetchComplete());
		}
	}, [isGameDay, isLogged, isChecked, dispatch]);

	useEffect(() => {
		dispatch(fetchPlayers());
		dispatch(fetchSquads());
		dispatch(fetchRounds());
	}, [dispatch, sport]);

	return (
		<React.Fragment>
			<React.Fragment>{children}</React.Fragment>
		</React.Fragment>
	);
};
