var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { bindAll, isFunction, merge } from 'lodash';
/**
 * HTTP Client created as wrapper of axios library.
 * Can be used to performs post/get/put/delete http methods.
 * It has few hooks:
 * `onBeforeRequest`
 * `onAfterRequest`
 * `transformResponse`
 * `onCatchNetworkError`
 * that would be called on every request.
 * This hooks should be passed in constructor.
 * All of the hooks is optional.
 *
 * ### Example (es imports)
 * ```js
 * import {
 *  HTTPClient as HTTPClientCore,
 *  IRequestConfig
 * } from '@fanhubmedia/fe-common-utils';
 * import axios from 'axios';
 * import { CANCEL } from 'redux-saga';
 *
 * // Next code show an example of possible usage HTTPClient class with redux saga "cancel" request functionality.
 * // It's also adds additional data into all requests.
 *
 * class HTTPClient extends HTTPClientCore {
 *  public makeRequest(config: IRequestConfig): Promise<any> {
 * 	    const source = axios.CancelToken.source();
 *
 *  const request = super.makeRequest({
 * 	    ...config,
 * 	    params: {
 * 		    ...config.params,
 * 		    _: Date.now(),
 * 		    sid: localStorage.getItem('sid') || '',
 * 	    },
 * 	    cancelToken: source.token,
 *  });
 *
 *  request[CANCEL] = () => source.cancel();
 *
 *  return request;
 *  }
 * }
 *
 * // Example below show how to set all data as FormData representation.
 *
 * const APIClient = new HTTPClient({
 * 	baseURL: process.env.REACT_APP_API_URL || '',
 * 	withCredentials: true,
 * 	transformRequest: [data => qs.stringify(data)],
 * });
 *
 * APIClient.post('login', credentials);
 * APIClient.get('user/show_my');
 *
 * const JSONClient = new HTTPClient({
 * 	baseURL: process.env.REACT_APP_JSON_URL || '',
 * });
 *
 * JSONClient.get('players.json');
 * ```
 */
var HTTPClient = /** @class */ (function () {
    function HTTPClient(config) {
        this.config = config;
        bindAll(this, [
            'onBeforeRequest',
            'onAfterRequest',
            'transformResponse',
            'onCatchNetworkError'
        ]);
        this.HttpClient = axios.create(this.config);
    }
    HTTPClient.getNormalizedNetworkError = function (networkError) {
        var message = networkError.message, response = networkError.response;
        return {
            code: response ? response.status : 0,
            message: message,
            statusText: response ? response.statusText : ''
        };
    };
    Object.defineProperty(HTTPClient.prototype, "interceptors", {
        get: function () {
            return this.HttpClient.interceptors;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Override default config for current instance.
     */
    HTTPClient.prototype.extendDefaults = function (config) {
        merge(this.HttpClient.defaults, config);
        return this;
    };
    /**
     * Performs pure request without calling any hooks.
     */
    HTTPClient.prototype.request = function (requestConfig) {
        return this.HttpClient.request(requestConfig);
    };
    /**
     * Performs `get` http method with call of all existing hooks.
     */
    HTTPClient.prototype.get = function (url, params, requestConfig) {
        return this.makeRequest(__assign({ params: params, url: url }, requestConfig));
    };
    /**
     * Performs `delete` http method with call of all existing hooks.
     */
    HTTPClient.prototype.delete = function (url, params, requestConfig) {
        return this.makeRequest(__assign({ data: params, method: 'delete', url: url }, requestConfig));
    };
    /**
     * Performs `post` http method with call of all existing hooks.
     */
    HTTPClient.prototype.post = function (url, params, requestConfig) {
        return this.makeRequest(__assign({ data: params, method: 'post', url: url }, requestConfig));
    };
    /**
     * Performs `put` http method with call of all existing hooks.
     */
    HTTPClient.prototype.put = function (url, params, requestConfig) {
        return this.makeRequest(__assign({ data: params, method: 'put', url: url }, requestConfig));
    };
    /**
     * Performs `put` http method with call of all existing hooks.
     */
    HTTPClient.prototype.patch = function (url, params, requestConfig) {
        return this.makeRequest(__assign({ data: params, method: 'patch', url: url }, requestConfig));
    };
    /**
     * Performs request with call of all existing hooks.
     */
    HTTPClient.prototype.makeRequest = function (requestConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.onBeforeRequest(requestConfig)];
                    case 1:
                        config = _a.sent();
                        return [2 /*return*/, this.HttpClient.request(__assign(__assign({}, requestConfig), config))
                                .then(this.onAfterRequest)
                                .then(this.transformResponse)
                                .catch(this.onCatchNetworkError)];
                }
            });
        });
    };
    HTTPClient.prototype.onBeforeRequest = function (requestConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var onBeforeRequest;
            return __generator(this, function (_a) {
                onBeforeRequest = this.config.onBeforeRequest;
                if (isFunction(onBeforeRequest)) {
                    return [2 /*return*/, onBeforeRequest.call(this, requestConfig)];
                }
                return [2 /*return*/];
            });
        });
    };
    HTTPClient.prototype.onAfterRequest = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var onAfterRequest;
            return __generator(this, function (_a) {
                onAfterRequest = this.config.onAfterRequest;
                if (isFunction(onAfterRequest)) {
                    return [2 /*return*/, onAfterRequest.call(this, response) || response];
                }
                return [2 /*return*/, response];
            });
        });
    };
    HTTPClient.prototype.transformResponse = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var transformResponse;
            return __generator(this, function (_a) {
                transformResponse = this.config.transformResponse;
                if (isFunction(transformResponse)) {
                    return [2 /*return*/, transformResponse.call(this, response) || response.data];
                }
                return [2 /*return*/, response.data];
            });
        });
    };
    HTTPClient.prototype.onCatchNetworkError = function (networkError) {
        return __awaiter(this, void 0, void 0, function () {
            var error, onCatchNetworkError;
            return __generator(this, function (_a) {
                error = HTTPClient.getNormalizedNetworkError(networkError);
                onCatchNetworkError = this.config.onCatchNetworkError;
                if (isFunction(onCatchNetworkError)) {
                    return [2 /*return*/, onCatchNetworkError.call(this, networkError) || error];
                }
                return [2 /*return*/, error];
            });
        });
    };
    return HTTPClient;
}());
export { HTTPClient };
