export enum UserGender {
	Male = "male",
	Female = "female",
	Other = "other",
	PreferNotToSay = "prefer_not_to_say",
}

export interface IRegisterFormPayload {
	birthday: Date;
	email: string;
	confirmEmail: string;
	firstName: string;
	gender: UserGender;
	geniusOptIn: boolean;
	isNotificationsEnabled: boolean;
	lastName: string;
	mflOptIn: boolean;
	password: string;
	confirmPassword: string;
	terms: boolean;
}

export interface IRegisterPayload {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	birthday: string | Date;
	gender: UserGender;
	mflOptIn: boolean;
	geniusOptIn: boolean;
	isNotificationsEnabled?: boolean;
}

export interface IUserUpdatePayload {
	email: IRegisterPayload["email"];
	firstName: IRegisterPayload["firstName"];
	lastName: IRegisterPayload["lastName"];
	birthday: IRegisterPayload["birthday"];
	gender: IRegisterPayload["gender"];
	mflOptIn?: IRegisterPayload["mflOptIn"];
	geniusOptIn?: IRegisterPayload["geniusOptIn"];
	isNotificationsEnabled?: IRegisterPayload["isNotificationsEnabled"];
}

export interface IUserUpdatePasswordPayload {
	password: IRegisterPayload["password"];
}

export interface ILoginPayload {
	email: string;
	password: string;
}

export interface IResetPasswordPayload {
	password: string;
	token: string;
}

export interface IUser {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	gender: string;
	birthday: string;
	mflOptIn: boolean;
	geniusOptIn: boolean;
	isNotificationsEnabled: boolean;
}

export interface IContactUsRequestPayload {
	name: string;
	email: string;
	type: string;
	question: string;
}
