import {SALARY_CUP_DELIMITER} from "modules/constants";
import {createSelector} from "reselect";
import {getPlayers} from "modules/selectors/players";
import {getRounds, getScoreRound} from "modules/selectors/round";
import {get, identity, isNumber} from "lodash";
import {getTeamsGameDay} from "modules/selectors/team";

const getPointsPerMillion = (cost: number, avgPoints?: number) => {
	if (!avgPoints || !cost) {
		return 0;
	}

	const computedCost = cost / SALARY_CUP_DELIMITER;

	return Number((avgPoints / (computedCost || 1)).toFixed(2));
};

export const getPlayersForStats = createSelector(getPlayers, getScoreRound, (players, round) => {
	const id = round ? round.id : 1;
	return players.map((player) => {
		const roundPoints = get(player, `stats.round_scores.${id}`, "-") as number | string;

		return {
			...player,
			pointsPerMillion: getPointsPerMillion(player.cost, player.stats.avg_points),
			roundPoints,
			roundPointsNumber: isNumber(roundPoints) ? roundPoints : 0,
		};
	});
});

export const getGameDayTeamRounds = createSelector(getRounds, getTeamsGameDay, (rounds, teams) => {
	if (!rounds.length) {
		return [];
	}

	const firstRound = teams[0].roundId || 1;

	return rounds
		.filter(({id}) => {
			return id >= firstRound;
		})
		.filter(identity);
});
