import {SecondaryLink} from "components/styled";
import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getProject, getSport} from "modules/selectors";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	a {
		margin: 0;
	}
`;

export const StatsCentreNavigation: React.FC = () => {
	const project = useSelector(getProject);
	const sport = useSelector(getSport);

	return (
		<Wrapper>
			<SecondaryLink end to={`/${project}/${sport}/stats-centre`}>
				Players
			</SecondaryLink>
			<SecondaryLink to={`/${project}/${sport}/stats-centre/teams`}>Teams</SecondaryLink>
		</Wrapper>
	);
};
