import {MSLClassicThemeObject} from "assets/MSL/classic_theme";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

export const themeGlobal = {
	typography: {
		fontFamily: "Lato",
	},
};

type ThemeInterface = typeof MSLClassicThemeObject;
declare module "styled-components" {
	// Allows change type of  theme to avoid any
	/* eslint-disable @typescript-eslint/no-empty-interface */

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface DefaultTheme extends ThemeInterface {}
}
