import {createReducer} from "redux-act";
import {RequestStateType, Steps} from "modules/types/enums";

import {
	createLeagueGameDayClear,
	createLeagueGameDayClearStep,
	createLeagueGameDayRequest,
	createLeagueGameDaySuccess,
	fetchJoinLeaguesGameDayClear,
	fetchJoinLeaguesGameDayConcat,
	fetchJoinLeaguesGameDayRequest,
	fetchJoinLeaguesGameDaySuccess,
	fetchLeagueGameDayTableConcat,
	fetchLeagueGameDayTableRequest,
	fetchLeagueGameDayTableSuccess,
	fetchLeagueGameDayUsersRequest,
	fetchLeagueGameDayUsersSuccess,
	fetchLeaguesGameDaySuccess,
	joinLeagueGameDayClear,
	joinLeagueGameDayRequest,
	JoinLeagueGameDaySuccess,
	leagueGameDayRemoveUserClear,
	leagueGameDayRemoveUserRequest,
	leagueGameDayRemoveUserSuccess,
	leagueGameDayRequestGlobalStateHandler,
	leagueGameDayUsersClear,
	leaveLeagueGameDayClear,
	leaveLeagueGameDayRequest,
	leaveLeagueGameDaySuccess,
	sendLeagueGameDayInviteClear,
	fetchLeaguesGameDayRequest,
	leaguesGameDayClear,
	setLeagueGameDayJoined,
	sendLeagueGameDayInviteRequest,
	sendLeagueGameDayInviteSuccess,
	updateLeagueGameDayRequest,
	updateLeagueGameDaySuccess,
} from "modules/actions";
import {
	IApiFetchJoinLeagueClearResponse,
	IApiFetchJoinLeagueGameDayClearResponse,
} from "modules/types/api";
import {
	IGenerateRequestStateHandler,
	ILeagueGameDay,
	ILeagueGameDayRankItem,
	ILeagueGameDayReducer,
	ILeagueGameDayUser,
	IWithNextPagePayload,
	IWithNextPayload,
} from "modules/types";

const defaultState: ILeagueGameDayReducer = {
	leagues: [],
	joinLeagues: {
		leagues: [],
		nextPage: false,
	},
	createActiveStep: Steps.First,
	leagueRanks: [],
	leagueUsers: [],
	leagueTableData: {
		ranks: [],
		next: false,
	},
	leagueUsersNewPage: false,
	isLeagueCreated: false,
	leagueRequests: {
		fetch: RequestStateType.Idle,
		create: RequestStateType.Idle,
		update: RequestStateType.Idle,
		joinFetch: RequestStateType.Idle,
		join: RequestStateType.Idle,
		leave: RequestStateType.Idle,
		sendInvites: RequestStateType.Idle,
		fetchLeagueRanks: RequestStateType.Idle,
		fetchLeagueUsers: RequestStateType.Idle,
		removeLeagueUser: RequestStateType.Idle,
		fetchLeagueTable: RequestStateType.Idle,
	},
};

const onfetchLeagueRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetch: RequestStateType.Loading},
});

const onfetchLeagueSuccess = (
	state: ILeagueGameDayReducer,
	payload: ILeagueGameDay[]
): ILeagueGameDayReducer => ({
	...state,
	leagues: payload,
	leagueRequests: {...state.leagueRequests, fetch: RequestStateType.Success},
});

const onLeaguesClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagues: [],
});

const onCreateLeagueRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, create: RequestStateType.Loading},
});

const onCreateLeagueSuccess = (
	state: ILeagueGameDayReducer,
	payload: ILeagueGameDay[]
): ILeagueGameDayReducer => ({
	...state,
	leagues: [...state.leagues, ...payload],
	isLeagueCreated: true,
	leagueRequests: {...state.leagueRequests, create: RequestStateType.Success},
});

const onCreateLeagueClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, create: RequestStateType.Idle},
});

const onCreateLeagueClearStep = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	isLeagueCreated: false,
});

const onFetchJoinLeaguesRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Loading},
});

const onFetchJoinLeaguesSuccess = (
	state: ILeagueGameDayReducer,
	payload: IApiFetchJoinLeagueClearResponse
): ILeagueGameDayReducer => ({
	...state,
	joinLeagues: payload,
	leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Success},
});

const onFetchJoinLeaguesClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Idle},
});

const onJoinLeagueRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, join: RequestStateType.Loading},
});

const onJoinLeagueSuccess = (
	state: ILeagueGameDayReducer,
	payload: ILeagueGameDay
): ILeagueGameDayReducer => ({
	...state,
	leagues: [...state.leagues, payload],
	leagueRequests: {...state.leagueRequests, join: RequestStateType.Success},
});

const onJoinLeagueClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, join: RequestStateType.Idle},
});

const onLeaveLeagueRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, leave: RequestStateType.Loading},
});

const onLeaveLeagueSuccess = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, leave: RequestStateType.Success},
});

const onLeaveLeagueClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, leave: RequestStateType.Idle},
});

const onUpdateLeagueRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, update: RequestStateType.Loading},
});

const onUpdateLeagueSuccess = (
	state: ILeagueGameDayReducer,
	payload: ILeagueGameDay
): ILeagueGameDayReducer => {
	const leagues = [...state.leagues];
	const leagueId = state.leagues.findIndex((league) => league.id === payload.id);
	if (leagueId !== -1) {
		leagues[leagueId] = payload;
	}

	return {
		...state,
		leagues: leagues,
		leagueRequests: {...state.leagueRequests, update: RequestStateType.Success},
	};
};

const onSetLeagueJoined = (
	state: ILeagueGameDayReducer,
	payload: number
): ILeagueGameDayReducer => {
	const leagues = [...state.leagues];
	const leagueId = state.leagues.findIndex((league) => league.id === payload);
	if (leagueId !== -1) {
		leagues[leagueId] = {...leagues[leagueId], is_joined: true};
	}

	return {
		...state,
		leagues: leagues,
	};
};

const onSendLeagueInviteRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, sendInvites: RequestStateType.Loading},
});

const onSendLeagueInviteSuccess = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, sendInvites: RequestStateType.Success},
});

const onSendLeagueInviteClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, sendInvites: RequestStateType.Idle},
});

const onFetchLeagueUsersRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueUsers: RequestStateType.Loading},
});

const onFetchLeagueUsersSuccess = (
	state: ILeagueGameDayReducer,
	request: IWithNextPagePayload<ILeagueGameDayUser[]>
): ILeagueGameDayReducer => ({
	...state,
	leagueUsers: request.payload,
	leagueUsersNewPage: request.newPage,
	leagueRequests: {...state.leagueRequests, fetchLeagueUsers: RequestStateType.Success},
});

const onLeagueUsersClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueUsers: [],
});

const onLeagueRemoveUserRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, removeLeagueUser: RequestStateType.Loading},
});

const onLeagueRemoveUserSuccess = (
	state: ILeagueGameDayReducer,
	payload: number
): ILeagueGameDayReducer => {
	const copy = [...state.leagueUsers];
	const index = state.leagueUsers.findIndex((user) => user.userId === payload);

	if (index !== -1) {
		copy.splice(index, 1);
	}

	return {
		...state,
		leagueUsers: copy,
		leagueRequests: {...state.leagueRequests, removeLeagueUser: RequestStateType.Success},
	};
};

const onLeagueRemoveUserClear = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, removeLeagueUser: RequestStateType.Loading},
});

const onFetchLeagueTableRequest = (state: ILeagueGameDayReducer): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueTable: RequestStateType.Loading},
});

const onFetchLeagueTableSuccess = (
	state: ILeagueGameDayReducer,
	payload: IWithNextPayload<ILeagueGameDayRankItem[]>
): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueTable: RequestStateType.Success},
	leagueTableData: {
		next: payload.next,
		ranks: payload.payload,
	},
});

const onFetchLeagueTableConcat = (
	state: ILeagueGameDayReducer,
	payload: IWithNextPayload<ILeagueGameDayRankItem[]>
): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueTable: RequestStateType.Success},
	leagueTableData: {
		next: payload.next,
		ranks: [...state.leagueTableData.ranks, ...payload.payload],
	},
});

const onLeagueRequestGlobalStateHandler = (
	state: ILeagueGameDayReducer,
	payload: IGenerateRequestStateHandler<ILeagueGameDayReducer["leagueRequests"]>
): ILeagueGameDayReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, [payload.key]: payload.state},
});

export const leaguesGameDay = createReducer<ILeagueGameDayReducer>({}, defaultState)
	.on(fetchLeaguesGameDayRequest, onfetchLeagueRequest)
	.on(fetchLeaguesGameDaySuccess, onfetchLeagueSuccess)
	.on(leaguesGameDayClear, onLeaguesClear)
	.on(setLeagueGameDayJoined, onSetLeagueJoined)

	.on(createLeagueGameDayRequest, onCreateLeagueRequest)
	.on(createLeagueGameDaySuccess, onCreateLeagueSuccess)
	.on(createLeagueGameDayClear, onCreateLeagueClear)
	.on(createLeagueGameDayClearStep, onCreateLeagueClearStep)

	.on(updateLeagueGameDayRequest, onUpdateLeagueRequest)
	.on(updateLeagueGameDaySuccess, onUpdateLeagueSuccess)

	.on(fetchJoinLeaguesGameDayRequest, onFetchJoinLeaguesRequest)
	.on(fetchJoinLeaguesGameDaySuccess, onFetchJoinLeaguesSuccess)
	.on(
		fetchJoinLeaguesGameDayConcat,
		(
			state: ILeagueGameDayReducer,
			payload: IApiFetchJoinLeagueGameDayClearResponse
		): ILeagueGameDayReducer => ({
			...state,
			joinLeagues: {
				leagues: payload.leagues,
				nextPage: payload.nextPage,
			},
			leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Success},
		})
	)
	.on(fetchJoinLeaguesGameDayClear, onFetchJoinLeaguesClear)

	.on(joinLeagueGameDayRequest, onJoinLeagueRequest)
	.on(JoinLeagueGameDaySuccess, onJoinLeagueSuccess)
	.on(joinLeagueGameDayClear, onJoinLeagueClear)

	.on(leaveLeagueGameDayRequest, onLeaveLeagueRequest)
	.on(leaveLeagueGameDaySuccess, onLeaveLeagueSuccess)
	.on(leaveLeagueGameDayClear, onLeaveLeagueClear)

	.on(sendLeagueGameDayInviteRequest, onSendLeagueInviteRequest)
	.on(sendLeagueGameDayInviteSuccess, onSendLeagueInviteSuccess)
	.on(sendLeagueGameDayInviteClear, onSendLeagueInviteClear)

	.on(fetchLeagueGameDayUsersRequest, onFetchLeagueUsersRequest)
	.on(fetchLeagueGameDayUsersSuccess, onFetchLeagueUsersSuccess)
	.on(leagueGameDayUsersClear, onLeagueUsersClear)

	.on(leagueGameDayRemoveUserRequest, onLeagueRemoveUserRequest)
	.on(leagueGameDayRemoveUserSuccess, onLeagueRemoveUserSuccess)
	.on(leagueGameDayRemoveUserClear, onLeagueRemoveUserClear)

	.on(fetchLeagueGameDayTableRequest, onFetchLeagueTableRequest)
	.on(fetchLeagueGameDayTableSuccess, onFetchLeagueTableSuccess)
	.on(fetchLeagueGameDayTableConcat, onFetchLeagueTableConcat)

	.on(leagueGameDayRequestGlobalStateHandler, onLeagueRequestGlobalStateHandler);
