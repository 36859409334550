import React, {useCallback, useState} from "react";
import {MenuItem} from "@mui/material";
import {Input} from "components/styled";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getNonScheduledRounds} from "modules/selectors";
import {media} from "assets/media";
import {formatGameWeek} from "modules/utils";

const Wrapper = styled.div`
	${Input} {
		width: 300px;
	}

	@media screen and ${media.lessThanMD} {
		width: 100%;
		${Input} {
			width: 100%;
		}
	}
`;

interface IProps {
	roundId: number;
	onChange: (round: number) => void;
}

export const LeaderboardFilters: React.FC<IProps> = ({roundId, onChange}) => {
	const rounds = useSelector(getNonScheduledRounds);
	const [selectedRound, setSelectedRound] = useState(roundId);

	const onRoundChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newRoundId = Number(e.target.value);
			onChange(Number(e.target.value));

			setSelectedRound(newRoundId);
		},
		[onChange]
	);

	return (
		<Wrapper>
			<Input select name="type" value={selectedRound} onChange={onRoundChange}>
				<MenuItem value={0} selected={true}>
					Overall
				</MenuItem>
				{rounds.map((round) => (
					<MenuItem key={round.id} value={round.id}>
						Game week {formatGameWeek(round.number)}
					</MenuItem>
				))}
			</Input>
		</Wrapper>
	);
};
