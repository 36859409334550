import React, {lazy} from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {BASE_URL} from "modules/constants";
import {retryFailLoad} from "modules/utils/LazyLoad";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {Provider} from "react-redux";
import {store} from "modules/store";
import {
	Footer,
	Header,
	ModalLogin,
	ModalRegister,
	NotAuthOnlyRoute,
	PagePreloader,
} from "components";
import DateAdapter from "@mui/lab/AdapterLuxon";
import {LocalizationProvider} from "@mui/lab";
import "./assets/core.css";
import {HOCSecret} from "components/HOCs/HOCSecret";
import {HOCAuth} from "components/HOCs/HOCAuth";
import {HOCThemeAdapter} from "components/HOCs/HOCThemeAdapter";
import {Main} from "components/styled";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://5cb4cd7e160c40d2aaefaa448ccec613@o151969.ingest.sentry.io/6119741",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: [".ligamfantasy.com", ".geniussports.com"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
});

// const Landing = lazy(retryFailLoad(() => import("pages/Landing")));
const LandingMC = lazy(retryFailLoad(() => import("pages/LandingMC")));
const Classic = lazy(retryFailLoad(() => import("pages/Classic")));
const Gameday = lazy(retryFailLoad(() => import("pages/Gameday")));

const AuthPageOutlet = lazy(retryFailLoad(() => import("components/Outlets/AuthPageOutlet")));
const ForgotPassword = lazy(retryFailLoad(() => import("pages/ForgotPassword")));
const ResetPassword = lazy(retryFailLoad(() => import("pages/ResetPassword")));

const NotFound = lazy(retryFailLoad(() => import("pages/PageNotFound")));

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter basename={BASE_URL}>
				<HOCThemeAdapter>
					<LocalizationProvider dateAdapter={DateAdapter}>
						<HOCSecret>
							<HOCAuth>
								<React.Suspense fallback={<PagePreloader />}>
									<Header />
									<Main>
										<Routes>
											<Route path="classic/*" element={<Classic />} />
											<Route path="gameday/*" element={<Gameday />} />

											<Route element={<NotAuthOnlyRoute />}>
												<Route path="/" element={<LandingMC />} />
												<Route path="/landing-mc" element={<LandingMC />} />
												<Route element={<AuthPageOutlet />}>
													<Route path="login" element={<ModalLogin />} />
													<Route
														path="register"
														element={<ModalRegister />}
													/>
													<Route
														path="forgot-password"
														element={<ForgotPassword />}
													/>
													<Route
														path="reset-password"
														element={<ResetPassword />}
													/>
												</Route>
											</Route>
											<Route element={<NotFound />} path="/*" />
										</Routes>
									</Main>
									<Footer />
								</React.Suspense>
							</HOCAuth>
						</HOCSecret>
					</LocalizationProvider>
				</HOCThemeAdapter>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
