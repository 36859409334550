import React, {useState} from "react";
import {Select, SelectChangeEvent, MenuItem} from "@mui/material";
import {getActualRound, getGameDayTeamRounds, getIsUserLogged} from "modules/selectors";
import {useDispatch, useSelector} from "react-redux";
import {get, toNumber, toString} from "lodash";
import {selectRound} from "modules/actions";
import styled from "styled-components";
import {formatGameWeek} from "modules/utils";

const Wrapper = styled.div`
	.game-week-select {
		color: #000;
		background: #fff;
		height: 40px;

		fieldset,
		.MuiSelect-select {
			color: #000;
		}
	}
`;

const MenuItemStyled = styled(MenuItem)`
	&.actual.Mui-selected,
	&.actual {
		font-weight: bold !important;
	}
`;

export const GameWeekSelector: React.FC = () => {
	const dispatch = useDispatch();
	const rounds = useSelector(getGameDayTeamRounds);
	const actualRound = useSelector(getActualRound);
	const actualRoundID = get(actualRound, "id", 0);
	const [selected, setSelected] = useState(actualRoundID);
	const isLogged = useSelector(getIsUserLogged);

	const onChange = (e: SelectChangeEvent) => {
		if (e.target.value) {
			const id = toNumber(e.target.value);
			setSelected(id);
			dispatch(selectRound(id));
		}
	};
	if (!rounds.length || !isLogged || actualRoundID === 0) {
		return null;
	}

	return (
		<Wrapper>
			<Select className="game-week-select" value={toString(selected)} onChange={onChange}>
				{rounds.map((roundData) => {
					const isActual = roundData.id === actualRound!.id;
					const className = isActual ? "actual" : "";
					return (
						<MenuItemStyled
							key={roundData.id}
							className={className}
							value={roundData.id}>
							Game Week {formatGameWeek(roundData.number)}
						</MenuItemStyled>
					);
				})}
			</Select>
		</Wrapper>
	);
};
