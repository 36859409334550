import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	getAutoFillRequestState,
	getIsTeamHeadsFull,
	isClassicGame,
	isGameDayGame,
	getTeam,
	getTeamEmptyLineup,
	getTeamRequestState,
	isTeamFullFilled,
	// getTeamRemainingSalary,
} from "modules/selectors";
import {Exist} from "components/Exist";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {
	autoFillGameDayRequest,
	autoFillRequest,
	clearHeads,
	editModalSavingMode,
	removePlayerFromTeam,
	saveTeam,
	toggleEditTeamNameModal,
	toggleNoHeadsModal,
} from "modules/actions";
import {flatMap} from "lodash";
// import {SALARY_CUP_DELIMITER} from "modules/constants";

export const NonCompleteTeamButtons: React.FC = () => {
	const dispatch = useDispatch();
	const autofillState = useSelector(getAutoFillRequestState);
	const saveTeamRequestState = useSelector(getTeamRequestState)("saveTeam");
	const isTeamFull = useSelector(isTeamFullFilled);
	const lineup = useSelector(getTeamEmptyLineup);
	const flatLineUp = flatMap(lineup);
	const team = useSelector(getTeam);
	const isHeadsFull = useSelector(getIsTeamHeadsFull);
	const isGameDay = useSelector(isGameDayGame);
	const isClassic = useSelector(isClassicGame);
	// const affordablePrice = useSelector(getTeamRemainingSalary) / SALARY_CUP_DELIMITER;
	/**
	 * add || hasExceededCap to isSaveDisabled
	 * to do FE check to disable team if have exceeded cap
	 * need some other design FE changes as well
	 */
	// const hasExceededCap = affordablePrice < 0;
	const isSaveDisabled = !isTeamFull || saveTeamRequestState.isLoading;
	const isAutoDisabled = autofillState.isLoading || saveTeamRequestState.isLoading;

	const clearTeam = useCallback(() => {
		flatLineUp.forEach((playerId) => {
			dispatch(removePlayerFromTeam(playerId));
			dispatch(clearHeads());
		});
	}, [flatLineUp, dispatch]);

	const autoFillHandler = useCallback(() => {
		dispatch(isGameDay ? autoFillGameDayRequest() : autoFillRequest());
	}, [dispatch, isGameDay]);

	const saveTeamHandle = useCallback(() => {
		dispatch(editModalSavingMode(true));
		if (!isHeadsFull && isClassic) {
			dispatch(toggleNoHeadsModal(true));
			return;
		}
		if (!team?.name) {
			dispatch(toggleEditTeamNameModal(true));
			return;
		}
		dispatch(saveTeam());
	}, [team, dispatch, isHeadsFull, isClassic]);

	return (
		<React.Fragment>
			<Exist when={isTeamFull}>
				<SecondaryButton onClick={clearTeam} disabled={isAutoDisabled}>
					Clear All
				</SecondaryButton>
			</Exist>

			<Exist when={!isTeamFull}>
				<SecondaryButton onClick={autoFillHandler} disabled={isAutoDisabled}>
					Auto fill
				</SecondaryButton>
			</Exist>

			<PrimaryButton disabled={isSaveDisabled} onClick={saveTeamHandle}>
				Save
			</PrimaryButton>
		</React.Fragment>
	);
};
