import React, {Component, useCallback, useState} from "react";
import styled from "styled-components";
import {FirstLevelNavLink, PureButton} from "components/styled";
import {Exist, IconChevron} from "components";

interface IAccordionProps {
	item: React.ComponentElement<unknown, Component>;
}

const AccordionButton = styled(PureButton)`
	color: currentColor;
	width: 60px;
	background: ${({theme}) => theme.header.menu.opened_item_color};
	border-bottom: 1px solid #ffffff;

	svg {
		width: 18px;
		height: 18px;
	}
`;

const AccordionWrapper = styled.div``;

const AccordionItem = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	&.open {
		> div,
		a,
		button {
			border-bottom: none;
		}
	}
`;

export const HOCAccordion: React.FC<IAccordionProps> = ({item, children = null}) => {
	const [isMenuOpen, setMenuOpen] = useState(false);

	const toggleMenu = useCallback(() => {
		setMenuOpen(!isMenuOpen);
	}, [isMenuOpen]);

	const chevronDirection = isMenuOpen ? "top" : "bottom";
	const isMenuOpenClass = isMenuOpen ? "open" : "";

	return (
		<AccordionWrapper className="accordion">
			<AccordionItem className={isMenuOpenClass}>
				{item}
				<AccordionButton onClick={toggleMenu} className="accordion-button">
					<IconChevron color="currentColor" width={12} height={6} to={chevronDirection} />
				</AccordionButton>
			</AccordionItem>
			<Exist when={isMenuOpen}>{children}</Exist>
		</AccordionWrapper>
	);
};

interface IAccordionLinkProps {
	text: string;
	link: string;
}

export const HOCAccordionLink: React.FC<IAccordionLinkProps> = ({text, link, children = null}) => {
	return (
		<HOCAccordion item={<FirstLevelNavLink to={link}>{text}</FirstLevelNavLink>}>
			{children}
		</HOCAccordion>
	);
};
