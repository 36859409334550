import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios, {AxiosError} from "axios";
import {API_URL, JSON_URL, playersJSONSportMapper} from "modules/constants";
import {ApiError} from "modules/utils/Api/ApiError";
import {CANCEL} from "redux-saga";
import {get, set} from "lodash";
import {
	IApiResponse,
	IAutoPickGameDayPayload,
	IAutoPickRequest,
	IContactUsRequestPayload,
	ICreateLeagueRequestPayload,
	IFetchLeaguesGetParams,
	IFetchRanksRequestPayload,
	IGameBarPayload,
	ILeagueInvitePayload,
	INewTeamRequest,
	IPaginationRequest,
	IResetPasswordPayload,
	ISportWithIdPayload,
	ISquad,
	ITeamRequestGameDay,
	ITradeRequestPayload,
	IUserUpdatePasswordPayload,
	IUserUpdatePayload,
	IWithSportPayload,
} from "modules/types";
import {ILoginPayload, IRegisterPayload} from "modules/types/user";
import {
	TApiAutoPickGameDayResponse,
	TApiAutoPickResponse,
	TApiCreateLeaguesResponse,
	TApiFetchJoinLeaguesResponse,
	TApiFetchLeagueRanksResponse,
	TApiFetchLeaguesResponse,
	TApiFetchLeagueUsersResponse,
	TApiFetchRanksResponse,
	TApiGameBarResponse,
	TApiGetTeamResponse,
	TApiGetTeamsResponse,
	TApiRegisterResponse,
	TApiTransferHistoryResponse,
	TApiTransferResponse,
} from "modules/types/api";
import {IPlayer, IPlayerStat, IRound} from "modules/types/json";
import {IHelpReducer, IHelpSection} from "modules/types/reducers";
import {IStatsCentrePlayer, IStatsCentreTeam} from "modules/types/shared";
import {SportType} from "modules/types/enums";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = (response: AxiosError) =>
	Promise.reject(response.response?.data).catch((err) => {
		if (!err) {
			throw new ApiError("Server error, please try later", 500);
		}
		return ApiError.CHECK(err as IApiResponse);
	});

const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

export const Api = {
	General: {
		ping: () => APIClient.get("ping"),
	},
	JSON: {
		checksums: () => JSONClient.get(`/checksums.json`),
		help_pages: (name: keyof IHelpReducer) => JSONClient.get<IHelpSection>(`/${name}.json`),
		rounds: (SPORT: string) => JSONClient.get<IRound[]>(`/${SPORT}/rounds.json`),
		players: (SPORT: string) =>
			JSONClient.get<IPlayer[]>(
				get(playersJSONSportMapper, SPORT, playersJSONSportMapper.MSL) as string
			),
		squads: (SPORT: string) => JSONClient.get<ISquad[]>(`/${SPORT}/squads.json`),
		venues: () => JSONClient.get("/venues.json"),
		all_players_stats: (SPORT: string) =>
			JSONClient.get<Record<number, IStatsCentrePlayer>>(`/${SPORT}/player_stats.json`),
		all_teams_stats: (SPORT: string) =>
			JSONClient.get<Record<number, IStatsCentreTeam>>(`/${SPORT}/team_stats.json`),
		player_stats: (request: IWithSportPayload<number>) =>
			JSONClient.get<IPlayerStat[]>(
				`${request.sport.toLowerCase()}/player_stats/${request.payload}.json`
			),
		notification_bar: () => JSONClient.get(`/notification_bar.json`),
		mrec: () => JSONClient.get(`/msl/mrecs.json`),
	},
	TeamGameday: {
		get: (sport: SportType) =>
			APIClient.get<TApiGetTeamsResponse>(`/${sport.toLowerCase()}/gameday_team/show_my`),
		create: (request: IWithSportPayload<ITeamRequestGameDay>) =>
			APIClient.post<TApiGetTeamResponse>(
				`${request.sport.toLowerCase()}/gameday_team/create`,
				request.payload
			),
		update: (request: IWithSportPayload<ITeamRequestGameDay>) =>
			APIClient.post<TApiGetTeamResponse>(
				`${request.sport.toLowerCase()}/gameday_team/update/${request.payload.round}`,
				{
					lineup: request.payload.lineup,
					name: request.payload.name,
				}
			),
		autopickFree: (params: IAutoPickGameDayPayload) =>
			APIClient.post<TApiAutoPickGameDayResponse>(
				`${params.sport}/gameday_team/autopick-free`,
				params.payload
			),
		autopick: (params: IAutoPickGameDayPayload) =>
			APIClient.post<TApiAutoPickGameDayResponse>(
				`${params.sport}/gameday_team/autopick`,
				params.payload
			),
	},
	Team: {
		get: (sport: SportType) =>
			APIClient.get<TApiGetTeamResponse>(`/${sport.toLowerCase()}/fantasy_team`),
		create: (params: IWithSportPayload<INewTeamRequest>) =>
			APIClient.post<TApiGetTeamResponse>(
				`/${params.sport.toLowerCase()}/fantasy_team`,
				params.payload
			),
		update: (params: IWithSportPayload<INewTeamRequest>) =>
			APIClient.post<TApiGetTeamResponse>(
				`${params.sport.toLowerCase()}/fantasy_team`,
				params.payload
			),
		autoPick: (params: IWithSportPayload<IAutoPickRequest>) =>
			APIClient.post<TApiAutoPickResponse>(
				`${params.sport.toLowerCase()}/fantasy_team/autopick`,
				params.payload
			),
		autoPickFree: (params: IWithSportPayload<IAutoPickRequest>) =>
			APIClient.post<TApiAutoPickResponse>(
				`${params.sport.toLowerCase()}/fantasy_team/autopick-free`,
				params.payload
			),
		makeTrade: (params: IWithSportPayload<ITradeRequestPayload>) =>
			APIClient.post<TApiTransferResponse>(
				`${params.sport.toLowerCase()}/fantasy_trade/make`,
				params.payload
			),
		fetchGameBar: (params: IWithSportPayload<IGameBarPayload>) =>
			APIClient.get<TApiGameBarResponse>(
				`${params.sport.toLowerCase()}/fantasy_ranking/gamebar`,
				params.payload
			),
		fetchLeaderboard: (params: IWithSportPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchRanksResponse>(
				`${params.sport.toLowerCase()}/fantasy_ranking`,
				params.payload
			),
	},
	FantasyTrades: {
		history: (params: {sport: SportType}) =>
			APIClient.get<TApiTransferHistoryResponse>(
				`${params.sport.toLowerCase()}/fantasy_trade/history`
			),
	},
	Contact: {},
	User: {
		getUser: () => APIClient.get<TApiRegisterResponse>("/user"),
		updateUser: (request: IUserUpdatePayload) =>
			APIClient.post<TApiRegisterResponse>("/user/update", request),
		changePassword: (request: IUserUpdatePasswordPayload) =>
			APIClient.post<TApiRegisterResponse>("/user/update", request),
		passwordChangeRequest: (request: string) =>
			APIClient.post<unknown>("/auth/password_reset/request", {email: request}),
		passwordResetRequest: (request: IResetPasswordPayload) =>
			APIClient.post<unknown>("auth/password_reset", request),
		contactUs: (request: IContactUsRequestPayload) =>
			APIClient.post<unknown>("/contact", request),
	},
	Auth: {
		register: (request: IRegisterPayload) =>
			APIClient.post<TApiRegisterResponse>("auth/register", request),
		login: (request: ILoginPayload) =>
			APIClient.post<TApiRegisterResponse>("auth/login", request),
		logout: () => APIClient.post("auth/logout"),
	},
	League: {
		fetch: (request: IWithSportPayload<IFetchLeaguesGetParams>) =>
			APIClient.get<TApiFetchLeaguesResponse>(
				`${request.sport.toLowerCase()}/fantasy_leagues`,
				request.payload
			),
		create: (request: IWithSportPayload<ICreateLeagueRequestPayload>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLowerCase()}/fantasy_league`,
				request.payload
			),
		update: (request: ISportWithIdPayload<ICreateLeagueRequestPayload>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLowerCase()}/fantasy_league/${request.id}`,
				request.payload
			),
		fetchJoin: (request: IWithSportPayload<IPaginationRequest>) =>
			APIClient.get<TApiFetchJoinLeaguesResponse>(
				`${request.sport.toLowerCase()}/fantasy_league/show-for-join`,
				request.payload
			),
		join: (request: IWithSportPayload<string>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLowerCase()}/fantasy_league/${request.payload}/join`
			),
		leave: (request: IWithSportPayload<number>) =>
			APIClient.post<unknown>(
				`${request.sport.toLowerCase()}/fantasy_league/${request.payload}/leave`
			),
		sendInvites: (request: ISportWithIdPayload<ILeagueInvitePayload[]>) =>
			APIClient.post<unknown>(
				`${request.sport.toLowerCase()}/fantasy_league/${request.id}/invite`,
				{invites: request.payload}
			),
		fetchUsers: (request: ISportWithIdPayload<IPaginationRequest>) =>
			APIClient.get<TApiFetchLeagueUsersResponse>(
				`${request.sport.toLowerCase()}/fantasy_league/${request.id}/league-users`,
				request.payload
			),
		fetchRanks: (request: ISportWithIdPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchLeagueRanksResponse>(
				`${request.sport.toLowerCase()}/fantasy_ranking/league/${request.id}`,
				request.payload
			),
		removeLeagueUser: (request: ISportWithIdPayload<number>) =>
			APIClient.post<unknown>(
				`${request.sport.toLowerCase()}/fantasy_league/${request.id}/user/${
					request.payload
				}`
			),
		fetchTable: (request: ISportWithIdPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchRanksResponse>(
				`${request.sport.toLowerCase()}/fantasy_ranking/league/${request.id}`,
				request.payload
			),
	},

	LeagueGameDay: {
		fetch: (request: IWithSportPayload<IFetchLeaguesGetParams>) =>
			APIClient.get<TApiFetchLeaguesResponse>(
				`${request.sport.toLowerCase()}/gameday_leagues`,
				request.payload
			),
		create: (request: IWithSportPayload<ICreateLeagueRequestPayload>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLowerCase()}/gameday_league`,
				request.payload
			),
		update: (request: ISportWithIdPayload<ICreateLeagueRequestPayload>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLowerCase()}/gameday_league/${request.id}`,
				request.payload
			),
		fetchJoin: (request: IWithSportPayload<IPaginationRequest>) =>
			APIClient.get<TApiFetchJoinLeaguesResponse>(
				`${request.sport.toLowerCase()}/gameday_league/show-for-join`,
				request.payload
			),
		join: (request: IWithSportPayload<string>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLowerCase()}/gameday_league/${request.payload}/join`
			),
		leave: (request: IWithSportPayload<number>) =>
			APIClient.post<unknown>(
				`${request.sport.toLowerCase()}/gameday_league/${request.payload}/leave`
			),
		sendInvites: (request: ISportWithIdPayload<ILeagueInvitePayload[]>) =>
			APIClient.post<unknown>(
				`${request.sport.toLowerCase()}/gameday_league/${request.id}/invite`,
				{invites: request.payload}
			),
		fetchUsers: (request: ISportWithIdPayload<IPaginationRequest>) =>
			APIClient.get<TApiFetchLeagueUsersResponse>(
				`${request.sport.toLowerCase()}/gameday_league/${request.id}/league-users`,
				request.payload
			),
		fetchRanks: (request: ISportWithIdPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchLeagueRanksResponse>(
				`${request.sport.toLowerCase()}/gameday_ranking/league/${request.id}`,
				request.payload
			),
		removeLeagueUser: (request: ISportWithIdPayload<number>) =>
			APIClient.post<unknown>(
				`${request.sport.toLowerCase()}/gameday_league/${request.id}/user/${
					request.payload
				}`
			),
		fetchTable: (request: ISportWithIdPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchRanksResponse>(
				`${request.sport.toLowerCase()}/gameday_ranking/league/${request.id}`,
				request.payload
			),
	},

	Rankings: {},
	RankingsGameDay: {
		fetchGameBar: (params: IWithSportPayload<IGameBarPayload>) =>
			APIClient.get<TApiGameBarResponse>(
				`${params.sport.toLowerCase()}/gameday_ranking/gamebar`,
				params.payload
			),
		fetchLeaderboard: (params: IWithSportPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchRanksResponse>(
				`${params.sport.toLowerCase()}/gameday_ranking`,
				params.payload
			),
	},
	Dashboard: {},
	Gamebar: {},
	Answer: {},
};

export * from "./ApiError";
