import React, {useCallback} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
} from "components/Modals/styled";
import {IconRemove} from "components/Icons";
import warningImage from "assets/images/icons/warning.png";
import styled from "styled-components";
import {SecondaryButton, WarningButton} from "components/styled";
import {saveTeam, toggleEditTeamNameModal, toggleNoHeadsModal} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getIsNoHeadsModalOpen, getTeam, getTeamHeads} from "modules/selectors";
import {Exist} from "components/Exist";

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		width: 64px;
		height: auto;
		margin: 30px 0 24px 0;
	}

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin: 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		font-weight: normal;
		margin-bottom: 30px;
	}
`;

export const NoTeamHeadsModal: React.FC = () => {
	const dispatch = useDispatch();
	const team = useSelector(getTeam);
	const isModalOpen = useSelector(getIsNoHeadsModalOpen);
	const heads = useSelector(getTeamHeads);

	const isHeadsInvalid = !heads.captain && heads.viceCaptain;

	const closeModal = useCallback(() => {
		dispatch(toggleNoHeadsModal(false));
	}, [dispatch]);

	const goToNextStep = useCallback(() => {
		if (!team?.name) {
			dispatch(toggleEditTeamNameModal(true));
			closeModal();
			return;
		}
		dispatch(saveTeam());
		closeModal();
	}, [dispatch, team, closeModal]);

	if (!isModalOpen) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerContainer>
					<img src={warningImage} alt="warning" />
					<h3>{isHeadsInvalid ? "Missing Captain" : "Missing Captains"}</h3>
					<Exist when={!isHeadsInvalid}>
						<p>
							Give your team a Captain and Vice Captain. <br />
							They will earn your team extra points.
						</p>
					</Exist>
					<Exist when={Boolean(isHeadsInvalid)}>
						<p>Choose a Captain and Vice Captain for your team to earn extra points!</p>
					</Exist>
					<ModalActions>
						<Exist when={!isHeadsInvalid}>
							<SecondaryButton onClick={goToNextStep}>No, Thanks</SecondaryButton>
						</Exist>
						<WarningButton onClick={closeModal}>Back to team</WarningButton>
					</ModalActions>
				</InnerContainer>
			</ModalContent>
		</ModalBackdrop>
	);
};
