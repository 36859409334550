import {createAction} from "redux-act";
import {
	IContactUsRequestPayload,
	ILoginPayload,
	IRegisterPayload,
	IResetPasswordPayload,
	IUser,
	IUserUpdatePasswordPayload,
	IUserUpdatePayload,
} from "modules/types/user";
import {IGenerateRequestStateHandler} from "modules/types";
import {IUserReducer} from "modules/types/reducers";

export const fetchUser = createAction();
export const userChecked = createAction();
export const userUpdateRequest = createAction<IUserUpdatePayload>();
export const userUpdateSuccess = createAction<IUser>();

export const userRegister = createAction<IRegisterPayload>();
export const userRegisterSuccess = createAction<IUser>();

export const userLogin = createAction<ILoginPayload>();
export const userLoginSuccess = createAction<IUser>();

export const userLogoutAndClear = createAction();
export const userLogoutAndClearRequest = createAction();
export const userLogoutAndClearSuccess = createAction();

export const userChangePasswordRequest = createAction<IUserUpdatePasswordPayload>();
export const userResetPasswordRequest = createAction<IResetPasswordPayload>();

export const userStateChangeAction =
	createAction<IGenerateRequestStateHandler<IUserReducer["userState"]>>();

export const requestPasswordChange = createAction<string>();

export const contactUsRequest = createAction<IContactUsRequestPayload>();
