import React from "react";
import emptyPlayer from "assets/images/emptyPlayer.png";
import {Stats} from "components/Team/Player/Stats";
import {
	EmptyPlayerName,
	Figure,
	PlayerData,
	PlayerInfoTop,
	PlayerInfoWrapper,
	Wrapper,
} from "./styled";
import {StatsTab} from "modules/types/enums";

interface IProps {
	playerID: number;
	onClickEmptyCell?: () => void;
	onClickTransfer?: (playerID: number) => void;
	showStats: StatsTab;
	isRemoveDisabled?: boolean;
	isTransferDisabled?: boolean;
	isPublicTeam?: boolean;
}

export const PlayerEmpty: React.FC<IProps> = ({onClickEmptyCell, showStats}) => (
	<Wrapper className="empty-cell" onClick={onClickEmptyCell}>
		<PlayerInfoTop>
			<Figure className="empty">
				<img src={emptyPlayer} alt="No player selected" />
			</Figure>
		</PlayerInfoTop>
		<PlayerInfoWrapper>
			<EmptyPlayerName>Add player</EmptyPlayerName>
			<PlayerData>
				<Stats shownStats={showStats} />
			</PlayerData>
		</PlayerInfoWrapper>
	</Wrapper>
);
