import React from "react";
import {useSelector} from "react-redux";
import {getProject, getSport} from "modules/selectors";
import {Link} from "react-router-dom";

interface IProps {
	to: string;
	end?: boolean;
	className?: string;
}

export const FooterSportLink: React.FC<IProps> = ({to, end, children, className}) => {
	const sport = useSelector(getSport);
	const project = useSelector(getProject);

	const link = `/${project}/${sport}${to}`;
	return (
		<Link to={link} className={className}>
			{children}
		</Link>
	);
};
