import React, {SyntheticEvent} from "react";
import {useSelector} from "react-redux";
import {getSquadById} from "modules/selectors";
import emptyPlayer from "assets/images/emptyPlayer.png";
import styled from "styled-components";
import {REACT_APP_LOCAL_IMAGE_URL} from "modules/constants";

const Img = styled.img`
	position: relative;
	top: -12px;

	&[data-default-jersey="true"] {
		top: 0;
		width: 90%;
	}
`;

interface IProps {
	squadId: number;
	alt?: string;
	className?: string;
}

export const PlayerJersey: React.FC<IProps> = ({squadId, alt, className}) => {
	const squad = useSelector(getSquadById)(squadId);
	const jerseyImage = `${REACT_APP_LOCAL_IMAGE_URL}squads/jersey/${squadId}.png`;

	const altText = alt || squad?.short_name;

	const loadFallback = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		const image = e.target as HTMLImageElement;
		if (image.src !== emptyPlayer) {
			image.src = emptyPlayer;
			image.setAttribute("data-default-jersey", "true");
		}
	};

	return <Img src={jerseyImage} alt={altText} className={className} onError={loadFallback} />;
};
