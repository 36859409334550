import React, {useEffect} from "react";
import {fetchUser} from "modules/actions";
import {useDispatch} from "react-redux";
import {GlobalErrorModal, GlobalInfoModal} from "components/Modals";

export const HOCAuth: React.FC = ({children}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchUser());
	}, [dispatch]);

	return (
		<React.Fragment>
			<React.Fragment>{children}</React.Fragment>
			<GlobalErrorModal />
			<GlobalInfoModal />
		</React.Fragment>
	);
};
