import {createReducer} from "redux-act";
import {RequestStateType} from "modules/types/enums";
import {IRankingsGameDay} from "modules/types";
import {
	fetchGameBarGameDaySuccess,
	rankingsRequestStateHandler,
	fetchLeaderboardGameDayConcat,
	fetchLeaderboardGameDayRequest,
	fetchLeaderboardGameDaySuccess,
} from "modules/actions";

const initial: IRankingsGameDay = {
	leaderboardData: {
		next: false,
		ranks: [],
	},
	gameBar: {
		round_rank: null,
		overall_rank: null,
		round_points: null,
		overall_points: null,
	},
	requestState: {
		leaderboard: RequestStateType.Idle,
	},
};

export const rankingsGameDay = createReducer<IRankingsGameDay>({}, initial)
	.on(rankingsRequestStateHandler, (state, payload) => ({
		...state,
		requestState: {...state.requestState, [payload.key]: payload.state},
	}))
	.on(fetchGameBarGameDaySuccess, (state, payload) => ({
		...state,
		gameBar: payload,
	}))
	.on(fetchLeaderboardGameDayRequest, (state) => ({
		...state,
		requestState: {...state.requestState, leaderboard: RequestStateType.Loading},
	}))
	.on(fetchLeaderboardGameDaySuccess, (state, payload) => ({
		...state,
		leaderboardData: {
			next: payload.next,
			ranks: payload.payload,
		},
		requestState: {...state.requestState, leaderboard: RequestStateType.Success},
	}))
	.on(fetchLeaderboardGameDayConcat, (state, payload) => ({
		...state,
		leaderboardData: {
			next: payload.next,
			ranks: [...state.leaderboardData.ranks, ...payload.payload],
		},
		requestState: {...state.requestState, leaderboard: RequestStateType.Success},
	}));
