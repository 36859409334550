import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {IconBurgerMenu, IconRemove} from "components";
import {HOCAccordionLink} from "components/HOCs/HOCAccordion";
import {
	FirstLevelExternalNavLink,
	FirstLevelNavLink,
	HeaderLogo,
	LogoWrapper,
	MockSponsorLogo,
	SecondLevelNavLink,
} from "components/styled";
import SponsorLogo from "assets/images/SponsorLogo.svg";
import {useDispatch, useSelector} from "react-redux";
import {getIsUserLogged, getProject, getSport} from "modules/selectors";
import {Link, useLocation} from "react-router-dom";
import {userLogoutAndClearRequest} from "modules/actions";
import url from "assets/images/icons/url.png";
import {SportSelector} from "components/Header/SportSelector";
import {HeaderLogoImage} from "./HeaderLogoImage";
import {APP_IS_AD_ENABLED} from "modules/constants";

const headerHeight = "58px";

const MobileMenuWrapper = styled.div`
	position: relative;
	box-sizing: border-box;
	height: ${headerHeight};
	width: 100%;
	background: url(${({theme}) => theme.header.right_image}),
		${({theme}) => theme.header.main.background};
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	color: ${({theme}) => theme.header.main.color};
	padding: 12px 20px;
	z-index: 5;
`;

const MobileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
`;

const ToggleMenuButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
`;

const MobileMenuBlock = styled.div`
	box-sizing: border-box;
	position: absolute;
	top: calc(${headerHeight} - 2px);
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	overflow: auto;
	width: 100%;
	z-index: 9;
	height: calc(100vh - ${headerHeight} + 2px);
	background: ${({theme}) => theme.header.main.background};
	border-top: 2px solid ${({theme}) => theme.header.main.border};
	color: ${({theme}) => theme.header.main.color};
	padding-bottom: 140px;
`;

const LoginButtonWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: 1px solid #ffffff;

	a,
	button {
		box-sizing: border-box;
		width: 100%;
		padding: 18px;
		color: #ffffff;
		display: flex;
		text-decoration: none;
		background: ${({theme}) => theme.header.menu.opened_item_color};
		border: none;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 20px;
	}
`;

const Relative = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

export const MobileMenu: React.FC = () => {
	const dispatch = useDispatch();
	const isLogged = useSelector(getIsUserLogged);
	const sport = useSelector(getSport);
	const currentProject = useSelector(getProject);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const location = useLocation();

	const sportLink = useCallback(
		(link: string) => {
			return `/${currentProject}/${sport}${link}`;
		},
		[currentProject, sport]
	);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen(!isMenuOpen);
		if (!isMenuOpen) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			document.body.classList.add("menu-open");
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			document.body.classList.remove("menu-open");
		}
	}, [isMenuOpen]);

	const isMenuOpenClass = isMenuOpen ? "open" : "";

	useEffect(() => {
		document.body.classList.remove("menu-open");
		setIsMenuOpen(false);
	}, [location]);

	const logout = () => {
		dispatch(userLogoutAndClearRequest());
	};

	return (
		<MobileMenuWrapper className={isMenuOpenClass}>
			<MobileHeader>
				<Relative>
					<LogoWrapper>
						<HeaderLogo to="/">
							<HeaderLogoImage />
						</HeaderLogo>
						<Exist when={APP_IS_AD_ENABLED}>
							<div>
								<p>Sponsored by</p>
								<MockSponsorLogo src={SponsorLogo} alt="Sponsor logo" />
							</div>
						</Exist>
					</LogoWrapper>
					<SportSelector />
				</Relative>

				<ToggleMenuButton onClick={toggleMenu}>
					<Exist when={isMenuOpen}>
						<IconRemove color="#FFFFFF" width={28} height={28} />
					</Exist>
					<Exist when={!isMenuOpen}>
						<IconBurgerMenu color="#FFFFFF" />
					</Exist>
				</ToggleMenuButton>
			</MobileHeader>
			<Exist when={isMenuOpen}>
				<MobileMenuBlock>
					<div>
						<FirstLevelNavLink to={sportLink("/team")}>My team</FirstLevelNavLink>
					</div>
					<Exist when={isLogged}>
						<HOCAccordionLink link={sportLink("/leagues")} text="Leagues">
							<SecondLevelNavLink to={sportLink("/leagues")}>
								My Leagues
							</SecondLevelNavLink>
							<SecondLevelNavLink to={sportLink("/leagues/create")}>
								Create a league
							</SecondLevelNavLink>
							<SecondLevelNavLink to={sportLink("/leagues/join")}>
								Join a league
							</SecondLevelNavLink>
						</HOCAccordionLink>
						<div>
							<FirstLevelNavLink to={sportLink("/leaderboard")}>
								Leaderboard
							</FirstLevelNavLink>
						</div>
						<div>
							<FirstLevelNavLink to={sportLink("/stats-centre")}>
								Stats Centre
							</FirstLevelNavLink>
						</div>
						<div>
							<FirstLevelExternalNavLink href="https://www.malaysianfootballleague.com/">
								Malaysian Football League
								<img src={url} alt="url" />
							</FirstLevelExternalNavLink>
						</div>
						<div>
							<HOCAccordionLink link={sportLink("/account")} text="Account">
								<SecondLevelNavLink to={sportLink("/account")}>
									Details
								</SecondLevelNavLink>
								<SecondLevelNavLink to={sportLink("/account/password")}>
									Change password
								</SecondLevelNavLink>
							</HOCAccordionLink>
						</div>
					</Exist>

					<HOCAccordionLink link={sportLink("/help")} text="Help">
						<SecondLevelNavLink to={sportLink("/help")}>The Game</SecondLevelNavLink>
						<SecondLevelNavLink to={sportLink("/help/faqs")}>FAQs</SecondLevelNavLink>
						<SecondLevelNavLink to={sportLink("/help/contact-us")}>
							Contact us
						</SecondLevelNavLink>
						<SecondLevelNavLink to={sportLink("/help/terms")}>
							Terms and Conditions
						</SecondLevelNavLink>
					</HOCAccordionLink>
					<LoginButtonWrapper>
						<Exist when={!isLogged}>
							<Link to="login">Log in</Link>
						</Exist>
						<Exist when={isLogged}>
							<button onClick={logout}>Log out</button>
						</Exist>
					</LoginButtonWrapper>
				</MobileMenuBlock>
			</Exist>
		</MobileMenuWrapper>
	);
};
