import {PureButton} from "components/styled";
import styled, {css} from "styled-components";

interface IPlayerNameView {
	isPlaying: boolean;
	isInjured: boolean;
}

export const getPlayerNameStyles = ({isPlaying, isInjured}: IPlayerNameView) => {
	if (isInjured) {
		return css`
			color: #a82727;
		`;
	}
	if (isPlaying) {
		return css`
			color: #0bad0b;
		`;
	}

	return css`
		color: ${({theme}) => theme.text_color};
	`;
};

export const HoverBox = styled.div<IPlayerNameView>`
	display: none !important;
	position: absolute;
	height: 96%;
	width: 98%;
	justify-content: space-around;
	bottom: -74px;
	left: 0;
	z-index: 3;
	border-radius: 2px 2px 0 0;
	align-items: center;
	${getPlayerNameStyles};

	@media (max-width: 560px) {
		bottom: -47px;
		width: 96%;
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
	}
`;

export const Figure = styled.figure`
	width: 60px;
	height: 60px;
	margin: 0 auto -8px;
	position: relative;
	z-index: 2;

	@media (max-width: 560px) {
		margin-bottom: -5px;
		width: 40px;
		height: 40px;
	}

	img {
		display: block;
		width: 100%;
	}

	&.empty {
		img {
			width: auto;
			margin: 0 auto;
		}

		@media (max-width: 560px) {
			img {
				width: 90%;
				height: auto;
			}
		}
	}
`;

export const Wrapper = styled.div`
	width: 100px;
	height: 97px;
	position: relative;
	cursor: pointer;

	@media (max-width: 560px) {
		width: 50px;
		height: 65px;
		&.game-day {
			width: 70px;
		}
	}

	&.active,
	&:hover {
		${HoverBox} {
			display: flex;
			border-bottom: 1px solid #ffffff;
			border-left: 1px solid #ffffff;
			border-right: 1px solid #ffffff;
			border-radius: 0 0 3px 3px;
		}
	}

	&.empty-cell {
		&.active,
		&:hover {
			${Figure} {
				visibility: visible;
			}
		}
	}
`;

export const PlayerData = styled.div`
	display: flex;
	font-size: 10px;
	height: 20px;

	@media (max-width: 560px) {
		height: 15px;
	}
`;

export const EmptyPlayerName = styled(PureButton)`
	background: #ffffff;
	font-size: 12px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0 4px;
	width: 100px;

	@media (max-width: 560px) {
		font-size: 8px;
		line-height: 14px;
		height: 15px;
		width: 100%;
	}
`;

export const PlayerInfoWrapper = styled.div`
	position: relative;
	overflow: hidden;
	border-radius: 2px;
	z-index: 3;
	color: #111;
`;

export const PlayerInfoTop = styled.div`
	position: relative;
	width: 100%;
`;
