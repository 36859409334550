import {createReducer} from "redux-act";

export interface IPosition {
	id: number;
	name: string;
	shortName: string;
	plural: string;
}

const initialState: IPosition[] = [
	{
		id: 1,
		name: "Goalkeepers",
		shortName: "GK",
		plural: "Goalkeepers",
	},
	{
		id: 2,
		name: "Defenders",
		shortName: "DEF",
		plural: "Defenders",
	},
	{
		id: 3,
		name: "Midfielders",
		shortName: "MID",
		plural: "Midfielders",
	},
	{
		id: 4,
		name: "Forwards",
		shortName: "ST",
		plural: "Forwards",
	},
];

export const positions = createReducer<IPosition[]>({}, initialState);
