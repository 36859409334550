import {createSelector} from "reselect";
import {MatchStatus, RoundStatus} from "modules/types/enums";
import {
	getGameDaySelectedTeam,
	getFirstScheduledRound,
	getRounds,
	getRoundsById,
	getTeam,
	isTransfersLeftLimit,
} from "modules/selectors";

export const getIsTeamLockout = createSelector(getTeam, getRounds, (team, rounds) => {
	if (!team || !("startRound" in team)) {
		return false;
	}

	const startRound = rounds.find((round) => round.id === team.startRound);
	return startRound ? startRound.status !== RoundStatus.Scheduled : false;
});

export const isGameDayTeamLockout = createSelector(
	getGameDaySelectedTeam,
	getRoundsById,
	(team, rounds) => {
		if (!team) {
			return false;
		}
		const startRound = rounds[team.roundId];
		return startRound ? startRound.status !== RoundStatus.Scheduled : false;
	}
);

// const twoHours = 1000 * 60 * 60 * 2;
export const getTradesDeadlineTime = createSelector(getFirstScheduledRound, (round) => {
	if (!round) {
		return;
	}
	const firstMatch = round.matches.find((match) => match.status === MatchStatus.Scheduled);

	if (!firstMatch) {
		return;
	}

	return new Date(firstMatch.date).valueOf();
});

export const isPlayerPoolTransferOutDisabled = createSelector(
	getIsTeamLockout,
	isTransfersLeftLimit,
	(isLockout, isSeasonOutLimit) => {
		return isLockout && !isSeasonOutLimit;
	}
);
