import React, {useCallback, useEffect} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
} from "components/Modals/styled";
import {IconRemove} from "components/Icons";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {TransferViewResult} from "components/TransferViewResult";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {
	getTeamRemainingSalary,
	getTeamRequestState,
	getTotalWeekTransfersLimit,
	getTransferModalState,
	getTransfersLeft,
} from "modules/selectors";
import {closeTransferModal} from "modules/actions/modals";
import {makeTradeRequest} from "modules/actions";
import {SALARY_CUP_DELIMITER, WEEK_TRANSFERS} from "modules/constants";

const InnerWrapper = styled.div`
	h2 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}
`;

const TransferData = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px 24px;
	gap: 24px;

	h3 {
		margin: 0;
		color: ${({theme}) => theme.text_color};
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 29px;
		text-align: center;
	}

	p {
		margin: 0 0 6px 0;
		color: ${({theme}) => theme.text_color};
		font-size: 12px;
		letter-spacing: 0;
		line-height: 15px;
		text-align: center;
		max-width: 95px;
	}
`;

export const TransferModal: React.FC = () => {
	const dispatch = useDispatch();
	const isModalOpen = useSelector(getTransferModalState);
	const remainBudget = Math.floor(useSelector(getTeamRemainingSalary) / SALARY_CUP_DELIMITER);
	const {isLoading, isSuccess} = useSelector(getTeamRequestState)("trade");
	const {weeklyLeft} = useSelector(getTransfersLeft);
	const weeklyLimit = useSelector(getTotalWeekTransfersLimit);

	const closeModal = useCallback(() => {
		dispatch(closeTransferModal());
	}, [dispatch]);

	const makeTransfer = useCallback(() => {
		dispatch(makeTradeRequest());
	}, [dispatch]);

	useEffect(() => {
		if (isSuccess) {
			closeModal();
		}
	}, [isSuccess, closeModal]);

	if (!isModalOpen) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerWrapper>
					<h2>Confirm Transfer</h2>
					<TransferViewResult />
					<TransferData>
						<div>
							<p>Weekly Transfers Remaining</p>
							<h3>
								{weeklyLeft}/{weeklyLimit ?? WEEK_TRANSFERS}
							</h3>
						</div>
						<div>
							<p>Budget Remaining</p>
							<h3>RM{remainBudget}m</h3>
						</div>
					</TransferData>

					<ModalActions>
						<SecondaryButton disabled={isLoading} onClick={closeModal}>
							Go back
						</SecondaryButton>
						<PrimaryButton disabled={isLoading} onClick={makeTransfer}>
							Make transfer
						</PrimaryButton>
					</ModalActions>
				</InnerWrapper>
			</ModalContent>
		</ModalBackdrop>
	);
};
