import styled from "styled-components";
import {HeaderContainer, HeaderNavLink} from "components/styled";
import React from "react";
import {useLocation} from "react-router-dom";

const SubMenuWrapper = styled(HeaderContainer)`
	display: flex;
	justify-content: flex-start;
	padding-left: 250px;
	height: 100%;

	${HeaderNavLink} {
		height: calc(100% - 2px);
		border-bottom: 2px solid transparent;
		color: ${({theme}) => theme.header.sub.color};

		&.active,
		&:hover,
		&:active,
		&:focus {
			background: transparent;
			color: ${({theme}) => theme.header.sub.color};
			border-bottom: 2px solid ${({theme}) => theme.header.sub.color};

			&:after {
				border-bottom: 10px solid ${({theme}) => theme.header.sub.color};
			}
		}
	}
`;

interface IProps {
	visibilityPath: string;
}

export const DesktopSubMenu: React.FC<IProps> = ({visibilityPath, children}) => {
	const location = useLocation();

	if (!location.pathname.includes(visibilityPath)) {
		return null;
	}
	return <SubMenuWrapper>{children}</SubMenuWrapper>;
};
