import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	getActualRound,
	getIsTeamLockout,
	getIsUserLogged,
	getSalaryCup,
	getSelectedRoundId,
	getTeamGameBar,
	getTeamRemainingSalary,
	getTransfersLeft,
	isGameDayGame,
} from "modules/selectors";
import {SALARY_CUP_DELIMITER} from "modules/constants";
import {fetchGameBarRequest} from "modules/actions";
import {useIsPathIncludes} from "modules/utils/hooks";
import {TeamCaptainSelect} from "components/Team/TeamCaptainSelect";
import {useMediaQuery} from "@mui/material";
import {Exist} from "components/Exist";
import {RoundStatus} from "modules/types/enums";
import {StatsBlock} from "components";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 10px 10px 15px 10px;
	border-radius: 0 0 5px 5px;
	border: 1px solid #dddddd;
	border-top: 0;
`;

export const TeamStats: React.FC = () => {
	const isCaptainsMobile = useMediaQuery("(max-width: 900px)");
	const dispatch = useDispatch();
	const isLogged = useSelector(getIsUserLogged);
	const selectedRound = useSelector(getSelectedRoundId);
	const actualRound = useSelector(getActualRound);
	const salaryCup = useSelector(getSalaryCup);
	const remainBudget = Number(
		(useSelector(getTeamRemainingSalary) / SALARY_CUP_DELIMITER).toFixed(2)
	);
	const teamValue = Number((salaryCup / SALARY_CUP_DELIMITER - remainBudget).toFixed(2));
	const {weeklyLeft, seasonLeft} = useSelector(getTransfersLeft);
	const gameBar = useSelector(getTeamGameBar);

	const isPreseasonFlag = !useSelector(getIsTeamLockout);
	const isTransferFlag = useIsPathIncludes("transfers");
	const isGameDay = useSelector(isGameDayGame);

	useEffect(() => {
		if (selectedRound && isLogged) {
			const activeRoundSchecduled = actualRound?.status === RoundStatus.Scheduled;
			const prevRoundID = activeRoundSchecduled ? selectedRound - 1 : 0;
			const roundIDToUse = prevRoundID > 0 ? prevRoundID : selectedRound;
			dispatch(fetchGameBarRequest({round: roundIDToUse}));
		}
	}, [isLogged, selectedRound, actualRound?.status, dispatch]);

	if (isGameDay) {
		return (
			<Wrapper>
				<StatsBlock title="Points" value={gameBar.round_points} />
				<StatsBlock title="Position" value={gameBar.round_rank} />
			</Wrapper>
		);
	}

	if (isPreseasonFlag) {
		return (
			<Wrapper>
				<StatsBlock title="Team Value" value={`RM${teamValue}m`} />
				<StatsBlock title="Remaining Budget" value={`RM${remainBudget}m`} />
				<Exist when={!isCaptainsMobile}>
					<TeamCaptainSelect />
				</Exist>
			</Wrapper>
		);
	}

	if (isTransferFlag) {
		return (
			<Wrapper>
				<StatsBlock title="Week Transfers remaining" value={String(weeklyLeft || 0)} />
				<StatsBlock title="Season Transfers remaining" value={String(seasonLeft || 0)} />
				<StatsBlock title="Remaining Budget" value={`RM${remainBudget}m`} />
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<StatsBlock title="Week Points" value={gameBar.round_points} />
			<StatsBlock title="Overall Points" value={gameBar.overall_points} />
			<StatsBlock title="Week Position" value={gameBar.round_rank} />
			<StatsBlock title="Overall Position" value={gameBar.overall_rank} />
			<Exist when={!isCaptainsMobile}>
				<TeamCaptainSelect />
			</Exist>
		</Wrapper>
	);
};
