import {createSelector} from "reselect";
import {getTeamsState} from "modules/actions";
import {getPlayersById, getRounds, getSelectedRoundId, isClassicGame} from "modules/selectors";
import {IDictionary, ILineup, IStore} from "modules/types";
import {
	chain,
	filter,
	find,
	get,
	identity,
	keyBy,
	last,
	memoize,
	range,
	size,
	toInteger,
} from "lodash";
import {FORMATION_LIST} from "modules/constants";
import {RequestStateType, RoundStatus} from "modules/types/enums";
import {ITeamsReducer} from "modules/types/reducers";
import {
	DEFAULT_FORMATION_CLASSIC,
	DEFAULT_FORMATION_GAMEDAY,
	DEFAULT_TEAM_GAMEDAY,
} from "modules/utils/Team";
import {IPlayer} from "modules/types/json";

const getState = ({teams}: IStore) => teams;

export const getSalaryCup = createSelector(getState, (state) => state.salaryCup);
export const getPublicTeam = createSelector(getTeamsState, ({publicTeam}) => publicTeam);
export const getTeam = createSelector(getTeamsState, ({team}) => team);
export const getTeamsGameDay = createSelector(getTeamsState, ({teamsGameDay}) => teamsGameDay);
export const getGameDayTeamsByRoundId = createSelector(getTeamsGameDay, (teams) =>
	keyBy(teams, "roundId")
);
export const getGameDaySelectedTeam = createSelector(
	getTeamsGameDay,
	getSelectedRoundId,
	(teams, roundId) => {
		const lastTeam = last(teams);
		const teamWithZero = find(teams, (team) => team.id === 0) || {
			...DEFAULT_TEAM_GAMEDAY[0],
			name: lastTeam ? lastTeam.name : "",
		};

		return teams.find((team) => team.roundId === roundId) || teamWithZero;
	}
);

export const getTeamForGame = createSelector(
	getTeam,
	getGameDaySelectedTeam,
	isClassicGame,
	(classicTeam, gameDayTeam, isClassic) => {
		return isClassic ? classicTeam : gameDayTeam;
	}
);

export const getTeamLineup = createSelector(getTeam, (team) => team?.lineup);
export const getTeamLineupGameDay = createSelector(getGameDaySelectedTeam, (teamGameDay) => {
	return teamGameDay.lineup;
});
export const getTeamEmptyLineup = createSelector(getTeamLineup, (lineup) => {
	const {captain, vice_captain, ...rest} = lineup;
	return rest;
});
export const isTeamFullFilled = createSelector(
	getTeamEmptyLineup,
	(lineup) => !chain(lineup).values().flatten().value().includes(0)
);
export const isTeamFullFilledGameDay = createSelector(
	getTeamLineupGameDay,
	(lineup) => !chain(lineup).values().flatten().value().includes(0)
);
export const getIsTeamPosFilledGameDay = createSelector(
	getTeamLineupGameDay,
	getPlayersById,
	(lineup, playersById) =>
		memoize((playerID: number) => {
			const player = playersById[playerID];
			if (!player) {
				return false;
			}

			const {position} = player;
			if (!lineup) {
				return false;
			}
			const lineupLength = lineup[position].filter((item) => item > 0).length;
			if (position === 1) {
				return lineupLength >= 1;
			}

			return lineupLength >= 2;
		})
);

export const getIsTeamLockoutInner = createSelector(getTeam, getRounds, (team, rounds) => {
	if (!team) {
		return false;
	}

	if (!("startRound" in team)) {
		return false;
	}

	const startRound = rounds.find((round) => round.id === team.startRound);
	return startRound ? startRound.status !== RoundStatus.Scheduled : false;
});

export const getIsTeamSaved = createSelector(getTeamsState, ({isTeamSaved}) => isTeamSaved);

export const isTeamLockedButNonFilled = createSelector(
	getIsTeamLockoutInner,
	getIsTeamSaved,
	(isLockout, isTeamSaved) => {
		return isLockout && !isTeamSaved;
	}
);

export const hasEmptySpot = (lineup: ILineup, position: number) =>
	lineup[position].some((id) => !id);

export const getAvailableFormation = (lineup: ILineup, position: number) => {
	const futureFormation = range(1, 5).map((row_index) => {
		const newPlayerPosition = row_index === position ? 1 : 0;

		return size(filter(lineup[row_index], identity)) + newPlayerPosition;
	});

	return find(FORMATION_LIST, (formationStr) =>
		formationStr
			.split("-")
			.map(toInteger)
			.every((maxPlayers, index) => futureFormation[index] <= maxPlayers)
	);
};

const checkIsInTeam = (playerID: number, lineup: ILineup, playersById: IDictionary<IPlayer>) => {
	const player = playersById[playerID];

	if (!player) {
		return false;
	}

	const {position, id} = player;

	if (!lineup) {
		return false;
	}

	return lineup[position]?.includes(id);
};

export const getPlayerInTeamCheckSelector = createSelector(
	getTeamLineup,
	getPlayersById,
	(lineup, playersById) =>
		memoize((playerID: number) => checkIsInTeam(playerID, lineup, playersById))
);

export const getPlayerInTeamGameDayCheckSelector = createSelector(
	getTeamLineupGameDay,
	getPlayersById,
	(lineup, playersById) =>
		memoize((playerID: number) => {
			return checkIsInTeam(playerID, lineup, playersById);
		})
);

export const getTeamValue = createSelector(
	getTeamLineup,
	getPlayersById,
	(lineup, playersById): number => {
		let captainID = 0;
		let captainCost = 0;
		if (lineup.captain) {
			captainID = Array.isArray(lineup.captain) ? lineup.captain[0] : lineup.captain;
			captainCost = get(playersById[captainID], "cost", 0);
		}
		let viceCaptainID = 0;
		let viceCaptainCost = 0;
		if (lineup.captain) {
			viceCaptainID = Array.isArray(lineup.vice_captain)
				? lineup.vice_captain[0]
				: lineup.vice_captain;
			viceCaptainCost = get(playersById[viceCaptainID], "cost", 0);
		}
		const teamCost = chain(lineup)
			.values()
			.flatten()
			.filter(identity)
			.value()
			.reduce((acc, id) => {
				acc += get(playersById[id], "cost", 0);
				return acc;
			}, 0);

		return teamCost - captainCost - viceCaptainCost;
	}
);

export const getTeamRemainingSalary = createSelector(
	getSalaryCup,
	getTeamValue,
	(salaryCup, teamValue) => salaryCup - teamValue
);

export const getIsEditTeamModalOpen = createSelector(
	getState,
	(state) => state.modalsState.editTeamModal
);

export const getIsNoHeadsModalOpen = createSelector(
	getState,
	(state) => state.modalsState.headsModal
);

const getRequestState = (property: RequestStateType) => {
	return {
		isIdle: property === RequestStateType.Idle,
		isLoading: property === RequestStateType.Loading,
		isSuccess: property === RequestStateType.Success,
		isError: property === RequestStateType.Error,
	};
};

export const getShownStats = createSelector(getState, (state) => Number(state.activeTab));
export const getIsUserHasTeam = createSelector(getState, (state) => state.isUserHasTeam);
export const getIsSavingMode = createSelector(getState, (state) => state.isSavingMode);
export const getIsTeamChecked = createSelector(getState, (state) => state.isTeamChecked);
export const getAutoFillRequestState = createSelector(getState, (state) =>
	getRequestState(state.fillRequestState)
);

export const getTeamLineupIDs = createSelector(getTeamEmptyLineup, (lineup) =>
	chain(lineup).values().flatMap<number>(identity).filter(identity).value()
);

export const getTeamGameBar = createSelector(getTeamsState, (state) => {
	return {
		round_rank: state.gameBar.round_rank || 0,
		overall_rank: state.gameBar.overall_rank || 0,
		round_points: state.gameBar.round_points || 0,
		overall_points: state.gameBar.overall_points || 0,
	};
});

export const getLeaderboard = createSelector(getState, (state) => state.leaderboardData);

export const getTeamRequestState = createSelector(getState, (state) => {
	return memoize((key: keyof ITeamsReducer["requestState"]) => {
		return {
			isIdle: state.requestState[key] === RequestStateType.Idle,
			isLoading: state.requestState[key] === RequestStateType.Loading,
			isSuccess: state.requestState[key] === RequestStateType.Success,
			isError: state.requestState[key] === RequestStateType.Error,
		};
	});
});

export const getPlayersAccordingLineUp = createSelector(
	getTeamLineupIDs,
	getPlayersById,
	(lineup, getById) => {
		return lineup.map((id) => getById[id]);
	}
);

export const getTeamHeads = createSelector(getState, (state) => {
	return {
		captain: state.captain,
		viceCaptain: state.viceCaptain,
	};
});

export const getIsTeamHeadsFull = createSelector(getState, (state) => {
	return Boolean(state.captain) && Boolean(state.viceCaptain);
});

export const getIsTeamHeadsPartiallyFull = createSelector(getState, (state) => {
	return Boolean(state.captain) || Boolean(state.viceCaptain);
});

export const getIsHeadsWereChanged = createSelector(
	getTeamHeads,
	getTeamLineup,
	getIsTeamHeadsPartiallyFull,
	(heads, lineup, isHeadsPartiallyFull) => {
		if (!isHeadsPartiallyFull) {
			return false;
		}

		return (
			Number(lineup.captain || 0) !== Number(heads.captain || 0) ||
			Number(heads.viceCaptain || 0) !== Number(lineup.vice_captain || 0)
		);
	}
);

export const getIsLastTransferMakeEmpty = createSelector(
	getState,
	(state) => state.isLastTradeMakeEmpty
);

export const getIsTeamComplete = createSelector(getState, (state) => state.isTeamComplete);
export const getDefaultFormation = createSelector(isClassicGame, (isClassic) =>
	isClassic ? DEFAULT_FORMATION_CLASSIC : DEFAULT_FORMATION_GAMEDAY
);

export const getIsGameDayTeamCompleted = createSelector(getGameDaySelectedTeam, (team) =>
	team ? team.isCompleted : false
);

export const isTeamRequestSuccess = createSelector(
	getState,
	({requestState}) => requestState.fetchTeam === RequestStateType.Success
);
