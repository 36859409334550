import {SecondaryLink} from "components/styled";
import React from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {media} from "assets/media";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	padding: 8px 0;

	a {
		margin: 0;
		padding: 11px 30px;
		border-radius: 6px;
		font-weight: normal;
	}

	@media screen and ${media.lessThanMD} {
		a {
			box-sizing: border-box;
			min-width: 60px;
			font-size: 14px;
			padding: 8px 6px;
			text-align: center;
		}
	}
`;

interface ILeagueNavigationButtonsProps {
	leagueId: number;
	isOwner: boolean;
}

export const MyLeagueNavigationButtons: React.FC<ILeagueNavigationButtonsProps> = ({
	leagueId,
	isOwner,
}) => {
	return (
		<Wrapper>
			<Exist when={isOwner}>
				<SecondaryLink to={`${leagueId}/table`}>Table</SecondaryLink>
				<SecondaryLink to={`${leagueId}/manage`}>Manage</SecondaryLink>
				<SecondaryLink to={`${leagueId}/invites`}>Invites</SecondaryLink>
				<SecondaryLink to={`${leagueId}/settings`}>Settings</SecondaryLink>
			</Exist>
			<Exist when={!isOwner}>
				<SecondaryLink to={`${leagueId}/table`}>Table</SecondaryLink>
				<SecondaryLink to={`${leagueId}/invites`}>Invites</SecondaryLink>
				<SecondaryLink to={`${leagueId}/about`}>About</SecondaryLink>
			</Exist>
		</Wrapper>
	);
};
