import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {getProject, getSportSelectorItems} from "modules/selectors";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {IconChevron} from "components/Icons";
import {PureButton} from "components/styled";
import {IProjectEntity} from "modules/types";
import {APP_IS_AD_ENABLED, IS_MSL_GAMEDAY_ENABLED, IS_MC_ENABLED} from "modules/constants";

const Wrapper = styled.div``;

const ChevronWrapper = styled(PureButton)`
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	width: 35px;
	height: 35px;
	background: ${({theme}) => theme.header.sport_selector_color};
`;

const SportItems = styled.div`
	position: absolute;
	z-index: 44;
	left: -30px;
	bottom: 0;
	width: calc(100% + 30px);
	transform: translateY(calc(100% + 6px));
	background: ${({theme}) => theme.primary};

	@media screen and (max-width: 1000px) {
		transform: translateY(calc(100% + 9px));
		left: -20px;
	}
`;

export const SportSelector: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const chevronDirection = isOpen ? "top" : "bottom";
	const currentProject = useSelector(getProject);
	const logosWithoutCurrent = useSelector(getSportSelectorItems);

	function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>) {
		useEffect(() => {
			function handleClickOutside(event: React.SyntheticEvent | Event) {
				if (ref.current && !ref.current.contains(event.target as Node)) {
					setIsOpen(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOutsideAlerter(wrapperRef);

	const toggleMenu = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	useEffect(() => {
		setIsOpen(false);
	}, [currentProject]);

	if (!IS_MSL_GAMEDAY_ENABLED && !IS_MC_ENABLED) {
		return null;
	}

	return (
		<Wrapper ref={wrapperRef}>
			<ChevronWrapper onClick={toggleMenu}>
				<IconChevron color="#303030" to={chevronDirection} />
			</ChevronWrapper>
			<Exist when={isOpen}>
				<SportItems>
					{logosWithoutCurrent.map((project) => (
						<SportItem key={project.link} {...project} />
					))}
				</SportItems>
			</Exist>
		</Wrapper>
	);
};

const SportItemWrapper = styled.div<{background: string}>`
	background: ${(props) => props.background ?? props.theme.other_project};
	background-size: 100% 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	padding: 14px 30px;
	gap: 18px;
	height: 80px;

	> a > img {
		height: 100%;
		width: auto;
		max-width: 135px;
	}

	@media screen and (max-width: 1000px) {
		height: 58px;
		padding: 12px 20px;
		gap: 26px;
	}
`;

const SponsorBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;

	p {
		color: #ffffff;
		font-size: 10px;
		letter-spacing: 0;
		line-height: 12px;
		margin: 0;
	}

	@media screen and (max-width: 1000px) {
		p {
			font-size: 8px;
		}
	}
`;

const SponsorLogoMock = styled.div`
	box-sizing: border-box;
	width: 100px;
	height: 33px;
	background: #ffffff;
	padding: 11px 16px;
	font-size: 10px;
	text-align: center;
	text-decoration: none;
	color: #000000;
	border-radius: 2px;

	@media screen and (max-width: 1000px) {
		width: 70px;
		height: 24px;
		font-size: 8px;
		padding: 8px;
	}
`;

const SportItem: React.FC<IProjectEntity> = ({logo, link, sponsorLogoLink, background}) => {
	return (
		<SportItemWrapper background={background}>
			<Link to={link}>
				<img src={logo} alt="Logo" />
			</Link>
			<Exist when={APP_IS_AD_ENABLED}>
				<SponsorBlock>
					<p>Sponsored by:</p>
					<a href={sponsorLogoLink}>
						<SponsorLogoMock>Sponsor Logo</SponsorLogoMock>
					</a>
				</SponsorBlock>
			</Exist>
		</SportItemWrapper>
	);
};
