import {all, debounce, takeLatest} from "redux-saga/effects";
import * as actions from "modules/actions";
import {errorsManagerSaga} from "modules/sagas/errors";
import {
	contactUsSaga,
	requestPasswordChangeSaga,
	userFetchSaga,
	userLoginSaga,
	userLogoutSaga,
	userRegisterSaga,
	userResetPasswordSaga,
	userUpdatePasswordSaga,
	userUpdateSaga,
	fetchLeaderboardGameDaySaga,
	fetchGameBarGameDaySaga,
} from "modules/sagas";
import {fetchPlayersSaga} from "modules/sagas/player";
import {
	autoFillSaga,
	changeFormationSaga,
	fetchGameBarSaga,
	fetchLeaderboardSaga,
	getTeamGameDaySaga,
	getTeamSaga,
	makeTradeSaga,
	removePlayerFromTeamSaga,
	resetTradeByIndexSaga,
	saveTeamSaga,
	saveTeamGameDaySaga,
	setPlayerToTeamSaga,
	transferPlayerToTeamSaga,
	autoFillGameDaySaga,
	fetchTransferHistorySaga,
} from "modules/sagas/team";
import {
	fetchAllPlayersStatsSaga,
	fetchAllTeamsStatsSaga,
	fetchHelpsSaga,
	fetchMRECSaga,
	fetchNotificationBarSaga,
	fetchPlayerStatsSaga,
	fetchRoundsDelayedSaga,
	fetchRoundsSaga,
	fetchSquadsSaga,
} from "modules/sagas/json";
import {
	createLeagueSaga,
	fetchJoinLeaguesSaga,
	fetchLeagueRanksSaga,
	fetchLeagueSaga,
	fetchLeagueTableSaga,
	fetchLeagueUsersSaga,
	joinLeagueSaga,
	LeagueRemoveUserSaga,
	leaveLeagueSaga,
	sendLeagueInviteSaga,
	updateLeagueSaga,
} from "modules/sagas/leagues";
import {takeEvery} from "typed-redux-saga";
import {
	createLeagueGameDaySaga,
	fetchJoinLeaguesGameDaySaga,
	fetchLeagueGameDaySaga,
	fetchLeagueRanksGameDaySaga,
	fetchLeagueTableGameDaySaga,
	fetchLeagueUsersGameDaySaga,
	joinLeagueGameDaySaga,
	LeagueRemoveUserGameDaySaga,
	leaveLeagueGameDaySaga,
	sendLeagueInviteGameDaySaga,
	updateLeagueGameDaySaga,
} from "modules/sagas/leaguesGameDay";

const user = [
	takeLatest(actions.fetchUser, userFetchSaga),
	takeLatest(actions.userRegister, userRegisterSaga),
	takeLatest(actions.userLogin, userLoginSaga),
	takeLatest(actions.userLogoutAndClearRequest, userLogoutSaga),
	takeLatest(actions.userUpdateRequest, userUpdateSaga),
	takeLatest(actions.userChangePasswordRequest, userUpdatePasswordSaga),
	takeLatest(actions.requestPasswordChange, requestPasswordChangeSaga),
	takeLatest(actions.userResetPasswordRequest, userResetPasswordSaga),
	takeLatest(actions.contactUsRequest, contactUsSaga),
];

const team = [
	// Classic
	takeLatest(actions.fetchTeam, getTeamSaga),
	takeLatest(actions.changeFormation, changeFormationSaga),
	takeLatest(actions.pickPlayerToTeam, setPlayerToTeamSaga),
	takeLatest(actions.removePlayerFromTeam, removePlayerFromTeamSaga),
	takeLatest(actions.saveTeam, saveTeamSaga),
	takeLatest(actions.autoFillRequest, autoFillSaga),
	takeLatest(actions.fetchLeaderboardRequest, fetchLeaderboardSaga),
	// Game Day
	takeLatest(actions.fetchTeamGameDay, getTeamGameDaySaga),
	takeLatest(actions.saveTeamGameDay, saveTeamGameDaySaga),
	takeLatest(actions.autoFillGameDayRequest, autoFillGameDaySaga),
];

const trades = [
	takeLatest(actions.tradeInPlayer, transferPlayerToTeamSaga),
	takeLatest(actions.resetTradeByIndex, resetTradeByIndexSaga),
	takeLatest(actions.makeTradeRequest, makeTradeSaga),
	takeLatest(actions.fetchGameBarRequest, fetchGameBarSaga),
	takeLatest(actions.fetchTradeHistory, fetchTransferHistorySaga),
];

const leagues = [
	takeLatest(actions.fetchLeaguesRequest, fetchLeagueSaga),
	takeLatest(actions.createLeagueRequest, createLeagueSaga),
	takeLatest(actions.updateLeagueRequest, updateLeagueSaga),
	takeLatest(actions.joinLeagueRequest, joinLeagueSaga),
	takeLatest(actions.leaveLeagueRequest, leaveLeagueSaga),
	takeLatest(actions.sendLeagueInviteRequest, sendLeagueInviteSaga),
	takeLatest(actions.fetchLeagueRanksRequest, fetchLeagueRanksSaga),
	takeLatest(actions.fetchLeagueUsersRequest, fetchLeagueUsersSaga),
	takeLatest(actions.leagueRemoveUserRequest, LeagueRemoveUserSaga),
	takeLatest(actions.fetchLeagueTableRequest, fetchLeagueTableSaga),
	debounce(500, actions.fetchJoinLeaguesRequest, fetchJoinLeaguesSaga),
];

const leaguesGameDay = [
	takeLatest(actions.fetchLeaguesGameDayRequest, fetchLeagueGameDaySaga),
	takeLatest(actions.createLeagueGameDayRequest, createLeagueGameDaySaga),
	takeLatest(actions.updateLeagueGameDayRequest, updateLeagueGameDaySaga),
	takeLatest(actions.joinLeagueGameDayRequest, joinLeagueGameDaySaga),
	takeLatest(actions.leaveLeagueGameDayRequest, leaveLeagueGameDaySaga),
	takeLatest(actions.sendLeagueGameDayInviteRequest, sendLeagueInviteGameDaySaga),
	takeLatest(actions.fetchLeagueGameDayRanksRequest, fetchLeagueRanksGameDaySaga),
	takeLatest(actions.fetchLeagueGameDayUsersRequest, fetchLeagueUsersGameDaySaga),
	takeLatest(actions.leagueGameDayRemoveUserRequest, LeagueRemoveUserGameDaySaga),
	takeLatest(actions.fetchLeagueGameDayTableRequest, fetchLeagueTableGameDaySaga),
	debounce(500, actions.fetchJoinLeaguesGameDayRequest, fetchJoinLeaguesGameDaySaga),
];

const JSONS = [
	takeLatest(actions.fetchSquads, fetchSquadsSaga),
	takeLatest(actions.fetchPlayers, fetchPlayersSaga),
	takeLatest(actions.fetchRounds, fetchRoundsSaga),
	takeLatest(actions.fetchRoundsDelayed, fetchRoundsDelayedSaga),
	takeLatest(actions.fetchPlayerStatsRequest, fetchPlayerStatsSaga),
	takeLatest(actions.fetchNotificationBar, fetchNotificationBarSaga),
	takeLatest(actions.fetchMREC, fetchMRECSaga),
	takeEvery(actions.fetchHelpRequest, fetchHelpsSaga),
	takeEvery(actions.fetchAllPlayersStatsRequest, fetchAllPlayersStatsSaga),
	takeEvery(actions.fetchAllTeamsStatsRequest, fetchAllTeamsStatsSaga),
];

const rankingsGameDay = [
	takeLatest(actions.fetchLeaderboardGameDayRequest, fetchLeaderboardGameDaySaga),
	takeLatest(actions.fetchGameBarGameDayRequest, fetchGameBarGameDaySaga),
];

export const rootSaga = function* () {
	yield all([
		takeLatest(actions.globalError, errorsManagerSaga),
		...user,
		...team,
		...trades,
		...leagues,
		...leaguesGameDay,
		...JSONS,
		...rankingsGameDay,
	]);
};

export * from "./user";
export * from "./leaguesGameDay";
export * from "./rankingsGameDay";
