import React from "react";
import {useSelector} from "react-redux";
import classicHeaderLogo from "assets/images/ClassicHeaderLogo.png";
import gamedayHeaderLogo from "assets/images/GamedayProjectLogo.png";
import {getProject, getSport} from "modules/selectors";
import {LOGOS_MAPPER} from "modules/constants";

interface IHeaderLogoImage {
	showOpposite?: boolean;
}

export const currentLogo = (isGameDay: boolean, showOpposite: boolean) => {
	if (showOpposite) {
		return isGameDay ? classicHeaderLogo : gamedayHeaderLogo;
	}
	return isGameDay ? gamedayHeaderLogo : classicHeaderLogo;
};

export const HeaderLogoImage: React.FC<IHeaderLogoImage> = ({showOpposite = false}) => {
	const sport = useSelector(getSport);
	const project = useSelector(getProject);
	const logoItem = LOGOS_MAPPER.find((item) => item.sport === sport && item.project === project);
	return <img src={logoItem?.logo ?? LOGOS_MAPPER[0].logo} alt="logo" />;
};
