import React from "react";
import styled from "styled-components";

const direction = {
	bottom: 90,
	left: 180,
	top: 270,
	right: 0,
};

// 180

interface IProps {
	width?: number;
	height?: number;
	color?: string;
	to?: keyof typeof direction;
}

const SVG = styled.svg<Pick<IProps, "to">>`
	transform: rotate(${({to}) => direction[to!]}deg);
`;

export const IconChevron: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 12 7" fill="none" {...rest}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.65342353,8.53952326 C2.83661153,8.72138854 3.08465385,8.8228604 3.3427838,8.82153428 C3.58750585,8.83352181 3.82609187,8.7426319 4.0008095,8.57085782 L8.51298578,4.309358 C8.69668566,4.12699136 8.8,3.87884756 8.8,3.61999773 C8.8,3.3611479 8.69668566,3.1130041 8.51298578,2.93063747 L4.0008095,-1.33086235 C3.81964435,-1.50866711 3.57427163,-1.60564726 3.32049906,-1.59974557 C3.0667265,-1.59384388 2.82612731,-1.48556207 2.65342353,-1.2995278 C2.30369466,-0.925524643 2.31762593,-0.340411414 2.68475809,0.0165236194 L6.47623955,3.61999773 L2.68475809,7.22347184 C2.31762593,7.58040688 2.30369466,8.16552011 2.65342353,8.53952326 L2.65342353,8.53952326 Z"
			fill={color}
		/>
	</SVG>
);

IconChevron.defaultProps = {
	width: 12,
	height: 12,
	color: "currentColor",
	to: "bottom",
};
