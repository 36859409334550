import {createAction} from "redux-act";
import {
	IFetchRanksRequestPayload,
	IGameBarPayload,
	IGameBarResponse,
	IGenerateRequestStateHandler,
	IRankingsGameDay,
	IRankItem,
	IWithNextPayload,
} from "modules/types";

export const fetchLeaderboardGameDayRequest = createAction<IFetchRanksRequestPayload>();
export const fetchLeaderboardGameDaySuccess = createAction<IWithNextPayload<IRankItem[]>>();
export const fetchLeaderboardGameDayConcat = createAction<IWithNextPayload<IRankItem[]>>();

export const rankingsRequestStateHandler =
	createAction<IGenerateRequestStateHandler<IRankingsGameDay["requestState"]>>();

export const fetchGameBarGameDayRequest = createAction<IGameBarPayload>();
export const fetchGameBarGameDaySuccess = createAction<IGameBarResponse>();
