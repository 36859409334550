import {DateTime} from "luxon";
import {IStatsColumn} from "modules/types/shared";
import {uniqueId} from "lodash";
import {ProjectType, SportType} from "modules/types/enums";
import headerMCClassicLogo from "assets/images/MCClassicLogo.svg";
import headerMCGamedayLogo from "assets/images/MCGamedayLogo.svg";
import classicHeaderLogo from "assets/images/ClassicHeaderLogo.png";
import gamedayHeaderLogo from "assets/images/GamedayProjectLogo.png";
import headerMCClassic from "assets/images/Landing/MC/headerMCClassic.png";
import headerMCWeekender from "assets/images/Landing/MC/headerMCWeekender.png";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IS_UAT = process.env.REACT_APP_ENVIRONMENT === "uat" || false;

export const REACT_APP_LOCAL_IMAGE_URL = process.env.REACT_APP_IMAGES_URL || "";
export const SALARY_CUP = Number(process.env.REACT_APP_SALARY_CUP ?? 0);
export const MC_SALARY_CUP = Number(process.env.REACT_APP_MC_SALARY_CUP ?? 0);
export const SALARY_CUP_DELIMITER = Number(process.env.REACT_APP_SALARY_CUP_DELIMITER);
export const APP_VERSION = parseFloat(process.env.REACT_APP_VERSION || "1.0");
// export const APP_IS_AD_ENABLED = process.env.REACT_APP_IS_AD_VISIBLE === "true";
export const APP_IS_AD_ENABLED = false;

export const LANDING_PATH = ["/", "/landing-mc"];
export const NO_HEADER_PATHS = ["/login", "/register", "/forgot-password", "/reset-password"];

export const MIN_USER_DATE = DateTime.fromISO("1900-01-01");
export const MAX_USER_DATE = DateTime.now().minus({year: 14});
export const USER_DOB_FORMAT = "dd/MM/yyyy";
export const EMAIL_PATTERN = "[A-za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const FORMATION_LIST_GAMEDAY = ["1-2-2-2"] as const;

export const FORMATION_LIST = [
	"1-4-4-2",
	"1-4-3-3",
	"1-4-5-1",
	"1-3-5-2",
	"1-3-4-3",
	"1-5-4-1",
] as const;

export const DEFAULT_BUTTON_SUCCESS_DELAY = 1500;
export const SECRET_LOCALSTORAGE_KEY = "ra_is_secret";

const IS_SECRET_ENABLED_ENV = Boolean(
	JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
);
const IS_SECRET_ENABLED_LOCAL = localStorage.getItem(SECRET_LOCALSTORAGE_KEY) !== "false";

export const IS_SECRET_ENABLED = IS_SECRET_ENABLED_ENV && IS_SECRET_ENABLED_LOCAL;

export const SEASON_TRANSFERS = 50;
export const WEEK_TRANSFERS = 5;

export const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";

export const SPORT_MAP = {
	[SportType.MSL]: "classic",
	[SportType.MC]: "gameday",
};

const headerColumnPrefixID = "header-column";
export const PLAYER_STATS_COLUMNS: IStatsColumn[] = [
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Name",
		value: "name",
		customClassName: "first",
		sortBy: "firstName",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Team",
		value: "team",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "RMm",
		value: "price",
		sortBy: "cost",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GWP",
		value: "roundPoints",
		sortBy: "roundPointsNumber",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "total_points",
		sortBy: "stats.total_points",
		name: "TP",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "starting11",
		sortBy: "stats.starting11",
		name: "SXI",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "substitutions",
		sortBy: "stats.substitutions",
		name: "Sub",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "goals",
		sortBy: "stats.goals",
		name: "GS",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "assists",
		sortBy: "stats.assists",
		name: "A",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "clean_sheets",
		sortBy: "stats.clean_sheets",
		name: "CS",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "own_goals",
		sortBy: "stats.own_goals",
		name: "OG",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "yellow_card",
		sortBy: "stats.yellow_card",
		name: "YC",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "red_card",
		sortBy: "stats.red_card",
		name: "RC",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "pointsPerMillion",
		name: "P/RM",
		sortBy: "pointsPerMillion",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "picked_by",
		sortBy: "stats.picked_by",
		name: "%",
	},
];

export const TEAM_STATS_COLUMNS: IStatsColumn[] = [
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Team",
		value: "team",
		customClassName: "first",
		sortBy: "stats.team",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "B",
		customClassName: "badge",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "P",
		value: "played",
		sortBy: "stats.played",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GS",
		value: "goals_scored",
		sortBy: "stats.goals_scored",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "A",
		value: "assists",
		sortBy: "stats.assists",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GC",
		value: "goals_conceded",
		sortBy: "stats.goals_conceded",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "OG",
		value: "own_goals",
		sortBy: "stats.own_goals",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "YC",
		value: "yellow_cards",
		sortBy: "stats.yellow_cards",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "RC",
		value: "red_cards",
		sortBy: "stats.red_cards",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Top Performer",
		customClassName: "performer",
		sortBy: "stats.top_performer.points_sum",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GWP",
		value: "gameweek_points",
		sortBy: "stats.gameweek_points",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "TP",
		value: "total_points",
		sortBy: "stats.total_points",
	},
];

export const IS_MSL_GAMEDAY_ENABLED = JSON.parse(
	process.env.REACT_APP_IS_GAMEDAY_ENABLED || "false"
) as boolean;

export const IS_MC_ENABLED = JSON.parse(process.env.REACT_APP_IS_MC_ENABLED || "false") as boolean;

export const playersJSONSportMapper = {
	MSL: "/players.json",
	MC: "/players_mc.json",
};

// {
// 	logo: currentLogo(isGameDay, showOpposite),
// 		link: isGameDay ? "" : "",
// 	sponsorLogo: "",
// 	sponsorLogoLink: "/",
// 	background: isGameDay ? "#000151" : "#F02F42",
// },
// {
// {

export const LOGOS_MAPPER = [
	{
		sport: SportType.MC,
		project: ProjectType.Classic,
		logo: headerMCClassicLogo,
		color: "",
		sponsorLogoLink: "/",
		link: "/classic/MC/team",
		background: `url("${headerMCClassic}")`,
		enabled: IS_MC_ENABLED,
	},
	{
		sport: SportType.MC,
		project: ProjectType.Gameday,
		logo: headerMCGamedayLogo,
		color: "",
		sponsorLogoLink: "/",
		link: "/gameday/MC/team",
		background: `url("${headerMCWeekender}")`,
		enabled: IS_MC_ENABLED,
	},
	{
		sport: SportType.MSL,
		project: ProjectType.Classic,
		logo: classicHeaderLogo,
		background: "#000151",
		link: "/classic/MSL/team",
		sponsorLogoLink: "/",
		enabled: true,
	},
	{
		sport: SportType.MSL,
		project: ProjectType.Gameday,
		logo: gamedayHeaderLogo,
		link: "/gameday/MSL/team",
		sponsorLogoLink: "/",
		background: "#F02F42",
		enabled: IS_MSL_GAMEDAY_ENABLED,
	},
];
