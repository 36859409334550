import styled from "styled-components";
import {media} from "assets/media";
import {PaddingContainer} from "components/styled";

export const PlayingField = styled.div`
	background: url(${({theme}) => theme.field.background.desktop}) no-repeat center top;
	background-size: 100% 100%;
	border-radius: 4px;
	height: 630px;
	width: 100%;
	padding-top: 73px;
	position: relative;
	box-sizing: border-box;

	@media (max-width: 1500px) {
		height: 650px;
	}

	@media (max-width: 1000px) {
		height: 750px;
	}

	@media (max-width: 700px) {
		background-image: url(${({theme}) => theme.field.background.mobile});
		padding-top: 75px;
		background-size: cover;
	}

	@media (max-width: 480px) {
		height: 570px;
		border-radius: 10px;
		padding-top: 40px;

		.field-row {
			gap: 32px;
		}

		&.wide .field-row {
			gap: 16px;
		}
	}
`;

export const FieldRow = styled.div`
	display: flex;
	justify-content: space-around;
	height: 21.5%;

	@media screen and ${media.lessThanMD} {
		justify-content: center;
		gap: 32px;
		&.game-day {
			justify-content: space-around;
		}
	}

	@media screen and ${media.lessThanSM} {
		gap: 16px;
	}
`;

export const StatusBarWrapper = styled.div`
	box-sizing: border-box;
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 64px;
	padding: 10px 0;
	z-index: 8;
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
`;

export const StatusBarInnerContainer = styled(PaddingContainer)`
	height: 100%;
`;

export const StatusBarSmallContainer = styled.div`
	box-sizing: border-box;
	max-width: 785px;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
`;

export const StatusBarSelectedBlock = styled.div`
	text-align: center;

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin: 0;
	}

	b {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 19px;
	}
`;

export const StatusBarButtonsBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	button {
		min-width: 105px;
	}
`;
