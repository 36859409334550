import {PrimaryLink} from "components/styled";
import React from "react";
import styled from "styled-components";
import {media} from "assets/media";
import {useMediaQuery} from "@mui/material";

const Wrapper = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 20px 24px;
	border: 1px dashed #dddddd;
	border-radius: 6px;

	h2 {
		color: ${({theme}) => theme.text_color};
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 29px;
		margin: 0 0 8px 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 20px;
	}

	@media screen and ${media.lessThanMD} {
		padding: 16px;

		h2 {
			padding: 0 10px;
			font-size: 20px;
		}

		p {
			margin-top: 0;
			padding: 0 10px;
		}
	}
`;

const ActionsButton = styled.div`
	display: flex;
	justify-content: flex-start;
	gap: 19px;

	a {
		box-sizing: border-box;
		margin: 0;
		max-width: 210px;
		width: 100%;
		text-align: center;
	}

	@media screen and ${media.lessThanMD} {
		gap: 14px;
	}
`;

export const CreateJoinBlock: React.FC = () => {
	const isMobile = useMediaQuery(media.lessThanMD);

	return (
		<Wrapper className="create-join-block">
			<h2>Join / Create</h2>
			<p>
				You can create your own private leagues or find public leagues to join and compete
				in
			</p>
			<ActionsButton>
				<PrimaryLink to={`join`}>{isMobile ? "Join" : "Join a league"}</PrimaryLink>
				<PrimaryLink to={`create`}>{isMobile ? "Create" : "Create a league"}</PrimaryLink>
			</ActionsButton>
		</Wrapper>
	);
};
