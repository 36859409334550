import React, {useCallback, useState} from "react";
import {IconRemove, IconFilter, Exist} from "components";
import {Input, PrimaryButton} from "components/styled";
import {useSelector} from "react-redux";
import {getPositions, isClassicGame} from "modules/selectors";
import {IPoolFilters} from "modules/types";
import styled from "styled-components";
import {MenuItem} from "@mui/material";
import {media} from "assets/media";

const PlayersPoolFilters = styled.div`
	padding: 14px 21px 6px 21px;
	background: #f8f8f8;
	border-bottom: 1px solid #dddddd;
`;

const FilterRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 21px;

	> div,
	button {
		width: 100%;
		margin-bottom: 20px;
	}

	> button {
		max-width: 85px;
	}

	@media screen and (max-width: ${media.md}) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0;

		> div,
		button {
			margin-bottom: 12px;
		}

		&.main {
			flex-direction: row;
			gap: 12px;
		}
	}
`;

const IconRemoveWrapper = styled.div`
	transform: translateY(1px);
`;

interface IProps {
	updateFilter: (data: IPoolFilters) => void;
	filters: IPoolFilters;
}

interface IPriceRange {
	title: string;
	value: string;
	ariaSelected: boolean;
}

const priceRangeFilterMap: IPriceRange[] = [
	{title: "All", value: "0-99", ariaSelected: true},
	{title: "Only affordable", value: "affordable", ariaSelected: true},
	{title: "Over RM6m", value: "6-99", ariaSelected: false},
	{title: "RM5-6m", value: "5-6", ariaSelected: false},
	{title: "RM4-5m", value: "4-5", ariaSelected: false},
	{title: "RM3-4m", value: "3-4", ariaSelected: false},
	{title: "Under RM3m", value: "0-3", ariaSelected: false},
];
export const PoolFilters: React.FC<IProps> = ({updateFilter, filters}) => {
	const positions = useSelector(getPositions);
	const [showFilters, setFiltersVisibility] = useState(false);
	const isClassic = useSelector(isClassicGame);
	const handleFiltersVisibility = useCallback(() => {
		setFiltersVisibility(!showFilters);
	}, [showFilters]);

	const onChangeAdapter = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		const value = e.target.value;

		if (!name) {
			return;
		}

		if (updateFilter) {
			updateFilter({
				...filters,
				[name]: value,
			});
		}
	};

	return (
		<PlayersPoolFilters>
			<FilterRow className="main">
				<Input
					value={filters.search}
					className={"search"}
					label="Search"
					name={"search"}
					onChange={onChangeAdapter}
				/>
				<PrimaryButton onClick={handleFiltersVisibility} className="blue">
					<span>Filter </span>
					<Exist when={showFilters}>
						<IconRemoveWrapper>
							<IconRemove color="#000151" width={16} height={16} />
						</IconRemoveWrapper>
					</Exist>
					<Exist when={!showFilters}>
						<IconFilter />
					</Exist>
				</PrimaryButton>
			</FilterRow>
			<Exist when={showFilters}>
				<FilterRow>
					<Input
						select
						onChange={onChangeAdapter}
						name={"position"}
						label="Position"
						value={filters.position}>
						<MenuItem value="0" aria-selected={false}>
							All Positions
						</MenuItem>
						{positions.map(({id, name}) => (
							<MenuItem key={id} value={id} aria-selected={false}>
								{name}
							</MenuItem>
						))}
					</Input>
					{/*<Input*/}
					{/*	select*/}
					{/*	onChange={onChangeAdapter}*/}
					{/*	label="Country"*/}
					{/*	name={"country"}*/}
					{/*	value={filters.country}>*/}
					{/*	<MenuItem value="0" aria-selected={true}>*/}
					{/*		By Country*/}
					{/*	</MenuItem>*/}
					{/*	/!*{squads.map(({id, name}) => (*!/*/}
					{/*	/!*	<MenuItem key={id} value={id} aria-selected={false}>{name}</MenuItem>*!/*/}
					{/*	/!*))}*!/*/}
					{/*</Input>*/}
				</FilterRow>
				<Exist when={isClassic}>
					<FilterRow>
						<Input
							select
							onChange={onChangeAdapter}
							label="Price"
							name={"price"}
							value={filters.price}>
							{priceRangeFilterMap.map((item) => (
								<MenuItem
									key={item.value}
									value={item.value}
									aria-selected={item.ariaSelected}>
									{item.title}
								</MenuItem>
							))}
						</Input>
					</FilterRow>
				</Exist>
			</Exist>
		</PlayersPoolFilters>
	);
};
