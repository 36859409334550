import {LOGOS_MAPPER, SPORT_MAP} from "modules/constants";
import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {memoize} from "lodash";
import {ProjectType} from "modules/types/enums";

const getSharedData = ({shared}: IStore) => shared;

export const getSport = createSelector(getSharedData, (state) => state.sport);
export const getLowerCaseSport = createSelector(getSport, (sport) => sport.toLowerCase());
export const getProject = createSelector(getSharedData, (state) => state.project);
export const getTheme = createSelector(getSharedData, (state) => state.appTheme);
export const getRouteSport = createSelector(getSharedData, (state) => SPORT_MAP[state.sport]);
export const getLanguage = createSelector(getSharedData, (state) => state.language);
export const getNotificationState = createSelector(getSharedData, (state) => state.notification);
export const getTeamStats = createSelector(getSharedData, (state) => state.teamsStats);

export const getTeamStatById = createSelector(getSharedData, (state) =>
	memoize((id: number = 0) => state.teamsStats[id])
);

export const isClassicGame = createSelector(
	getProject,
	(currentProject: ProjectType) => currentProject === ProjectType.Classic
);
export const isGameDayGame = createSelector(
	getProject,
	(currentProject: ProjectType) => currentProject === ProjectType.Gameday
);

export const getProjectWithSportRoute = createSelector(getProject, getSport, (project, sport) => {
	return `${project}/${sport}`;
});

export const getGameName = createSelector(isClassicGame, isGameDayGame, (isClassic, isGameDay) => {
	if (isClassic) {
		return "fantasy";
	}
	return isGameDay ? "gameday" : "";
});

export const getSportSelectorItems = createSelector(getSport, getProject, (sport, project) => {
	return LOGOS_MAPPER.filter(
		(item) => item.enabled && !(item.sport === sport && item.project === project)
	);
});
