import {IStore} from "modules/types";
import {getActiveMatches, getMatchesArray, getPlayerInfoModalState} from "modules/selectors";
import {createSelector} from "reselect";
import {filter, find, get, keyBy, memoize} from "lodash";
import {PlayerStatus} from "modules/types/enums";

const getState = ({player}: IStore) => player;

export const getPlayers = createSelector(getState, (state) => state.players);
export const getActivePlayers = createSelector(getState, (state) =>
	state.players.filter((player) => player.status !== PlayerStatus.Eliminated)
);

export type IExtendedPlayer = ReturnType<typeof getPlayers>[number];
export const getPlayersById = createSelector(getPlayers, (players) =>
	keyBy<IExtendedPlayer>(players, "id")
);

export const getPlayerById = createSelector(getPlayersById, (playersById) =>
	memoize((playerId: number = 0) => playersById[playerId])
);

export const getIsPlayerLiveStatusBySquadId = createSelector(getActiveMatches, (matches) =>
	memoize((squadId: number = 0) =>
		matches.some(({home_squad_id, away_squad_id}) =>
			[home_squad_id, away_squad_id].includes(squadId)
		)
	)
);

export const getPlayerStats = createSelector(getState, (state) => state.playerStats);

export const getPlayerMatchFixture = createSelector(
	getPlayerInfoModalState,
	getPlayersById,
	getMatchesArray,
	(state, players, matches) => {
		const player = find(players, (player) => player.id === state.playerId);

		const squad_id = player?.squadId;

		if (!squad_id) {
			return [];
		}

		return filter(
			matches,
			(match) =>
				get(match, "away_squad_id", 0) === squad_id ||
				get(match, "home_squad_id", 0) === squad_id
		);
	}
);

export const getStatsCentrePlayerStats = createSelector(
	getState,
	(state) => state.statsCentrePlayerStats
);
