import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg`
	polygon {
		transform: translate(-3px, -3px);
	}
`;

export const IconRemove: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 14 14" fill="none" {...rest}>
		<polygon
			id="Path"
			fill={color}
			points="14.5134046 16 10 11.4865954 5.48659541 16 4 14.5134046 8.51340459 10 4 5.48659541 5.48659541 4 10 8.51340459 14.5134046 4 16 5.48659541 11.4865954 10 16 14.5134046"
		/>
	</SVG>
);

IconRemove.defaultProps = {
	width: 14,
	height: 14,
	color: "currentColor",
};
