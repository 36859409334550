import React, {useCallback} from "react";
import {
	IconInfo,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
	ModalHeaderTitle,
	ModalText,
} from "components";
import {IconRemove} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {getInfoModalState} from "modules/selectors";
import {closeInfoModal} from "modules/actions/modals";
import {PrimaryButton} from "components/styled";
import {purify} from "modules/utils";

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		margin: 18px 0 24px 0;
	}

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin: 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin-bottom: 30px;
	}
`;

const SPrimaryButton = styled(PrimaryButton)`
	max-width: 260px;
	width: 100%;
`;

export const GlobalInfoModal: React.FC = () => {
	const dispatch = useDispatch();
	const infoData = useSelector(getInfoModalState);

	const closeModal = useCallback(() => {
		dispatch(closeInfoModal());
	}, [dispatch]);

	if (Object.values(infoData).length === 0) {
		return null;
	}

	const textSanitized = purify(infoData.text);

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerContainer>
					<IconInfo color="#000151" width={66} height={66} />
					<ModalHeaderTitle>{infoData.title}</ModalHeaderTitle>
					<ModalText dangerouslySetInnerHTML={{__html: textSanitized}} />
					<SPrimaryButton onClick={closeModal}>Close</SPrimaryButton>
				</InnerContainer>
			</ModalContent>
		</ModalBackdrop>
	);
};
