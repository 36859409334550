import React from "react";
import styled from "styled-components";
import {IconInjury, IconPlus, IconRemove, IconReset} from "components";
import IconInfo from "assets/images/icons/info.svg";
import IconCaptain from "assets/images/icons/IconCaptain.svg";
import IconVice from "assets/images/icons/IconViceCaptain.svg";
import {Exist} from "components/Exist";
import {useSelector} from "react-redux";
import {isClassicGame} from "modules/selectors";

const Wrapper = styled.div`
	background: ${({theme}) => theme.field.legend.background};
	color: ${({theme}) => theme.field.legend.color};
	width: 100%;
	max-width: 660px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 10px 5px;

	@media (max-width: 700px) {
		justify-content: flex-start;
		max-width: 295px;
		flex-wrap: wrap;
		padding: 5px 10px;
	}
`;

const Status = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 700px) {
		width: 33%;
		padding: 5px 0;
	}

	span {
		position: relative;
		font-size: 10px;
		display: inline-block;
		margin-left: 6px;
	}
`;

const IconAddWrapper = styled.div`
	background: #dddddd;
	color: #222;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
	}
`;

const IconResetWrapper = styled(IconAddWrapper)`
	svg {
		width: 9px;
		height: 9px;
	}
`;

const IconNotSelected = styled(IconInjury)`
	overflow: hidden;
	border-radius: 50%;
`;

export const Legend: React.FC = (props) => {
	const isClassic = useSelector(isClassicGame);
	return (
		<Wrapper {...props}>
			<Status>
				<IconAddWrapper>
					<img src={IconInfo} alt="i" />
				</IconAddWrapper>
				<span>Info</span>
			</Status>
			<Status>
				<IconAddWrapper>
					<IconRemove width={10} height={10} />
				</IconAddWrapper>
				<span>Remove</span>
			</Status>
			<Status>
				<IconAddWrapper>
					<IconPlus />
				</IconAddWrapper>
				<span>Add</span>
			</Status>
			<Status>
				<IconNotSelected />
				<span>Unavailable or Eliminated</span>
			</Status>
			<Exist when={isClassic}>
				<Status>
					<IconResetWrapper>
						<IconAddWrapper>
							<IconReset color="#000" />
						</IconAddWrapper>
					</IconResetWrapper>
					<span>Reset transfer</span>
				</Status>
				<Status />
				<Status>
					<IconResetWrapper>
						<IconAddWrapper>
							<img src={IconCaptain} alt="captain" />
						</IconAddWrapper>
					</IconResetWrapper>
					<span>Captain</span>
				</Status>
				<Status>
					<IconResetWrapper>
						<IconAddWrapper>
							<img src={IconVice} alt="vice-captain" />
						</IconAddWrapper>
					</IconResetWrapper>
					<span>Vice-Captain</span>
				</Status>
			</Exist>
		</Wrapper>
	);
};
