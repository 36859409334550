import {createAction} from "redux-act";
import {
	IFetchLeaguesGameDayGetParams,
	IFetchRanksRequestPayload,
	IGenerateRequestStateHandler,
	ILeagueGameDay,
	IRankItem,
	ILeagueGameDayRemoveUserPayload,
	IPaginationRequest,
	ISportWithIdPayload,
	IWithIdPayload,
	IWithNextPagePayload,
	IWithNextPayload,
	ILeagueGameDayInvitePayload,
	ICreateLeagueGameDayForm,
	ICreateLeagueGameDayRequestPayload,
	ILeagueGameDayUser,
	ILeagueGameDayRankItem,
	ILeagueGameDayReducer,
} from "modules/types";
import {IApiFetchJoinLeagueClearResponse} from "modules/types/api";

export const fetchLeaguesGameDayRequest = createAction<IFetchLeaguesGameDayGetParams>();
export const fetchLeaguesGameDaySuccess = createAction<ILeagueGameDay[]>();

export const leaguesGameDayClear = createAction();
export const setLeagueGameDayJoined = createAction<number>();

export const createLeagueGameDayRequest = createAction<ICreateLeagueGameDayForm>();
export const createLeagueGameDaySuccess = createAction<ILeagueGameDay[]>();
export const createLeagueGameDayClear = createAction();
export const createLeagueGameDayClearStep = createAction();

export const updateLeagueGameDayRequest =
	createAction<ISportWithIdPayload<ICreateLeagueGameDayRequestPayload>>();
export const updateLeagueGameDaySuccess = createAction<ILeagueGameDay>();
export const updateLeagueGameDayClear = createAction();

export const fetchJoinLeaguesGameDayRequest = createAction<IPaginationRequest>();
export const fetchJoinLeaguesGameDaySuccess = createAction<IApiFetchJoinLeagueClearResponse>();
export const fetchJoinLeaguesGameDayConcat = createAction<IApiFetchJoinLeagueClearResponse>();
export const fetchJoinLeaguesGameDayClear = createAction();

export const joinLeagueGameDayRequest = createAction<string>();
export const JoinLeagueGameDaySuccess = createAction<ILeagueGameDay>();
export const joinLeagueGameDayClear = createAction();

export const leaveLeagueGameDayRequest = createAction<number>();
export const leaveLeagueGameDaySuccess = createAction();
export const leaveLeagueGameDayClear = createAction();

export const sendLeagueGameDayInviteRequest =
	createAction<IWithIdPayload<ILeagueGameDayInvitePayload[]>>();
export const sendLeagueGameDayInviteSuccess = createAction();
export const sendLeagueGameDayInviteClear = createAction();

export const fetchLeagueGameDayUsersRequest = createAction<IWithIdPayload<IPaginationRequest>>();
export const fetchLeagueGameDayUsersSuccess =
	createAction<IWithNextPagePayload<ILeagueGameDayUser[]>>();
export const leagueGameDayUsersClear = createAction();

export const fetchLeagueGameDayRanksRequest =
	createAction<IWithIdPayload<IFetchRanksRequestPayload>>();
export const fetchLeagueGameDayRanksSuccess = createAction<IRankItem[]>();

export const leagueGameDayRemoveUserRequest = createAction<ILeagueGameDayRemoveUserPayload>();
export const leagueGameDayRemoveUserSuccess = createAction<number>();
export const leagueGameDayRemoveUserClear = createAction();

export const fetchLeagueGameDayTableRequest =
	createAction<IWithIdPayload<IFetchRanksRequestPayload>>();
export const fetchLeagueGameDayTableSuccess =
	createAction<IWithNextPayload<ILeagueGameDayRankItem[]>>();
export const fetchLeagueGameDayTableConcat =
	createAction<IWithNextPayload<ILeagueGameDayRankItem[]>>();

export const leagueGameDayRequestGlobalStateHandler =
	createAction<IGenerateRequestStateHandler<ILeagueGameDayReducer["leagueRequests"]>>();
