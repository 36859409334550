import React, {useCallback, useEffect, useState} from "react";
import {SecondaryButton, WarningButton} from "components/styled";
import {Exist} from "components/Exist";
import {CircularPreloaded} from "components/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {getLeagueGameDayById, getLeagueGameDayRequestStateByKey} from "modules/selectors";
import {joinLeagueGameDayRequest, setLeagueGameDayJoined} from "modules/actions";
import {ILeague} from "modules/types";
import styled from "styled-components";
import {media} from "assets/media";
import {useNavigateWithSport} from "modules/utils/hooks";
import {openLeagueLeaveModal} from "modules/actions/modals";

const LeaveLeagueWrapper = styled.div`
	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 19px;
		font-weight: 400;
		margin: 0 0 12px 0;
	}

	p {
		color: #808080;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 18px;
		max-width: 320px;
		font-weight: 300;
		margin: 0 0 20px 0;
	}

	button {
		max-width: 220px;
		width: 100%;
	}

	@media screen and ${media.lessThanMD} {
		h3 {
			font-size: 16px;
			letter-spacing: 0;
			line-height: 19px;
		}

		p {
			font-size: 14px;
		}

		button {
			max-width: unset;
			width: 100%;
		}
	}
`;

interface IProps {
	leagueId: ILeague["id"];
	showJoin?: boolean;
}

export const LeaveLeagueGameDayBlock: React.FC<IProps> = ({leagueId, showJoin}) => {
	const dispatch = useDispatch();
	const sportNavigate = useNavigateWithSport();

	const {isLoading, isSuccess} = useSelector(getLeagueGameDayRequestStateByKey)("leave");

	const leaveLeague = useCallback(() => {
		dispatch(openLeagueLeaveModal());
	}, [dispatch]);

	useEffect(() => {
		if (isSuccess) {
			sportNavigate("/leagues");
		}
	}, [isSuccess, sportNavigate]);

	if (showJoin) {
		return (
			<LeaveLeagueWrapper>
				<JoinBlock leagueId={leagueId} />
			</LeaveLeagueWrapper>
		);
	}

	return (
		<LeaveLeagueWrapper>
			<h3>Leave league</h3>
			<p>If you leave a league, you can always rejoin it should you choose</p>
			<WarningButton
				className="leave-league"
				type="button"
				onClick={leaveLeague}
				disabled={isLoading}>
				<Exist when={!isLoading}>Leave league</Exist>
				<Exist when={isLoading}>
					<CircularPreloaded size={16} color="#FFFFFF" />
				</Exist>
			</WarningButton>
		</LeaveLeagueWrapper>
	);
};

interface IJoinProps {
	leagueId: ILeague["id"];
}

const JoinBlock: React.FC<IJoinProps> = ({leagueId}) => {
	const dispatch = useDispatch();
	const league = useSelector(getLeagueGameDayById)(leagueId);
	const [isJoin, setIsJoin] = useState(false);
	const {isSuccess} = useSelector(getLeagueGameDayRequestStateByKey)("join");

	useEffect(() => {
		if (isSuccess && isJoin) {
			dispatch(setLeagueGameDayJoined(league!.id));
			setIsJoin(false);
		}
	}, [dispatch, league, isSuccess, isJoin]);

	const onLeagueJoin = useCallback(() => {
		setIsJoin(true);
		dispatch(joinLeagueGameDayRequest(league!.code));
	}, [dispatch, league]);

	const joinClass = isJoin ? "active" : "";

	return (
		<SecondaryButton type="button" className={joinClass} onClick={onLeagueJoin}>
			<Exist when={isJoin}>
				<CircularPreloaded size={16} color="#FFFFFF" />
			</Exist>
			<Exist when={!isJoin}>Join again</Exist>
		</SecondaryButton>
	);
};
