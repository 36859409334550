import React from "react";
import {ILeague, ILeagueGameDay} from "modules/types";
import {getRoundsById, getUser} from "modules/selectors";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {MyLeagueNavigationButtons} from "./MyLeagueNavigationButtons";
import {MyLeagueTeamList} from "./MyLeagueTeamsList";
import {media} from "assets/media";
import {formatGameWeek} from "modules/utils";

const Wrapper = styled.div`
	box-sizing: border-box;
	border: 1px solid #dddddd;
	border-radius: 4px;
	padding: 20px 32px;
	width: 100%;

	@media screen and ${media.lessThanMD} {
		padding: 12px 14px;
	}
`;

const LeagueName = styled.h3`
	color: ${({theme}) => theme.text_color};
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 29px;
	margin: 0;
`;

const Heading = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and ${media.lessThanMD} {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
`;

const LeagueInfo = styled.p`
	color: #a3a3a3;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 26px;
	text-transform: capitalize;
	font-weight: 300;
	margin: 0;
`;

interface IProps {
	league: ILeague | ILeagueGameDay;
}

export const MyLeagueItem: React.FC<IProps> = ({league}) => {
	const user = useSelector(getUser);
	const roundsById = useSelector(getRoundsById);

	const isOwner = user?.id === league?.user_id;

	if (!league) {
		return null;
	}
	const startRound = roundsById[league.start_round]?.number;

	return (
		<Wrapper className="league-item">
			<Heading>
				<LeagueName>{league.name}</LeagueName>
			</Heading>
			<MyLeagueTeamList />
			<MyLeagueNavigationButtons leagueId={league.id} isOwner={isOwner} />
			<LeagueInfo>
				{league.privacy} league, starting game week {formatGameWeek(startRound)}
			</LeagueInfo>
		</Wrapper>
	);
};
