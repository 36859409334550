import {get} from "lodash";
import {FB_SHARE_URL} from "modules/constants";
import {IShare} from "modules/types";
import {ShareTarget, ShareType} from "modules/types/share";
import {SportType} from "modules/types/enums";

const getEncodedJson = <T>(object: T) => btoa(JSON.stringify(object));

const getTextsMap = (code: string) => ({
	MSL: {
		[ShareType.General]:
			"Build the team you think will be the best during the Liga Super season. It’s free and easy to join!",
		[ShareType.League]: `You think you can beat me in fantasy this Liga Super season? I’ll prove you wrong! Come join my league now. The league pin is ${code}`,
	},
	MC: {
		[ShareType.General]:
			"Build the team you think will be the best during the Malaysia Cup tournament and compete with friends. It’s free and easy to join!",
		[ShareType.League]: `You think you can beat me in fantasy this Malaysia Cup? I’ll prove you wrong! Come join my league now. The league pin is ${code}`,
	},
});

export const getShareText = (type: ShareType, code = "", sport: SportType | undefined) => {
	console.log(sport);
	const messages = getTextsMap(code);
	console.log(messages);
	console.log(`${String(sport).toUpperCase()}.${type}`);
	const text = get(messages, `${String(sport).toUpperCase()}.${type}`, "") as string;

	if (!messages) {
		console.error("Invalid share data!");
	}
	return text;
};

export const getLinkForShare = <T>(object: T, type: ShareType, game?: string) => {
	const encodedJson = getEncodedJson(object);

	switch (type) {
		case ShareType.League:
			return `${FB_SHARE_URL}${game || "fantasy"}_league/${encodedJson}`;
		case ShareType.General:
			return `${FB_SHARE_URL}general/${encodedJson}`;
		default:
			return `${FB_SHARE_URL}general/${encodedJson}`;
	}
};

const prepareShareObject = (data: IShare) => {
	let object = {
		t: getTimestamp(),
		sport: data.sport!.toLowerCase(),
	};

	if (data.type === ShareType.League) {
		object = Object.assign(object, {
			leagueId: data.leagueId,
		});
	}
	return object;
};

const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const shareFacebook = (data: IShare) => {
	const FB_API = get(window, "FB");

	if (!FB_API) {
		throw Error("No FB api found");
	}

	const object = prepareShareObject(data);

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object, data.type, data?.game),
		},
		(response: unknown) => {
			console.log(response);
		}
	);
};

const shareTwitter = (data: IShare) => {
	const object = prepareShareObject(data);
	const href = getLinkForShare(object, data.type, data.game);
	const text = getShareText(data.type, data.code, data.sport);
	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(text),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

const shareMap = {
	[ShareTarget.Twitter]: shareTwitter,
	[ShareTarget.Facebook]: shareFacebook,
};

export const share = (data: IShare) => {
	const shareFunction = shareMap[data.target];

	if (shareFunction && typeof shareFunction === "function") {
		shareFunction(data);
	}
};
