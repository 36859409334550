import React, {useCallback} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {
	getIsTeamLockout,
	getPlayerById,
	getSelectedRoundId,
	getTeamHeads,
	isPlayerPlaying,
} from "modules/selectors";
import {PlayerStatus, StatsTab} from "modules/types/enums";
import {Exist} from "components/Exist";
import {SALARY_CUP_DELIMITER} from "modules/constants";
import {media} from "assets/media";

const PlayerDataPart = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	font-weight: bold;

	&.white {
		background: #fff;
	}

	&.grey {
		background: #ddd;
	}

	&.thin {
		font-weight: normal;
	}

	&.active {
		background: #0bad0b;
		color: #ffffff;
	}

	&.injured {
		background: #a82727;
		color: #ffffff;
	}

	@media screen and ${media.lessThanMD} {
		font-size: 8px;
	}
`;

interface IProps {
	shownStats?: StatsTab;
	playerId?: number;
}

const getStatOrDash = (stats?: number): string => {
	return String(stats ?? "-");
};

const getRoundScore = (
	selectedRound: number | undefined,
	score: number | undefined,
	isCaption: boolean
): number | undefined => {
	return selectedRound && score && (Number(isCaption) + 1) * score;
};

const getTotalScore = (score: number | undefined, isCaption: boolean): number | undefined => {
	return score && (Number(isCaption) + 1) * score;
};

export const Stats: React.FC<IProps> = ({shownStats, playerId}) => {
	const player = useSelector(getPlayerById)(playerId);
	const heads = useSelector(getTeamHeads);
	const isCaptain = playerId === heads.captain;
	const isPlaying = useSelector(isPlayerPlaying)(playerId);
	const isLockout = useSelector(getIsTeamLockout);
	const selectedRound = useSelector(getSelectedRoundId) as number;
	const playerCost = player?.cost / SALARY_CUP_DELIMITER;

	const injuredClass = player?.status === PlayerStatus.Injured ? "injured" : "";
	const roundScore = getRoundScore(
		selectedRound,
		player?.stats.round_scores?.[selectedRound],
		isCaptain
	);
	const totalScore = getTotalScore(player?.stats.total_points, isCaptain);

	const isTabs = useCallback(
		(tabs: StatsTab[]) => {
			if (shownStats === undefined) {
				return false;
			}
			return tabs.includes(shownStats);
		},
		[shownStats]
	);

	if (!player || !isLockout) {
		return null;
	}

	if (isTabs([StatsTab.TransferValue])) {
		return (
			<PlayerDataPart className="grey thin">RM{getStatOrDash(playerCost)}m</PlayerDataPart>
		);
	}

	if (isPlaying) {
		return (
			<PlayerDataPart className={`white thin active ${injuredClass}`}>
				{getStatOrDash(player.stats.total_points)}
			</PlayerDataPart>
		);
	}

	if (!selectedRound) {
		return null;
	}

	return (
		<React.Fragment>
			<Exist when={isTabs([StatsTab.Weekly, StatsTab.Overall])}>
				<Exist when={Boolean(selectedRound)}>
					<PlayerDataPart className="white thin">
						{getStatOrDash(roundScore)}
					</PlayerDataPart>
					<PlayerDataPart className="grey">{getStatOrDash(totalScore)}</PlayerDataPart>
				</Exist>
			</Exist>
		</React.Fragment>
	);
};
