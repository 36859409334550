import {filter, gt, identity, indexOf, isEmpty, lte, merge, range, reduce, set, size} from "lodash";
import {
	IAutoPickGameDayPayload,
	IError,
	IFetchRanksRequestPayload,
	IGameBarPayload,
	INewTeamRequest,
	ISagaAction,
	ITeamRequestGameDay,
} from "modules/types";
import {
	getAvailableFormation,
	getGameDaySelectedTeam,
	getIsUserLogged,
	getLowerCaseSport,
	getPlayersById,
	getResetTransferDataSelector,
	getSelectedRoundId,
	getSport,
	getTeam,
	getTeamHeads,
	getTeamLineup,
	getTeamsGameDay,
	getTradePairsAccordingPosition,
	getTrades,
	hasEmptySpot,
	isClassicGame,
	isGameDayGame,
	isTeamRequestSuccess,
} from "modules/selectors";
import {ILineup, INewTeam, ITeam} from "modules/types/team";
import {Api, ApiError} from "modules/utils/Api";

import {
	DEFAULT_FORMATION_CLASSIC,
	FORMATIONS,
	getGameDayTeam,
	hasCurrentRoundTeam,
	IFormation,
} from "modules/utils/Team";
import {IPlayer} from "modules/types/json";
import {SagaIterator} from "redux-saga";
import {call, delay, put, select, take} from "typed-redux-saga";
import {
	autoFillClear,
	autoFillGameDaySuccess,
	autoFillSuccess,
	changeFormation,
	changeFormationSuccess,
	clearHeads,
	clearTrades,
	fetchGameBarSuccess,
	fetchLeaderboardConcat,
	fetchLeaderboardSuccess,
	fetchTeamGameDaySuccess,
	fetchTeamSuccess,
	globalError,
	handlePreAuthModal,
	makeTradeSuccess,
	removePlayerFromTeam,
	savedTeamSuccess,
	setHeads,
	setLastTradeEmptyState,
	setTeamComplete,
	setTeamSaved,
	setUserHasTeam,
	successResetTradeByIndex,
	teamFetchComplete,
	teamGlobalRequestStateHandler,
	toggleTeamSavedModal,
	tradeOutPlayer,
	updateLineup,
	updateLineupGameDay,
	fetchTradeHistorySuccess,
} from "modules/actions";
import {RequestStateType} from "modules/types/enums";

export const changeFormationSaga = function* (params: ISagaAction<IFormation>) {
	const {payload} = params;

	try {
		const formation = FORMATIONS.get(payload);

		if (!formation) {
			return;
		}

		const {lineup, ...rest} = yield* select(getTeam);

		const translationTransferError = "Error while changing formation";

		const outPosition: number[] = [];
		const inPosition: number[] = [];

		const newLineup = reduce<ILineup, ILineup>(
			formation,
			(acc, newLine, key) => {
				const positionID = Number(key);
				const currentLine = lineup[key];
				const currentLineWithPlayers = filter(currentLine, identity);

				const emptyLineSize = size(newLine);
				const currentLineSize = size(currentLine);
				const playersLineSize = size(currentLineWithPlayers);

				const availablePositionsDiff = emptyLineSize - currentLineSize;
				const filledPositionsDiff = playersLineSize - emptyLineSize;

				if (gt(availablePositionsDiff, 0)) {
					inPosition.push(...range(0, availablePositionsDiff).fill(positionID));
				}

				if (lte(filledPositionsDiff, 0)) {
					acc[key] = merge([], newLine, currentLineWithPlayers);
				} else {
					outPosition.push(...range(0, filledPositionsDiff).fill(positionID));
				}

				return acc;
			},
			{}
		);

		const isRequireReplace = [isEmpty(outPosition), isEmpty(inPosition)].every((val) => !val);

		if (isRequireReplace) {
			const invalidLineupMessage = "You can only change formation before choosing players";

			const errorMessage = translationTransferError + `<p>${invalidLineupMessage}</p>`;

			throw new ApiError(errorMessage);
		}

		const newTeam: ITeam | INewTeam = {
			...rest,
			lineup: newLineup,
			formation: payload,
		};

		yield put(changeFormationSuccess(newTeam));
	} catch (e) {
		yield put(globalError(e as IError));
	}
};

const updateClassicLineup = function* (
	position: number,
	replaceFrom: number,
	replaceTo: number,
	player_id: number
) {
	try {
		const lineup: ILineup = yield* select(getTeamLineup);
		const isAddPlayerAction = !replaceFrom;

		if (isAddPlayerAction && !hasEmptySpot(lineup, position)) {
			const newFormation = getAvailableFormation(lineup, position);

			if (newFormation) {
				yield call(changeFormationSaga, changeFormation(newFormation));
			} else {
				throw new Error("Can't define allowed formation.");
			}
		}

		const actualLineup: ILineup = yield* select(getTeamLineup);
		const line = [...actualLineup[position]];

		const newLineup: ILineup = {
			...actualLineup,
			[position]: set(line, indexOf(line, replaceFrom), replaceTo),
		};
		yield* put(updateLineup(newLineup));
	} catch (e) {
		yield* put(globalError(e as IError));
		yield* put(removePlayerFromTeam(player_id));
		yield* put(tradeOutPlayer(player_id));
	}
};

const updateGameDayLineup = function* (position: number, replaceFrom: number, replaceTo: number) {
	const teams = yield* select(getTeamsGameDay);
	const selectedTeam = yield* select(getGameDaySelectedTeam);
	const isTeamInArray = teams.find((team) => team.id === selectedTeam.id);

	const lineNon = [...selectedTeam.lineup[position]];
	const newLineup: ILineup = {
		...selectedTeam.lineup,
		[position]: set(lineNon, indexOf(lineNon, replaceFrom), replaceTo),
	};
	const updatedTeam = {
		...selectedTeam,
		lineup: newLineup,
	};

	const newTeams = isTeamInArray
		? teams.map((team) => {
				return team.id === selectedTeam.id
					? {
							...team,
							lineup: newLineup,
					  }
					: team;
		  })
		: [...teams, updatedTeam];

	yield* put(updateLineupGameDay(newTeams));
};

const findAndReplaceInTeamSaga = function* (
	player_id: number,
	replaceFrom: number,
	replaceTo: number
) {
	try {
		const isClassic = yield* select(isClassicGame);
		const isGameDay = yield* select(isGameDayGame);
		const playersById = yield* select(getPlayersById);

		const player: IPlayer = playersById[player_id];
		if (!player) {
			throw new Error("Picked player not found!");
		}

		const {position} = player;

		if (isClassic) {
			yield call(updateClassicLineup, position, replaceFrom, replaceTo, player_id);
		}

		if (isGameDay) {
			yield call(updateGameDayLineup, position, replaceFrom, replaceTo);
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const setPlayerToTeamSaga = function* ({payload}: ISagaAction<number>) {
	yield call(findAndReplaceInTeamSaga, payload, 0, payload);
};

export const transferPlayerToTeamSaga = function* ({payload}: ISagaAction<number>) {
	yield* call(findAndReplaceInTeamSaga, payload, 0, payload);
	const trades = yield* select(getTradePairsAccordingPosition);

	if (trades.length === 0) {
		yield* put(setLastTradeEmptyState(true));
		yield* delay(1000);
		yield* put(setLastTradeEmptyState(false));
		yield* put(setTeamSaved());
	}
};

export const removePlayerFromTeamSaga = function* ({payload}: ISagaAction<number>) {
	yield call(findAndReplaceInTeamSaga, payload, payload, 0);
	const heads = yield* select(getTeamHeads);

	if (payload === heads.captain) {
		yield* put(clearHeads());
	}

	if (payload === heads.viceCaptain) {
		yield* put(
			setHeads({
				...heads,
				viceCaptain: undefined,
			})
		);
	}
};

export const getTeamSaga = function* (): SagaIterator {
	try {
		const defaultFormation = DEFAULT_FORMATION_CLASSIC;
		const EMPTY_TEAM = {
			name: "",
			formation: defaultFormation,
			lineup: FORMATIONS.get(defaultFormation) as ILineup,
		};
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.get, sport);
		const team = response.success.team ?? EMPTY_TEAM;
		yield* put(
			setHeads({
				captain: Number(team.lineup.captain) || 0,
				viceCaptain: Number(team.lineup.vice_captain) || 0,
			})
		);

		yield* put(savedTeamSuccess(team));

		if (team.isCompleted) {
			yield* put(setUserHasTeam(true));
		}

		yield* put(setTeamComplete(Boolean(team.isCompleted)));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(fetchTeamSuccess());
		yield* put(teamFetchComplete());
	}
};

export const getTeamGameDaySaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.TeamGameday.get, sport);
		const teamGameDay = yield* select(getTeamsGameDay);
		const teams = response.success.teams;

		const mapped = teams.length
			? teams.map((team) => {
					const previous = teamGameDay.find((gdTeam) => gdTeam.roundId === team.roundId);

					return {
						...team,
						name: previous ? previous.name : team.name,
						lineup: previous ? previous.lineup : team.lineup,
					};
			  })
			: teamGameDay;

		yield* put(fetchTeamGameDaySuccess(mapped));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(teamFetchComplete());
	}
};

const preAuthorizeSaga = function* () {
	const isAuthorized = yield* select(getIsUserLogged);

	if (!isAuthorized) {
		yield* put(handlePreAuthModal(true));
	}
};

const getUserName = (team: INewTeam): string => {
	return team.name || `Malaysia Liga Super Fantasy ${team.id || 1}`;
};

// eslint-disable-next-line complexity
export const saveTeamSaga = function* (): SagaIterator {
	try {
		const team = yield* select(getTeam);
		const heads = yield* select(getTeamHeads);
		yield* call(preAuthorizeSaga);
		const isAuthorized = yield* select(getIsUserLogged);
		if (!isAuthorized) {
			return;
		}

		const lineup = {
			...reduce(
				team.lineup,
				(acc, value, key) => {
					set(acc, key, filter(value, identity));
					return acc;
				},
				{}
			),
			captain: heads.captain || undefined,
			vice_captain: heads.viceCaptain || undefined,
		};
		const classicParams: INewTeamRequest = {
			name: getUserName(team),
			formation: team.formation,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			lineup,
		};

		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.update, {sport, payload: classicParams});
		if (response.success.team) {
			yield* put(savedTeamSuccess(response.success.team));
			yield* put(toggleTeamSavedModal(true));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(teamFetchComplete());
	}
};

export const saveTeamGameDaySaga = function* (): SagaIterator {
	try {
		yield* call(preAuthorizeSaga);
		const isAuthorized = yield* select(getIsUserLogged);
		if (!isAuthorized) {
			return;
		}

		const isReceived = yield* select(isTeamRequestSuccess);

		if (!isReceived) {
			yield* take(fetchTeamGameDaySuccess);
		}

		const teamGameDay = yield* select(getTeamsGameDay);
		const selectedRoundId = yield* select(getSelectedRoundId);
		const roundId = selectedRoundId || 1;
		const hasGameDayTeam = hasCurrentRoundTeam(teamGameDay, roundId);
		const gameDayTeam = getGameDayTeam(hasGameDayTeam, teamGameDay, roundId);

		const lineup = {
			...reduce(
				gameDayTeam.lineup,
				(acc, value, key) => {
					set(acc, key, filter(value, identity));
					return acc;
				},
				{}
			),
		};
		const gameDayParams: ITeamRequestGameDay = {
			name: gameDayTeam.name,
			lineup,
			round: roundId,
		};
		// const gameDayUpdateParams: ITeamRequestGameDay = {
		// 	name: gameDayTeam.name,
		// 	lineup,
		// };

		const sport = yield* select(getSport);
		const response = hasGameDayTeam
			? yield* call(Api.TeamGameday.update, {
					sport,
					payload: gameDayParams,
			  })
			: yield* call(Api.TeamGameday.create, {
					sport,
					payload: gameDayParams,
			  });
		if (response.success.team) {
			yield* put(savedTeamSuccess(response.success.team));
			yield* put(toggleTeamSavedModal(true));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(teamFetchComplete());
	}
};

export const autoFillSaga = function* () {
	try {
		const isLogged = yield* select(getIsUserLogged);
		const sport = yield* select(getSport);
		const team = yield* select(getTeam);
		const heads = yield* select(getTeamHeads);

		const {captain, vice_captain, ...lineup} = team.lineup;
		const requestUrl = isLogged ? Api.Team.autoPick : Api.Team.autoPickFree;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield* call(requestUrl, {
			sport,
			payload: {
				lineup: {
					...lineup,
					captain: heads.captain || 0,
					vice_captain: heads.viceCaptain || 0,
				},
				formation: team.formation,
			},
		});
		yield* put(autoFillSuccess(response.success.team));
		yield* put(
			setHeads({
				captain: Number(response.success.team.lineup.captain),
				viceCaptain: Number(response.success.team.lineup.vice_captain),
			})
		);
		// if (!isLogged) {
		// 	yield* put(editModalSavingMode(true));
		// }
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(autoFillClear());
	}
};

export const autoFillGameDaySaga = function* () {
	try {
		const isLogged = yield* select(getIsUserLogged);
		const sport = yield* select(getLowerCaseSport);
		const team = yield* select(getGameDaySelectedTeam);
		const round = yield* select(getSelectedRoundId);

		const response = yield* call(
			isLogged ? Api.TeamGameday.autopick : Api.TeamGameday.autopickFree,
			{
				sport,
				payload: {
					lineup: {
						...team.lineup,
					},
					name: team.name,
					round,
				},
			} as IAutoPickGameDayPayload
		);

		const responseTeam = response.success.team;

		yield* put(
			autoFillGameDaySuccess({
				...responseTeam,
				name: responseTeam.name || team.name,
				id: responseTeam.id || team.id,
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(autoFillClear());
	}
};

export const resetTradeByIndexSaga = function* ({payload}: ISagaAction<number>) {
	const getResetTransferData = yield* select(getResetTransferDataSelector);
	const resetTransferData = getResetTransferData(payload);

	if (!resetTransferData) {
		return;
	}

	yield put(successResetTradeByIndex(resetTransferData));
};

export const makeTradeSaga = function* (): SagaIterator {
	try {
		const trades = yield* select(getTrades);
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.makeTrade, {
			sport,
			payload: {
				playersIn: trades.tradeIn,
				playersOut: trades.tradeOut,
			},
		});
		yield* put(makeTradeSuccess(response.success));
		yield* put(clearTrades());
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(500);
		yield* put(
			teamGlobalRequestStateHandler({
				key: "trade",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchGameBarSaga = function* ({payload}: ISagaAction<IGameBarPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.fetchGameBar, {sport, payload});
		yield* put(fetchGameBarSuccess(response.success.gamebar));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchLeaderboardSaga = function* ({
	payload,
}: ISagaAction<IFetchRanksRequestPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.fetchLeaderboard, {sport, payload});

		const requestData = {
			next: response.success.next,
			payload: response.success.rankings,
		};

		if (payload.offset !== 0) {
			yield* put(fetchLeaderboardConcat(requestData));
		} else {
			yield* put(fetchLeaderboardSuccess(requestData));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			teamGlobalRequestStateHandler({
				key: "leaderboard",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchTransferHistorySaga = function* () {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.FantasyTrades.history, {sport});

		yield put(fetchTradeHistorySuccess(response.success.trades));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};
