import React from "react";
import {useSelector} from "react-redux";
import {getTeamHeads} from "modules/selectors";
import styled from "styled-components";
import IconCaptain from "assets/images/icons/IconCaptain.svg";
import IconVice from "assets/images/icons/IconViceCaptain.svg";
import {media} from "assets/media";

const Icon = styled.img`
	position: absolute;
	right: 0;
	top: -12px;
	width: 24px;
	height: 24px;
	z-index: 4;

	@media screen and ${media.lessThanSM} {
		width: 12px;
		height: 12px;
	}
`;

interface IProps {
	playerId: number;
}
export const IconTeamHeads: React.FC<IProps> = ({playerId}) => {
	const heads = useSelector(getTeamHeads);
	const isCaptain = playerId === heads.captain;
	const isViceCaptain = playerId === heads.viceCaptain;
	if (isCaptain) {
		return <Icon src={IconCaptain} alt="C" />;
	}

	if (isViceCaptain) {
		return <Icon src={IconVice} alt="V" />;
	}

	return null;
};
