import React from "react";
import {useIsPathIncludes} from "modules/utils/hooks";
import {useSelector} from "react-redux";
import {getIsHeadsWereChanged} from "modules/selectors";
import {PrimaryButton} from "components/styled";

interface IProps {
	saveTeamHandle: () => void;
	clearTeam: () => void;
	autoFillHandler: () => void;
}

export const LockoutButtons: React.FC<IProps> = ({autoFillHandler, clearTeam, saveTeamHandle}) => {
	const isTransferPage = useIsPathIncludes("transfers");
	const isHeadsHasChanges = useSelector(getIsHeadsWereChanged);

	if (!isHeadsHasChanges || isTransferPage) {
		return null;
	}
	return (
		<React.Fragment>
			<PrimaryButton onClick={saveTeamHandle}>Save</PrimaryButton>
		</React.Fragment>
	);
};
