import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getPlayerById} from "modules/selectors";
import {getPlayerPositionMap} from "modules/utils";
import {Exist} from "components/Exist";
import {media} from "assets/media";

const Wrapper = styled.div``;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #d8d8d8;
	padding: 24px 22px;

	@media screen and ${media.lessThanMD} {
		padding: 16px 9px;
		flex-wrap: wrap;
		border-bottom: 0;
		justify-content: flex-start;
		gap: 0;
	}
`;

interface IProps {
	playerId: number;
}

export const PlayerOverview: React.FC<IProps> = ({playerId}) => {
	const player = useSelector(getPlayerById)(playerId);
	const {isGoalkeeper, isDefender, isMidfielder, isForward} = getPlayerPositionMap(
		player?.position
	);
	const isMidfielderOrForward = isMidfielder || isForward;

	return (
		<Wrapper className="overview">
			<Exist when={isGoalkeeper}>
				<Row>
					<Column title="Season Points" points={player?.stats?.total_points} />
					<Column title="Pts per game" points={player?.stats?.avg_points} />
					<Column title="Starting XI" points={player?.stats?.starting11} />
					<Column title="Sub apps" points={player?.stats?.substitutions} />
					<Column title="Selected %" points={player?.stats?.picked_by} />
				</Row>
				<Row>
					<Column title="Clean sheets" points={player?.stats?.clean_sheets} />
					<Column title="Goals conceded" points={player?.stats?.goals_conceded} />
					<Column title="Own Goals" points={player?.stats?.own_goals} />
					<Column title="Yellow Cards" points={player?.stats?.yellow_card} />
					<Column title="Red Cards" points={player?.stats?.red_card} />
				</Row>
			</Exist>
			<Exist when={isDefender}>
				<Row>
					<Column title="Season Points" points={player?.stats?.total_points} />
					<Column title="Pts per game" points={player?.stats?.round_scores?.[0]} />
					<Column title="Starting XI" points={player?.stats?.starting11} />
					<Column title="Sub apps" points={player?.stats?.substitutions} />
					<Column title="Selected %" points={player?.stats?.picked_by} />
				</Row>
				<Row>
					<Column title="Goals" points={player?.stats?.goals} />
					<Column title="Assists" points={player?.stats?.assists} />
					<Column title="Clean sheets" points={player?.stats?.clean_sheets} />
					<Column title="Own Goals" points={player?.stats?.own_goals} />
					<Column title="Yellow Cards" points={player?.stats?.yellow_card} />
					<Column title="Red Cards" points={player?.stats?.red_card} />
				</Row>
			</Exist>
			<Exist when={isMidfielderOrForward}>
				<Row>
					<Column title="Season Points" points={player?.stats?.total_points} />
					<Column title="Pts per game" points={player?.stats?.round_scores?.[0]} />
					<Column title="Starting XI" points={player?.stats?.starting11} />
					<Column title="Sub apps" points={player?.stats?.substitutions} />
					<Column title="Selected %" points={player?.stats?.picked_by} />
				</Row>
				<Row>
					<Column title="Goals" points={player?.stats?.goals} />
					<Column title="Assists" points={player?.stats?.assists} />
					<Column title="Own Goals" points={player?.stats?.own_goals} />
					<Column title="Yellow Cards" points={player?.stats?.yellow_card} />
					<Column title="Red Cards" points={player?.stats?.red_card} />
				</Row>
			</Exist>
		</Wrapper>
	);
};

interface IColumnProps {
	title: string;
	points: number | undefined;
}

const StatsBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 11px;

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 18px;
		letter-spacing: 0;
		line-height: 22px;
		text-align: center;
		font-weight: bold;
		margin: 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		text-align: center;
		font-weight: normal;
		margin: 0;
	}

	@media screen and ${media.lessThanMD} {
		border-bottom: 1px solid #d8d8d8;
		width: 33%;
		padding: 16px 0;
	}
`;
const Column: React.FC<IColumnProps> = ({title, points}) => {
	const hasPoints = !(points === undefined || points === null);
	return (
		<StatsBlock>
			<h3>{hasPoints ? points : "-"}</h3>
			<p>{title || "&nbsp;"}</p>
		</StatsBlock>
	);
};
