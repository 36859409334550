import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getIsUserLogged, getSelectedRoundId, getTeamGameDayGameBar} from "modules/selectors";
import {fetchGameBarGameDayRequest} from "modules/actions";
import {StatsBlock} from "components";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 10px 10px 15px 10px;
	border-radius: 0 0 5px 5px;
	border: 1px solid #dddddd;
	border-top: 0;
`;

export const GameDayTeamStats: React.FC = () => {
	const dispatch = useDispatch();
	const isLogged = useSelector(getIsUserLogged);
	const selectedRoundId = useSelector(getSelectedRoundId);
	const gameBar = useSelector(getTeamGameDayGameBar);

	useEffect(() => {
		if (selectedRoundId && isLogged) {
			dispatch(fetchGameBarGameDayRequest({round: selectedRoundId}));
		}
	}, [isLogged, selectedRoundId, dispatch]);

	return (
		<Wrapper>
			<StatsBlock title="Points" value={gameBar.round_points} />
			<StatsBlock title="Position" value={gameBar.round_rank} />
		</Wrapper>
	);
};
