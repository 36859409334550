import {fill, get, range, reduce, set, split} from "lodash";
import {FORMATION_LIST} from "modules/constants";
import {ITeamGameDay} from "modules/types";
import {ILineup} from "modules/types/team";

export type IFormation = typeof FORMATION_LIST[number];
const createLineupFromFormation = (formation: IFormation): Readonly<ILineup> =>
	reduce(
		split(formation, "-"),
		(acc, arrayLength, index) => {
			set(acc, index + 1, fill(range(0, Number(arrayLength)), 0));
			return acc;
		},
		{}
	);

export const FORMATIONS = new Map(
	FORMATION_LIST.map((formation) => [formation, createLineupFromFormation(formation)])
);

export const DEFAULT_FORMATION_CLASSIC = "1-4-4-2";
export const DEFAULT_FORMATION_GAMEDAY = "1-2-2-2";
export const DEFAULT_TEAM_GAMEDAY: ITeamGameDay[] = [
	{
		name: "",
		lineup: {
			1: [0],
			2: [0, 0],
			3: [0, 0],
			4: [0, 0],
		},
		id: 0,
		roundId: 0,
		sport: "msl",
		points: 0,
		isCompleted: false,
	},
];

export const hasCurrentRoundTeam = (teamGameDay: ITeamGameDay[], roundID: number) => {
	const currentTeam = teamGameDay.find((team) => get(team, "roundId", 0) === roundID);
	return Boolean(currentTeam?.id);
};

export const getGameDayTeam = (
	hasGameDayTeam: boolean,
	teamGameDay: ITeamGameDay[],
	roundID: number
): ITeamGameDay => {
	if (teamGameDay.length) {
		return hasGameDayTeam
			? teamGameDay.find((teamItem) => teamItem.roundId === roundID) ||
					DEFAULT_TEAM_GAMEDAY[0]
			: teamGameDay[0];
	} else {
		return DEFAULT_TEAM_GAMEDAY[0];
	}
};
