import {SagaIterator} from "redux-saga";
import {
	createLeagueGameDayClear,
	createLeagueGameDaySuccess,
	fetchJoinLeaguesGameDayClear,
	fetchJoinLeaguesGameDayConcat,
	fetchJoinLeaguesGameDaySuccess,
	fetchLeagueGameDayRanksSuccess,
	fetchLeagueGameDayTableConcat,
	fetchLeagueGameDayTableSuccess,
	fetchLeagueGameDayUsersSuccess,
	fetchLeaguesGameDaySuccess,
	globalError,
	joinLeagueGameDayClear,
	JoinLeagueGameDaySuccess,
	leagueGameDayRemoveUserClear,
	leagueGameDayRemoveUserSuccess,
	leagueGameDayRequestGlobalStateHandler,
	leaveLeagueGameDayClear,
	leaveLeagueGameDaySuccess,
	sendLeagueGameDayInviteClear,
	sendLeagueGameDayInviteSuccess,
	updateLeagueGameDaySuccess,
} from "modules/actions";
import {getJoinLeaguesGameDayState, getSport} from "modules/selectors";
import {call, delay, put, select} from "typed-redux-saga";
import {
	ICreateLeagueForm,
	ICreateLeagueRequestPayload,
	IError,
	IFetchLeaguesGetParams,
	IFetchRanksRequestPayload,
	ILeagueInvitePayload,
	ILeagueRemoveUserPayload,
	IPaginationRequest,
	ISagaAction,
	ISportWithIdPayload,
	IWithIdPayload,
	IWithSportPayload,
} from "modules/types";
import {Api} from "modules/utils/Api";
import {DEFAULT_BUTTON_SUCCESS_DELAY} from "modules/constants";
import {openInfoModal} from "modules/actions/modals";
import {RequestStateType} from "modules/types/enums";

export const fetchLeagueGameDaySaga = function* ({
	payload,
}: ISagaAction<IFetchLeaguesGetParams>): SagaIterator {
	try {
		const filter: IFetchLeaguesGetParams = {
			privacy: payload.privacy === "all" ? undefined : payload.privacy,
			limit: payload.limit,
			offset: payload.offset,
		};
		const sport = yield* select(getSport);
		const response = yield* call(Api.LeagueGameDay.fetch, {sport, payload: filter});
		yield* put(fetchLeaguesGameDaySuccess(response.success.leagues));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(2000);
		yield* put(
			leagueGameDayRequestGlobalStateHandler({
				key: "fetch",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const createLeagueGameDaySaga = function* ({
	payload,
}: ISagaAction<ICreateLeagueForm>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const data: IWithSportPayload<ICreateLeagueRequestPayload> = {
			sport,
			payload: {
				name: payload.name,
				privacy: payload.privacy,
				start_round_id: payload.startRound,
			},
		};
		const response = yield* call(Api.LeagueGameDay.create, {...data});
		yield* put(createLeagueGameDaySuccess([response.success.league]));
	} catch (e) {
		yield* put(globalError(e as IError));
		yield* put(createLeagueGameDayClear());
	} finally {
		yield* delay(2000);
		yield* put(
			leagueGameDayRequestGlobalStateHandler({
				key: "fetch",
				state: RequestStateType.Idle,
			})
		);
		yield* put(createLeagueGameDayClear());
	}
};

export const updateLeagueGameDaySaga = function* ({
	payload,
}: ISagaAction<ISportWithIdPayload<ICreateLeagueRequestPayload>>): SagaIterator {
	try {
		const response = yield* call(Api.LeagueGameDay.update, payload);
		yield* put(updateLeagueGameDaySuccess(response.success.league));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(DEFAULT_BUTTON_SUCCESS_DELAY);
		yield* put(
			leagueGameDayRequestGlobalStateHandler({
				key: "update",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchJoinLeaguesGameDaySaga = function* ({
	payload,
}: ISagaAction<IPaginationRequest>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.LeagueGameDay.fetchJoin, {sport, payload});
		if (payload.page === 1) {
			yield* put(fetchJoinLeaguesGameDaySuccess(response.success));
		} else {
			const joinLeagues = yield* select(getJoinLeaguesGameDayState);
			yield* put(
				fetchJoinLeaguesGameDayConcat({
					leagues: [...joinLeagues.leagues, ...response.success.leagues],
					nextPage: response.success.nextPage,
				})
			);
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(fetchJoinLeaguesGameDayClear());
	}
};

export const joinLeagueGameDaySaga = function* ({payload}: ISagaAction<string>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.LeagueGameDay.join, {sport, payload});
		yield* put(JoinLeagueGameDaySuccess(response.success.league));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(joinLeagueGameDayClear());
	}
};

export const leaveLeagueGameDaySaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		yield* call(Api.LeagueGameDay.leave, {sport, payload});
		yield* put(leaveLeagueGameDaySuccess());
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(leaveLeagueGameDayClear());
	}
};

export const sendLeagueInviteGameDaySaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<ILeagueInvitePayload[]>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		yield* call(Api.LeagueGameDay.sendInvites, {
			sport,
			id: payload.id,
			payload: payload.payload,
		});
		yield* put(sendLeagueGameDayInviteSuccess());
		yield* put(
			openInfoModal({
				title: "Invites sent",
				text: "Your friends should receive their invites shortly.",
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(sendLeagueGameDayInviteClear());
	}
};

export const fetchLeagueRanksGameDaySaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<IFetchRanksRequestPayload>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.LeagueGameDay.fetchRanks, {
			sport,
			id: payload.id,
			payload: payload.payload,
		});
		yield* put(fetchLeagueGameDayRanksSuccess(response.success.rankings));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			leagueGameDayRequestGlobalStateHandler({
				key: "fetchLeagueRanks",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchLeagueUsersGameDaySaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<IPaginationRequest>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.LeagueGameDay.fetchUsers, {
			sport,
			id: payload.id,
			payload: payload.payload,
		});
		yield* put(
			fetchLeagueGameDayUsersSuccess({
				payload: response.success.users,
				newPage: response.success.nextPage,
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			leagueGameDayRequestGlobalStateHandler({
				key: "fetchLeagueUsers",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const LeagueRemoveUserGameDaySaga = function* ({
	payload,
}: ISagaAction<ILeagueRemoveUserPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		yield* call(Api.LeagueGameDay.removeLeagueUser, {
			sport,
			id: payload.leagueId,
			payload: payload.userId,
		});
		yield* put(leagueGameDayRemoveUserSuccess(payload.userId));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(1000);
		yield* put(leagueGameDayRemoveUserClear());
	}
};

export const fetchLeagueTableGameDaySaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<IFetchRanksRequestPayload>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.LeagueGameDay.fetchTable, {
			id: payload.id,
			sport,
			payload: payload.payload,
		});

		const requestData = {
			next: response.success.next,
			payload: response.success.rankings,
		};

		if (payload.payload.offset !== 0) {
			yield* put(fetchLeagueGameDayTableConcat(requestData));
		} else {
			yield* put(fetchLeagueGameDayTableSuccess(requestData));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			leagueGameDayRequestGlobalStateHandler({
				key: "fetchLeagueTable",
				state: RequestStateType.Idle,
			})
		);
	}
};
