import React, {useCallback, useState} from "react";
import {
	Exist,
	IconRemove,
	ModalBackdrop,
	ModalClose,
	ModalCloseButton,
	ModalContent,
} from "components";
import styled from "styled-components";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
	AuthLogo,
	ErrorText,
	Input,
	NavigateToAnotherAuthPageLink,
	PureButton,
	SubmitButtonWrapper,
	TextFieldControl,
} from "components/styled";
import {DateTime} from "luxon";
import {Checkbox, FormControlLabel, MenuItem} from "@mui/material";
import {IRegisterFormPayload, IRegisterPayload, UserGender} from "modules/types/user";
import {ICheckboxDataset} from "modules/types";
import {handleAuthModal, userRegister} from "modules/actions";
import {getIsSavingMode} from "modules/selectors";
import {MAX_USER_DATE, MIN_USER_DATE, PASSWORD_PATTERN, USER_DOB_FORMAT} from "modules/constants";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import LogoIcon from "assets/images/LogoColor.png";
import {EAuthModalType} from "modules/types/enums";
// import {debounce} from "lodash";

const Title = styled.h1`
	color: ${({theme}) => theme.text_color};
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
	text-align: center;
`;

const Form = styled.form`
	margin-top: 40px;
`;

const LabelWithLink = styled(FormControlLabel)`
	a {
		color: ${({theme}) => theme.text_color};
	}
`;

export const ModalRegister: React.FC = () => {
	const dispatch = useDispatch();
	const isSaveMode = useSelector(getIsSavingMode);
	const [error, setError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [registerValues, setRegisterValue] = useState<Partial<IRegisterFormPayload>>({
		birthday: MAX_USER_DATE.toJSDate(),
		email: "",
		confirmEmail: "",
		firstName: "",
		gender: UserGender.PreferNotToSay,
		geniusOptIn: false,
		isNotificationsEnabled: false,
		lastName: "",
		mflOptIn: false,
		password: "",
		confirmPassword: "",
		terms: false,
	});

	const updateCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.checked;
		const fieldName = e.currentTarget.dataset["field"];

		if (!fieldName) {
			return;
		}

		setRegisterValue({
			...registerValues,
			[fieldName]: value,
		});
	};

	const updateField = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			const fieldName = e.target.name || e.target.dataset["field"];

			if (!value || !fieldName) {
				return;
			}

			setRegisterValue({
				...registerValues,
				[fieldName]: value,
			});
		},
		[registerValues]
	);

	const checkAgeOnSubmit = (date: Date | null) => {
		if (!date) {
			return MIN_USER_DATE.toJSDate();
		}
		const dateStr = DateTime.fromISO(new Date(date).toISOString().substring(0, 10));

		const isTooYoung = dateStr.startOf("day") > MAX_USER_DATE.startOf("day");
		if (isTooYoung) {
			return MAX_USER_DATE.toJSDate();
		}
		const isTooOld = dateStr.startOf("day") < MIN_USER_DATE.startOf("day");
		if (isTooOld) {
			return MIN_USER_DATE.toJSDate();
		}
		return dateStr.toJSDate();
	};

	/**
	 * Several parts commented out
	 * waiting for approval in MFLSLC-400
	 * to check date on submit as previous
	 * method was wiping all other fields
	 */

	/*
	const checkAge = (date: DateTime | null) => {
		if (!date) {
			return;
		}

		const isTooYoung = date.startOf("day") > MAX_USER_DATE.startOf("day");
		if (isTooYoung) {
			setRegisterValue({
				...registerValues,
				birthday: MAX_USER_DATE.toJSDate(),
			});
			return;
		}
		const isTooOld = date.startOf("day") < MIN_USER_DATE.startOf("day");
		if (isTooOld) {
			setRegisterValue({
				...registerValues,
				birthday: MIN_USER_DATE.toJSDate(),
			});
		}
	};
	*/

	// eslint-disable-next-line react-hooks/exhaustive-deps
	// const checkAgeDebounce = useCallback(debounce(checkAge, 1500), [debounce]);

	const setDOB = useCallback(
		(date: DateTime | null) => {
			if (!date) {
				return;
			}

			// checkAgeDebounce(date);
			setRegisterValue({
				...registerValues,
				birthday: date.toJSDate(),
			});
		},
		[registerValues]
		// [registerValues, checkAgeDebounce]
	);

	const onFormSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();

		setError("");
		setPasswordError("");
		if (registerValues.email?.toLowerCase() !== registerValues.confirmEmail?.toLowerCase()) {
			setError("Emails don't match");
			return;
		}

		if (registerValues.password !== registerValues.confirmPassword) {
			setPasswordError("Passwords don't match");
			return;
		}

		if (
			!registerValues.password ||
			!new RegExp(PASSWORD_PATTERN).test(String(registerValues.password))
		) {
			setPasswordError(
				"Password must contain at least 8 characters, 1 uppercase, 1 number and 1 special character"
			);
			return;
		}
		const birthday = checkAgeOnSubmit(registerValues.birthday!);
		const userPayload: IRegisterPayload = {
			email: registerValues.email!,
			password: registerValues.password,
			firstName: registerValues.firstName!,
			lastName: registerValues.lastName!,
			birthday: DateTime.fromJSDate(birthday).toFormat(USER_DOB_FORMAT),
			gender: registerValues.gender!,
			mflOptIn: registerValues.mflOptIn!,
			geniusOptIn: registerValues.geniusOptIn!,
		};
		dispatch(userRegister(userPayload));
	};

	const toggleModal = useCallback(() => {
		dispatch(handleAuthModal({authModalToShow: EAuthModalType.LOGIN}));
	}, [dispatch]);

	const closeModal = useCallback(() => {
		dispatch(handleAuthModal({showAuthModal: false}));
	}, [dispatch]);

	const modalHasFadeClass = isSaveMode ? "fade" : "";
	return (
		<ModalBackdrop className={modalHasFadeClass}>
			<ModalContent className="full">
				<Exist when={isSaveMode}>
					<ModalCloseButton onClick={closeModal}>
						<IconRemove />
					</ModalCloseButton>
				</Exist>
				<Exist when={!isSaveMode}>
					<ModalClose to="/">
						<IconRemove />
					</ModalClose>
				</Exist>
				<AuthLogo src={LogoIcon} alt="logo" />
				<Title>Log in to your Malaysian Fantasy Football account:</Title>
				<Exist when={Boolean(error)}>
					<ErrorText className="center">{error}</ErrorText>
				</Exist>
				<Form onSubmit={onFormSubmit}>
					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label="First name"
							inputProps={{"data-field": "firstName"}}
							onChange={updateField}
							required={true}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label="Last name"
							inputProps={{"data-field": "lastName"}}
							onChange={updateField}
							required={true}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label="Email"
							type="email"
							inputProps={{"data-field": "email"}}
							onChange={updateField}
							required={true}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							type="email"
							label="Confirm Email"
							onChange={updateField}
							inputProps={{"data-field": "confirmEmail"}}
							required={true}
						/>
					</TextFieldControl>
					<TextFieldControl className="sized">
						<Input
							select
							variant="outlined"
							label="Gender"
							name="gender"
							onChange={updateField}
							inputProps={{"data-field": "gender"}}
							defaultValue="0"
							required={true}>
							<MenuItem value="0" selected={true} disabled={true}>
								Select Gender
							</MenuItem>
							<MenuItem value={UserGender.Male}>Male</MenuItem>
							<MenuItem value={UserGender.Female}>Female</MenuItem>
							<MenuItem value={UserGender.Other}>Other</MenuItem>
							<MenuItem value={UserGender.PreferNotToSay}>Prefer not to say</MenuItem>
						</Input>
					</TextFieldControl>
					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label="Password"
							type="password"
							onChange={updateField}
							required={true}
							helperText={passwordError}
							error={Boolean(passwordError)}
							inputProps={{
								"data-field": "password",
							}}
						/>
					</TextFieldControl>
					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label="Confirm Password"
							type="password"
							onChange={updateField}
							required={true}
							inputProps={{
								"data-field": "confirmPassword",
								// pattern: PASSWORD_PATTERN,
							}}
						/>
					</TextFieldControl>
					<TextFieldControl className="sized">
						<DesktopDatePicker
							label="D.O.B"
							minDate={MIN_USER_DATE}
							maxDate={MAX_USER_DATE}
							inputFormat={USER_DOB_FORMAT}
							value={registerValues.birthday}
							onChange={setDOB}
							renderInput={(params) => <Input {...params} required={true} />}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized checkbox">
						<LabelWithLink
							control={
								<Checkbox
									checked={registerValues.terms}
									onChange={updateCheckbox}
									required={true}
									// Seems as broken types in library, InputProps appends error, used disabled to avoid any
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									inputProps={{"data-field": "terms"} as ICheckboxDataset}
								/>
							}
							label={
								<span>
									By selecting this box I agree to the{" "}
									<a href="/help/terms" target="_blank">
										Terms and Conditions
									</a>
								</span>
							}
						/>
						{registerValues.terms}
					</TextFieldControl>

					<TextFieldControl className="sized checkbox">
						<FormControlLabel
							control={
								<Checkbox
									checked={registerValues.mflOptIn}
									onChange={updateCheckbox}
									// Seems as broken types in library, InputProps appends error, used disabled to avoid any
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									inputProps={{"data-field": "mflOptIn"} as ICheckboxDataset}
								/>
							}
							label="I would like to receive offers, promotions and services from the Malaysian Football League and its group companies by email."
						/>
					</TextFieldControl>
					<TextFieldControl className="sized checkbox">
						<FormControlLabel
							control={
								<Checkbox
									checked={registerValues.geniusOptIn}
									onChange={updateCheckbox}
									// Seems as broken types in library, InputProps appends error, used disabled to avoid any
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									inputProps={{"data-field": "geniusOptIn"} as ICheckboxDataset}
								/>
							}
							label="I would like to receive offers, promotions and services from Genius Sports (the provider of the game) and its group companies by email."
						/>
					</TextFieldControl>
					<SubmitButtonWrapper>
						<button>Register</button>
					</SubmitButtonWrapper>
					<NavigateToAnotherAuthPageLink>
						<Exist when={!isSaveMode}>
							Already have an account? <Link to="/login">Sign In</Link>
						</Exist>
						<Exist when={isSaveMode}>
							Already have an account?{" "}
							<PureButton type="button" onClick={toggleModal}>
								Sign In
							</PureButton>
						</Exist>
					</NavigateToAnotherAuthPageLink>
				</Form>
			</ModalContent>
		</ModalBackdrop>
	);
};
