import React from "react";
import styled from "styled-components";
import {media} from "assets/media";
import {APP_IS_AD_ENABLED} from "modules/constants";

const AdWrapper = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 32px 24px;
	display: flex;
	justify-content: center;

	&.none {
		opacity: 0;
		pointer-events: none;
		height: 80px;
	}

	@media screen and (max-width: ${media.sm}) {
		padding: 16px;
		display: none;
	}
`;

const SideAdvertisement = styled.div`
	box-sizing: border-box;
	height: 70px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #b8b8b8;
	background-color: #a7a7a7;

	span {
		color: #555555;
		font-size: 12px;
		letter-spacing: 0;
		line-height: 22px;
	}
`;

const Advertisement = styled.div`
	height: 90px;
	max-width: 728px;
	width: 100%;
	border: 1px solid #b8b8b8;
	background-color: #a7a7a7;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		color: #555555;
		font-size: 12px;
		letter-spacing: 0;
		line-height: 22px;
		@media screen and (max-width: ${media.sm}) {
			color: #555555;
		}
	}

	@media screen and (max-width: ${media.sm}) {
		height: 50px;
	}
`;

interface IProps {
	className?: string;
}
export const AdBlock: React.FC<IProps> = ({className = ""}) => {
	const adClass = APP_IS_AD_ENABLED ? "" : "none";
	return <AdWrapper />;
	return (
		<AdWrapper className={`${className} ${adClass}`}>
			<Advertisement>
				<span>AD UNIT</span>
			</Advertisement>
		</AdWrapper>
	);
};

interface IProps {
	className?: string;
}
export const AdBlockSideBar: React.FC<IProps> = ({className = ""}) => {
	if (!APP_IS_AD_ENABLED) {
		return null;
	}
	return (
		<SideAdvertisement className={className}>
			<span>SPONSOR AD</span>
		</SideAdvertisement>
	);
};
