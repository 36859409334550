import {getTeamForGame} from "modules/selectors";
import React from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
	color: ${({theme}) => theme.text_color};
	font-size: 14px;
	letter-spacing: 0;
	line-height: 26px;
`;

export const MyLeagueTeamList: React.FC = () => {
	const team = useSelector(getTeamForGame);
	return <Wrapper>{team.name}</Wrapper>;
};
