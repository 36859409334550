import React from "react";
import styled from "styled-components";
import {PrimaryButton, PureButton} from "components/styled";
import facebookIcon from "assets/images/icons/facebook.svg";
import twitterIcon from "assets/images/icons/twitter.svg";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/media";
import {getLinkForShare, getShareText, share} from "modules/utils/Share";
import {getSport, getGameName} from "modules/selectors";
import {ShareTarget, ShareType} from "modules/types/share";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const SocialButton = styled(PureButton)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	width: 100%;
	font-weight: 200;

	color: #ffffff;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 17px;
	height: 40px;
	padding: 13px 12px;
	transition: 0.25s;

	&.facebook {
		background: #3b5998;
	}

	&.twitter {
		background: #1da1f2;
	}

	img {
		height: 16px;
		width: auto;
	}

	&:hover {
		opacity: 0.7;
	}
`;

const NativeShareButton = styled(PrimaryButton)`
	width: 100%;
	max-width: unset;
`;

interface IProps {
	leagueId: number;
	code: string;
}

export const SocialSharing: React.FC<IProps> = ({leagueId, code = ""}) => {
	const sport = useSelector(getSport);
	const game = useSelector(getGameName);
	const isMobile = useMediaQuery(media.lessThanMD);
	const isNavigatorAccessible = "share" in navigator && isMobile;
	const shareVia = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const target = e.currentTarget.dataset.target;

		if (!target) {
			return;
		}

		share({
			target: target as ShareTarget,
			type: ShareType.League,
			sport,
			leagueId,
			code,
			game,
		});
	};

	const shareNative = async () => {
		try {
			const url = getLinkForShare(
				{
					type: ShareType.League,
					sport,
					leagueId,
					code,
				},
				ShareType.League
			);
			const text = getShareText(ShareType.League, code, sport);

			const shareData = {url, text};

			await navigator.share(shareData);
		} catch (err) {
			console.log(err);
		}
	};

	if (isNavigatorAccessible) {
		return <NativeShareButton onClick={shareNative}>Share</NativeShareButton>;
	}

	return (
		<Wrapper className="sharing">
			<SocialButton
				onClick={shareVia}
				className="facebook"
				data-target={ShareTarget.Facebook}>
				<img src={facebookIcon} alt="Twitter icon" />
				Facebook
			</SocialButton>
			<SocialButton onClick={shareVia} className="twitter" data-target={ShareTarget.Twitter}>
				<img src={twitterIcon} alt="Twitter icon" />
				Twitter
			</SocialButton>
		</Wrapper>
	);
};
