import {InputAdornment, useMediaQuery} from "@mui/material";
import {Input} from "components/styled";
import React from "react";
import styled from "styled-components";
import IconSearch from "assets/images/icons/IconSearch.svg";
import {media} from "assets/media";

const Wrapper = styled.div`
	margin-bottom: 24px;

	${Input} {
		max-width: 500px;
		width: 100%;

		.MuiInputLabel-root:not([data-shrink="true"]) {
			color: #a3a3a3 !important;
		}
	}

	@media screen and ${media.lessThanMD} {
		padding: 0 20px;
	}
`;

interface IProps {
	filter: string;
	onFilterSet: (search: string) => void;
}

export const JoinFilter: React.FC<IProps> = ({filter, onFilterSet}) => {
	const isMobile = useMediaQuery(media.lessThanMD);
	const searchText = isMobile
		? "Search for a public league..."
		: "Search for a public league or enter a private pin";

	const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onFilterSet) {
			onFilterSet(e.currentTarget.value);
		}
	};

	return (
		<Wrapper>
			<Input
				label={searchText}
				onChange={onFilterChange}
				value={filter}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<img src={IconSearch} alt="search" />
						</InputAdornment>
					),
				}}
			/>
		</Wrapper>
	);
};
