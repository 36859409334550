import React, {useCallback, useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	removePlayerFromTeam,
	resetTradeByIndex,
	setTeamSaved,
	tradeOutPlayer,
} from "modules/actions";
import {
	getIsLastTransferMakeEmpty,
	getIsTeamLockout,
	getIsUserHasTeam,
	getPlayerById,
	getTeamLineup,
	getTradePairsAccordingPosition,
} from "modules/selectors";
import {flatMap, isEmpty} from "lodash";
import {TransferRow} from "components";
import {useIsPathIncludes, useNavigateWithSport} from "modules/utils/hooks";
import {useParams} from "react-router-dom";

const Wrapper = styled.div`
	margin-top: -19px;
`;

const Table = styled.table`
	width: 100%;
	border: 1px solid #dddddd;
	border-collapse: collapse;
`;

const Thead = styled.thead`
	border: 1px solid #dddddd;

	th {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 17px;
	}

	th {
		&.reset-action {
			width: 50px;
			border-left: 1px solid #dddddd;
		}

		&.out {
			text-align: left;
			padding-left: 55px;
		}

		&.in {
			text-align: right;
			padding-right: 55px;
		}
	}
`;

const Tbody = styled.tbody`
	tr {
		border-bottom: 1px solid #dddddd;

		td {
			&.reset {
				width: 50px;
				border-left: 1px solid #dddddd;
				height: 100%;

				button {
					width: 100%;
					height: 50px;
					position: relative;

					&:after {
						content: "";
						min-width: 24px;
						min-height: 24px;
						background: #f8f8f8;
						-webkit-border-radius: 50%;
						-moz-border-radius: 50%;
						border-radius: 50%;
						position: absolute;
						left: 50%;
						top: 45%;
						transform: translate(-50%, -50%);
						z-index: -1;
					}
				}
			}

			&.icon {
				padding: 0 12px;
			}

			&.out {
				text-align: left;
			}

			&.in {
				text-align: right;
			}
		}
	}
`;

interface IProps {
	hasAction: boolean;
}

interface IParams {
	id?: string;
}

export const TransferView: React.FC<IProps> = ({hasAction}) => {
	const params: IParams = useParams();
	const transferPlayerId = Number(params.id);
	const transferPlayer = useSelector(getPlayerById)(transferPlayerId);
	const dispatch = useDispatch();
	const isTradesEmpty = useSelector(getIsLastTransferMakeEmpty);
	const sportNavigate = useNavigateWithSport();
	const tradesData = useSelector(getTradePairsAccordingPosition);
	const isUserHasTeam = useSelector(getIsUserHasTeam);
	const isLockout = useSelector(getIsTeamLockout);
	const isTransferPage = useIsPathIncludes("transfers");

	const lineup = useSelector(getTeamLineup);
	const flatLineUp = flatMap(lineup);

	const resetAll = useCallback(() => {
		for (let i = 0; i < tradesData.length; i++) {
			dispatch(resetTradeByIndex(0));
		}
	}, [tradesData, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(setTeamSaved());
		};
	}, [dispatch]);

	useEffect(() => {
		if (!isLockout && isUserHasTeam) {
			resetAll();
			sportNavigate("/team");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, sportNavigate, isTransferPage, isUserHasTeam, tradesData]);

	useEffect(() => {
		if (isTradesEmpty) {
			sportNavigate("/team");
			dispatch(setTeamSaved());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTradesEmpty, sportNavigate, dispatch]);

	useEffect(() => {
		const isUserOnField = flatLineUp.includes(transferPlayerId);

		if (!isUserHasTeam || !isUserOnField) {
			return;
		}
		if (transferPlayerId && isUserOnField) {
			dispatch(tradeOutPlayer(transferPlayerId));
			dispatch(removePlayerFromTeam(transferPlayerId));
		}
		// Disabled because trade out needs ony on first load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	if (!transferPlayer || isEmpty(tradesData)) {
		return null;
	}

	return (
		<Wrapper>
			<Table>
				<Thead>
					<tr>
						<th className="out">Out</th>
						<th>&nbsp;</th>
						<th className="in">In</th>
						<th className="reset-action">Reset</th>
					</tr>
				</Thead>
				<Tbody>
					{tradesData.map(([tradeOut, tradeIn], tradeIndex) => (
						<TransferRow
							key={tradeOut}
							tradeOut={tradeOut}
							tradeIn={tradeIn}
							tradeIndex={tradeIndex}
							hasAction={hasAction}
						/>
					))}
				</Tbody>
			</Table>
		</Wrapper>
	);
};
