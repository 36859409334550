export enum RequestStateType {
	Loading,
	Success,
	Error,
	Idle,
}

export enum EAuthModalType {
	REGISTRATION = "registration",
	LOGIN = "login",
}

export enum MatchStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum PlayerStatus {
	Playing = "playing",
	NotPlaying = "not-playing",
	Injured = "injured",
	Uncertain = "uncertain",
	Eliminated = "eliminated",
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum LeagueClass {
	Overall = "overall",
	Regular = "regular",
	Facebook = "facebook",
	Twitter = "twitter",
	One = "one",
	ThreeLions = "three_lions",
}

export enum LeagueType {
	OPEN = "open",
}

export enum Subscriptions {
	Trial = "trial",
	Month = "month",
	Year = "year",
}

export enum SubscriptionStatus {
	Active = "active",
	CancelledByUser = "cancelled_user",
	CancelledBySystem = "cancelled_system",
}

export enum Competition {
	FA = "fa",
	EURO = "euro",
}

export enum StatsTab {
	Weekly,
	Overall,
	TransferValue,
}

export enum ThreeLionsStatus {
	Joined = "joined",
	NotJoined = "not_joined",
	Removed = "removed",
}

export enum ShowOverviewType {
	Monthly = "monthly",
	Weekly = "weekly",
	Phase = "phase",
}

export enum ShowReviewType {
	Monthly = "monthly",
	Season = "season",
	Weekly = "weekly",
}

export enum StatsCentreTab {
	Players = "players",
	FormGuide = "form-guide",
	Teams = "teams",
}

export enum MatchCentreTab {
	ByRound = "by-round",
	ByTeam = "by-team",
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum MatchFactType {
	Goal = "goal",
	OwnGoal = "own_goal",
	MissedPenalty = "missed_penalty",
	KeyContribution = "assist",
	YellowCard = "yellow_card",
	RedCard = "red_card",
	SubOff = "sub_off",
	SubOn = "sub_on",
}

export enum PlayerPosition {
	Goalkeeper = 1,
	Defender = 2,
	Midfielder = 3,
	Forward = 4,
}
export enum Steps {
	First,
	Second,
}

export enum LeagueTableType {
	Overall,
	Round,
}

export enum ProjectType {
	Classic = "classic",
	Gameday = "gameday",
}

export enum SportType {
	MC = "MC",
	MSL = "MSL",
}
