import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {find, last, memoize} from "lodash";
import {ILeagueReducer} from "modules/types/reducers";
import {RequestStateType} from "modules/types/enums";
import {getUser} from "modules/selectors";
import {IApiFetchJoinLeagueGameDayClearResponse} from "modules/types/api";

const getState = ({leaguesGameDay}: IStore) => leaguesGameDay;

export const getLeaguesGameDay = createSelector(getState, (state) => state.leagues);
export const getLastCreatedLeagueGameDay = createSelector(getLeaguesGameDay, (leagues) =>
	last(leagues)
);
export const getIsLeagueGameDayOwner = createSelector(
	getLeaguesGameDay,
	getUser,
	(leagues, user) => {
		return memoize((id: number = 0) => {
			const league = leagues.find((item) => item.id === id);
			if (!league) {
				return false;
			}
			return league.user_id === user?.id;
		});
	}
);
export const getLeagueGameDayById = createSelector(getLeaguesGameDay, (leagues) =>
	memoize((id: number = 0) => find(leagues, {id}))
);

export const getLeagueGameDayRequestStateByKey = createSelector(getState, (state) => {
	return memoize((key: keyof ILeagueReducer["leagueRequests"]) => {
		return {
			isIdle: state.leagueRequests[key] === RequestStateType.Idle,
			isLoading: state.leagueRequests[key] === RequestStateType.Loading,
			isSuccess: state.leagueRequests[key] === RequestStateType.Success,
			isError: state.leagueRequests[key] === RequestStateType.Error,
		};
	});
});

export const getIsLeagueGameDayCreated = createSelector(getState, (state) => state.isLeagueCreated);

export const getJoinLeaguesGameDayState = createSelector(
	getState,
	(state) => state.joinLeagues as IApiFetchJoinLeagueGameDayClearResponse
);

export const getLeagueGameDayUsers = createSelector(getState, (state) => ({
	users: state.leagueUsers,
	nextPage: state.leagueUsersNewPage,
}));

export const getLeagueGameDayTableData = createSelector(getState, (state) => state.leagueTableData);
