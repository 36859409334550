import {get, isString} from "lodash";
import {SagaIterator} from "redux-saga";
import {call, put} from "redux-saga/effects";
import {IError, ISagaAction} from "modules/types";
import {ApiError} from "modules/utils/Api";
import {userLogoutAndClearRequest, showGlobalError} from "modules/actions";

const defaultErrorHandler = (payload: IError) => {
	if (isString(payload.message)) {
		throw new Error(payload.message);
	}
	return null;
};

const onError = {
	*[ApiError.AUTH_REQUIRED](): SagaIterator {
		yield put(userLogoutAndClearRequest());
		yield call(defaultErrorHandler, {message: "Session expired. Please log in again"});
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<IError>): SagaIterator {
	const error_code = Number(get(payload, "code"));

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield call(onErrorHandler, payload);
	} catch (err) {
		yield put(
			showGlobalError({
				message: (err as IError)?.message,
				code: error_code || 0,
			})
		);
	}
};
