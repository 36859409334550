import {createReducer} from "redux-act";
import {INotification, IShareDataReducer} from "modules/types/reducers";
import {
	fetchAllTeamsStatsSuccess,
	hideNotification,
	setLanguage,
	setProject,
	setSport,
	setTheme,
	showNotification,
} from "modules/actions";
import {Theme} from "@mui/material";
import {ProjectType, SportType} from "modules/types/enums";
import {ClassicMSLTheme} from "assets/MSL/classic_theme";

const defaultState: IShareDataReducer = {
	sport: SportType.MSL,
	project: ProjectType.Classic,
	language: "en",
	notification: null,
	teamsStats: {},
	appTheme: ClassicMSLTheme,
};

const setSportHandler = (state: IShareDataReducer, payload: SportType): IShareDataReducer => ({
	...state,
	sport: payload,
});

const setProjectHandler = (state: IShareDataReducer, payload: ProjectType): IShareDataReducer => ({
	...state,
	project: payload,
});

const setLanguageHandler = (state: IShareDataReducer, payload: string): IShareDataReducer => ({
	...state,
	language: payload,
});

const onShowNotification = (
	state: IShareDataReducer,
	payload: INotification
): IShareDataReducer => ({
	...state,
	notification: {
		type: payload.type ?? "success",
		duration: payload.duration || 3000,
		message: payload.message,
	},
});

const onHideNotification = (state: IShareDataReducer): IShareDataReducer => ({
	...state,
	notification: null,
});

const onFetchAllTeamsStatsSuccess = (
	state: IShareDataReducer,
	payload: IShareDataReducer["teamsStats"]
): IShareDataReducer => ({
	...state,
	teamsStats: payload,
});

const onSetTheme = (state: IShareDataReducer, payload: Theme): IShareDataReducer => ({
	...state,
	appTheme: payload,
});

export const shared = createReducer<IShareDataReducer>({}, defaultState)
	.on(setSport, setSportHandler)
	.on(setProject, setProjectHandler)
	.on(setLanguage, setLanguageHandler)
	.on(showNotification, onShowNotification)
	.on(hideNotification, onHideNotification)
	.on(fetchAllTeamsStatsSuccess, onFetchAllTeamsStatsSuccess)
	.on(setTheme, onSetTheme);
