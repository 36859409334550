import React from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {PlayersPoolCell, PlayersPoolThead} from "./styled";
import {PlayerPollCell} from "components/Team/PlayerPoll/PlayerPollCell";
import {ISortBy} from "modules/types/team";
import {Exist} from "components/Exist";
import {isClassicGame} from "modules/selectors";

const FirstHeadCell = styled(PlayersPoolCell)`
	@media (max-width: 1000px) {
		justify-content: flex-start;
		padding-left: 40px;
	}
`;

interface IProps {
	playersLength: number;
	sortBy: ISortBy;
	updateSortOnCLick: (e: React.SyntheticEvent<HTMLElement>) => void;
}

export const PlayerPollHeader: React.FC<IProps> = ({playersLength, sortBy, updateSortOnCLick}) => {
	const hasNoPlayers = playersLength === 0;
	const isClassic = useSelector(isClassicGame);

	if (hasNoPlayers) {
		return <EmptyMessage>No players...</EmptyMessage>;
	}

	return (
		<PlayersPoolThead>
			<FirstHeadCell className={"max-width center-align"}>Players</FirstHeadCell>
			<PlayersPoolCell>Teams</PlayersPoolCell>
			<Exist when={isClassic}>
				<PlayerPollCell
					sortBy={sortBy}
					sortProperty={"cost"}
					title={"RM"}
					updateSortOnCLick={updateSortOnCLick}
					tooltipId={"player-pool-tooltip-value"}
					tooltip={"Player Value"}
				/>
			</Exist>
			<PlayerPollCell
				sortBy={sortBy}
				sortProperty={"stats.total_points"}
				title={"Pts"}
				updateSortOnCLick={updateSortOnCLick}
				tooltipId={"player-pool-tooltip-points"}
				tooltip={"Total Points"}
			/>
			<PlayerPollCell
				sortBy={sortBy}
				sortProperty={"stats.picked_by"}
				title={"Pk %"}
				updateSortOnCLick={updateSortOnCLick}
				tooltipId={"player-pool-tooltip-picked"}
				tooltip="pick"
			/>
			<PlayersPoolCell>&nbsp;</PlayersPoolCell>
		</PlayersPoolThead>
	);
};

const EmptyMessage = styled.p`
	padding: 10px;
	font-size: 14px;
	line-height: 17px;
`;
