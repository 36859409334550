import React from "react";
import styled from "styled-components";
import FacebookIcon from "assets/images/Facebook.svg";
import TwitterIcon from "assets/images/Twitter.svg";
import GeniusIcon from "assets/images/Genius.svg";
import {useLocation} from "react-router-dom";
import {media} from "assets/media";
import {Container} from "components/styled";
import {NO_HEADER_PATHS} from "modules/constants";
import landingLogo from "assets/images/icons/LogoBlue.png";
import {FooterSportLink} from "components/Footer/FooterSportLink";

const FooterWrapper = styled.footer`
	border-top: 4px solid ${({theme}) => theme.footer.border_color};
	background: ${({theme}) => theme.footer.background};
	color: ${({theme}) => theme.footer.color};
	padding: 24px;
`;

const InnerWrapper = styled(Container)`
	display: flex;
	flex-direction: column;
`;

const LogosWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	@media screen and (max-width: ${media.sm}) {
		flex-direction: column;
		gap: 24px;
	}
`;

const FooterNote = styled.p`
	font-size: 12px;
	letter-spacing: 0;
	line-height: 18px;

	@media screen and (max-width: ${media.sm}) {
		text-align: center;
	}
`;

const SocialIcon = styled.a`
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
	}
`;

const GeniusIconWrap = styled(SocialIcon)`
	height: 33px;
	width: 100px;
`;

const BottomWrapper = styled(LogosWrapper)`
	margin: 12px 0;

	div:first-of-type {
		flex-direction: column;
		align-items: flex-start;
		gap: 0;

		a {
			color: #dddddd;
			font-size: 12px;
			letter-spacing: 0;
			line-height: 18px;
			text-decoration: none;

			&:hover {
				color: ${({theme}) => theme.text.white};
			}
		}

		@media screen and (max-width: ${media.sm}) {
			align-items: center;
		}
	}
`;

const MockLogo = styled.a`
	height: 80px;

	img {
		height: 100%;
		width: auto;
	}
`;

const MockLogoSmall = styled(MockLogo)`
	width: 40px;
	height: 40px;
`;

const Copyright = styled.p`
	color: #dddddd;
	font-size: 12px;
	letter-spacing: 0;
	margin-bottom: 0;

	@media screen and (max-width: ${media.sm}) {
		text-align: center;
	}
`;

export const Footer: React.FC = () => {
	const year = new Date().getFullYear();
	const location = useLocation();

	if (NO_HEADER_PATHS.includes(location.pathname)) {
		return null;
	}

	return (
		<FooterWrapper>
			<InnerWrapper>
				<LogosWrapper>
					<div>
						<MockLogo href="https://www.malaysianfootballleague.com/">
							<img src={landingLogo} alt="logo" />
						</MockLogo>
					</div>
					<div>
						<SocialIcon href="https://twitter.com/MFL_MY">
							<img src={TwitterIcon} alt="TwitterIcon" />
						</SocialIcon>
						<SocialIcon href="https://www.facebook.com/malaysianfootballleague/">
							<img src={FacebookIcon} alt="FacebookIcon" />
						</SocialIcon>
					</div>
				</LogosWrapper>
				<FooterNote />
				<BottomWrapper>
					<div>
						<FooterSportLink to="/help/terms">Terms & Conditions</FooterSportLink>
						<FooterSportLink to="/help/contact-us">Contact Us</FooterSportLink>
					</div>
					<div>
						<MockLogoSmall />
					</div>
					<div>
						<GeniusIconWrap
							href="https://geniussports.com/"
							target="_blank"
							rel="noopener noreferrer">
							<img src={GeniusIcon} alt="Genius Sports" />
						</GeniusIconWrap>
					</div>
				</BottomWrapper>
				<Copyright>© Malaysian Football League {year}</Copyright>
			</InnerWrapper>
		</FooterWrapper>
	);
};
