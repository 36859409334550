import {createSelector} from "reselect";
import {
	chain,
	filter,
	get,
	groupBy,
	identity,
	indexOf,
	lte,
	merge,
	range,
	reduce,
	set,
	size,
	some,
} from "lodash";
import {IDictionary, ILineup, IStore, Nullable} from "modules/types";
import {
	getAvailableFormation,
	getPlayersById,
	getTeam,
	hasEmptySpot,
	getSalaryCup,
} from "modules/selectors";
import {IPlayer} from "modules/types/json";
import {FORMATIONS} from "modules/utils/Team";

const getTeamState = ({teams}: IStore) => teams;

export const getTransfersLimitValues = createSelector(
	getTeamState,
	getTeam,
	({tradesData}, team) => {
		const tradeLeft = tradesData.trades[String(team?.id)];

		return {
			weekly_total: team.weeklyLeft || 0,
			weekly_spent: tradeLeft?.weekly,
			season_total: team.seasonLeft || 0,
			season_spent: tradeLeft?.season,
		};

		// return {
		// 	weekly_total: WEEK_TRANSFERS,
		// 	weekly_spent: WEEK_TRANSFERS - (WEEK_TRANSFERS  - (team.weeklyLeft || 0)),
		// 	season_total: SEASON_TRANSFERS,
		// 	season_spent: SEASON_TRANSFERS - (S)
		// }
	}
);

export const getTrades = createSelector(getTeamState, (state) => ({
	tradeIn: state.tradesData.tradeIn,
	tradeOut: state.tradesData.tradeOut,
}));

export const getWeeklyTradesLeftAmount = createSelector(
	getTransfersLimitValues,
	({weekly_total, weekly_spent}) => {
		if (weekly_total === null) {
			return null;
		}

		return weekly_total - (weekly_spent || 0);
	}
);

export const isReachedTransferOutLimit = createSelector(
	getWeeklyTradesLeftAmount,
	getTeamState,
	(tradesLeft, team) => {
		return Number(team.team.weeklyLeft) <= size(team.tradesData.tradeOut);
	}
);

const returnTradePairsRange = (
	tradesLeft: Nullable<number>,
	tradeOut: number[],
	tradeIn: number[]
) => {
	if (tradesLeft === null) {
		tradesLeft = 25;
	}

	return reduce<number, number[][]>(
		range(0, Number(tradesLeft)),
		(acc, tradeIndex) => {
			const trade = [tradeOut[tradeIndex], tradeIn[tradeIndex]];

			if (some(trade, identity)) {
				acc.push(trade);
			}

			return acc;
		},
		[]
	);
};

export const getTradePairs = createSelector(
	getTeamState,
	getWeeklyTradesLeftAmount,
	({tradesData}, tradesLeft) => {
		return returnTradePairsRange(tradesLeft, tradesData.tradeOut, tradesData.tradeIn);
	}
);

const sortPlayersByPosition = (a: IPlayer, b: IPlayer) => {
	if (!a || !b) {
		return 0;
	}
	if (a.position === b.position) {
		return 0;
	}

	return a.position > b.position ? 1 : -1;
};

export const getTradePairsAccordingPosition = createSelector(
	getTeamState,
	getPlayersById,
	getWeeklyTradesLeftAmount,
	({tradesData}, players, tradesLeft) => {
		const tradeOutPlayersData = tradesData.tradeOut
			.map((playerId) => players[playerId])
			.sort(sortPlayersByPosition)
			.map((player) => player?.id);

		// check tradeIn by length index of tradeout
		const tradeInPlayersData = tradesData.tradeOut
			.map((playerId, index) => players[tradesData.tradeIn[index]])
			.sort(sortPlayersByPosition)
			.map((player) => player?.id);

		return returnTradePairsRange(tradesLeft, tradeOutPlayersData, tradeInPlayersData);
	}
);

export const getTradesLength = createSelector(getTradePairs, (trades) => trades.length);

export const getTransfersLeft = createSelector(
	getTeam,
	getTradePairs,
	({weeklyLeft, seasonLeft}, trades) => {
		const tradesAmount = size(trades);

		return {
			weeklyLeft: weeklyLeft ? weeklyLeft - tradesAmount : 0,
			seasonLeft: seasonLeft ? seasonLeft - tradesAmount : 0,
		};
	}
);

export const isTransfersLeftLimit = createSelector(getTransfersLeft, ({weeklyLeft, seasonLeft}) => {
	return weeklyLeft === 0 || seasonLeft === 0;
});

const getLineupValueHelper = (lineup: ILineup, playersByID: IDictionary<IPlayer>) => {
	// Remove captain and vice_captain as they affect calculations
	const {captain, vice_captain, ...rest} = lineup;
	return chain(rest)
		.values()
		.flatten()
		.filter(identity)
		.value()
		.reduce((acc, id) => {
			acc += get(playersByID[id], "cost", 0);
			return acc;
		}, 0);
};

interface IReplacePlayerInTeamPayload {
	playersByID: IDictionary<IPlayer>;
	lineup: ILineup;
	replaceFrom?: number;
	replaceTo?: number;
}

const findAndReplaceInTeamHelper = ({
	playersByID,
	lineup,
	replaceFrom = 0,
	replaceTo = 0,
}: IReplacePlayerInTeamPayload) => {
	const playerID = replaceFrom || replaceTo;

	if (!playerID) {
		return lineup;
	}

	const player: IPlayer = playersByID[playerID];
	const {position} = player;
	const line = [...lineup[position]];

	return {
		...lineup,
		[position]: set(line, indexOf(line, replaceFrom), replaceTo),
	};
};

interface ITransformFormationPayload {
	lineup: ILineup;
	formation?: ReturnType<typeof getAvailableFormation>;
}

const transformFormationHelper = ({lineup, formation}: ITransformFormationPayload) => {
	if (!formation) {
		return lineup;
	}

	return reduce<ILineup, ILineup>(
		FORMATIONS.get(formation),
		(acc, newLine, key) => {
			const currentLine = lineup[key];
			const currentLineWithPlayers = filter(currentLine, identity);
			const emptyLineSize = size(newLine);
			const playersLineSize = size(currentLineWithPlayers);
			const filledPositionsDiff = playersLineSize - emptyLineSize;

			if (lte(filledPositionsDiff, 0)) {
				acc[key] = merge([], newLine, currentLineWithPlayers);
			}

			return acc;
		},
		{}
	);
};

export const getResetTransferDataSelector = createSelector(
	getTradePairsAccordingPosition,
	getPlayersById,
	getTeam,
	getSalaryCup,
	(trades, playersByID, team, salaryCup) => (resetTradeIndex: number) => {
		const [tradeOut, tradeIn] = trades[resetTradeIndex];

		let lineup = {...team.lineup};
		let formation = team.formation;

		lineup = findAndReplaceInTeamHelper({
			playersByID,
			lineup,
			replaceFrom: tradeIn,
			replaceTo: 0,
		});

		const returnToTeamPlayer: IPlayer = playersByID[tradeOut];
		if (!returnToTeamPlayer) {
			return false;
		}
		const position = returnToTeamPlayer.position;

		if (!hasEmptySpot(lineup, position)) {
			formation = getAvailableFormation(lineup, position)!;

			if (!formation) {
				return false;
			}

			lineup = transformFormationHelper({lineup, formation});
		}

		lineup = findAndReplaceInTeamHelper({
			playersByID,
			lineup,
			replaceFrom: 0,
			replaceTo: tradeOut,
		});

		const teamValue = getLineupValueHelper(lineup, playersByID);

		if (salaryCup - teamValue < 0) {
			return false;
		}

		return {formation, lineup, tradeIn, tradeOut};
	}
);

export const getTransfersHistory = (state: IStore) => state.tradeHistory.trades;

export const getTransfersHistoryByRound = createSelector(getTransfersHistory, (trades) =>
	groupBy(trades, "round")
);

export const getTotalWeekTransfersLimit = createSelector(getTeam, (team) => team.weeklyLimit);
