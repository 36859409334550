import {Input} from "components/styled";
import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	getIsActualRoundLocked,
	getPlayersAccordingLineUp,
	getTeam,
	getTeamHeads,
} from "modules/selectors";
import {MenuItem} from "@mui/material";
import {setHeads} from "modules/actions";
import {ITeamHeads} from "modules/types";
import {getDisplayName} from "modules/utils/players";

const Wrapper = styled.div`
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-left: 20px;
	gap: 20px;
	min-width: 310px;

	${Input} {
		max-width: 145px;
		width: 100%;

		.MuiInputLabel-root:not([data-shrink="true"]) {
			top: -6px !important;
			font-size: 14px;
			color: #222222 !important;
		}

		.MuiOutlinedInput-input {
			padding-top: 8px;
			padding-bottom: 10px;
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: #dddddd !important;
		}
	}

	@media screen and (max-width: 750px) {
		gap: 8px;
		padding-left: 0;

		${Input} {
			max-width: unset;
		}
	}
`;

export const TeamCaptainSelect: React.FC = () => {
	const dispatch = useDispatch();
	const teamPlayers = useSelector(getPlayersAccordingLineUp);
	const team = useSelector(getTeam);
	const headState = useSelector(getTeamHeads);
	const isActualRoundLocked = useSelector(getIsActualRoundLocked);

	const captainPlayers = useMemo(() => {
		return teamPlayers.filter((player) => player?.id !== headState.viceCaptain);
	}, [teamPlayers, headState]);

	const viceCaptainPlayers = useMemo(() => {
		return teamPlayers.filter((player) => player?.id !== headState.captain);
	}, [teamPlayers, headState]);

	const onHeadSet = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const name = e.target.name as keyof ITeamHeads;
			const value = Number(e.target.value);

			if (!name) {
				return;
			}

			dispatch(
				setHeads({
					...headState,
					[name]: value,
				})
			);
		},
		[dispatch, headState]
	);

	if (isActualRoundLocked && team?.isCompleted) {
		return null;
	}

	return (
		<Wrapper>
			<Input
				select
				name="captain"
				label="Select Captain"
				onChange={onHeadSet}
				value={headState.captain}>
				<MenuItem value={0} selected={true}>
					No Captain
				</MenuItem>
				{captainPlayers.map((player, index) => (
					<MenuItem value={player?.id} key={index}>
						{getDisplayName(player)}
					</MenuItem>
				))}
			</Input>
			<Input
				select
				name="viceCaptain"
				label="Select Vice Captain"
				onChange={onHeadSet}
				value={headState.viceCaptain}>
				<MenuItem value={0} selected={true}>
					No Vice captain
				</MenuItem>
				{/* This functions have to be identical, if wrap in common component - MUI select doesn't work */}
				{/* eslint-disable-next-line sonarjs/no-identical-functions */}
				{viceCaptainPlayers.map((player, index) => (
					<MenuItem value={player?.id} key={index}>
						{getDisplayName(player)}
					</MenuItem>
				))}
			</Input>
		</Wrapper>
	);
};
