import React, {useCallback} from "react";
import {
	getIsActualRoundLocked,
	getIsTeamComplete,
	getIsTeamHeadsFull,
	getIsTeamLockout,
	getTeam,
	getTeamEmptyLineup,
	isClassicGame,
	isGameDayGame,
} from "modules/selectors";
import {useDispatch, useSelector} from "react-redux";
import {flatMap} from "lodash";
import {
	autoFillGameDayRequest,
	autoFillRequest,
	clearHeads,
	editModalSavingMode,
	removePlayerFromTeam,
	saveTeam,
	saveTeamGameDay,
	toggleEditTeamNameModal,
	toggleNoHeadsModal,
} from "modules/actions";
import {Exist} from "components/Exist";
import {useIsPathIncludes} from "modules/utils/hooks";
import {NonCompleteTeamButtons} from "./NonCompleteTeamButtons";
import {LockoutButtons} from "components/Team/BottomStatusBar/LockoutButtons";
import {NonLockoutButtons} from "components/Team/BottomStatusBar/NonLockoutButtons";
import {TransferButtons} from "./TransferButtons";
import {allTrue} from "modules/utils";
import {GameDayBottomStatusBar} from "components/Team/GameDayBottomStatusBar";
import {
	StatusBarButtonsBlock,
	StatusBarInnerContainer,
	StatusBarSelectedBlock,
	StatusBarSmallContainer,
	StatusBarWrapper,
} from "components/Team/styled";

export const BottomStatusBar: React.FC = () => {
	const isClassic = useSelector(isClassicGame);

	return isClassic ? <ClassicBottomStatusBar /> : <GameDayBottomStatusBar />;
};

const ClassicBottomStatusBar = () => {
	const lineup = useSelector(getTeamEmptyLineup);
	const flatLineUp = flatMap(lineup);
	const total = flatLineUp.length;
	const selected = flatLineUp.filter((item) => item !== 0).length;
	const isTeamComplete = useSelector(getIsTeamComplete);
	return (
		<StatusBarWrapper>
			<StatusBarInnerContainer>
				<StatusBarSmallContainer>
					<StatusBarSelectedBlock>
						<p>Selected</p>
						<p>
							<b>
								{selected} / {total}
							</b>
						</p>
					</StatusBarSelectedBlock>
					<StatusBarButtonsBlock>
						<Exist when={!isTeamComplete}>
							<NonCompleteTeamButtons />
						</Exist>
						<Exist when={isTeamComplete}>
							<TeamSaveButtons />
							<TransferButtons />
						</Exist>
					</StatusBarButtonsBlock>
				</StatusBarSmallContainer>
			</StatusBarInnerContainer>
		</StatusBarWrapper>
	);
};

const TeamSaveButtons: React.FC = () => {
	const dispatch = useDispatch();
	const isGameDay = useSelector(isGameDayGame);
	const isLockout = useSelector(getIsTeamLockout);
	const isHeadsFull = useSelector(getIsTeamHeadsFull);
	const isTransferPage = useIsPathIncludes("transfers");
	const lineup = useSelector(getTeamEmptyLineup);
	const flatLineUp = flatMap(lineup);
	const team = useSelector(getTeam);
	const isActualRoundLocked = useSelector(getIsActualRoundLocked);

	const clearTeam = useCallback(() => {
		flatLineUp.forEach((playerId) => {
			dispatch(removePlayerFromTeam(playerId));
			dispatch(clearHeads());
		});
	}, [flatLineUp, dispatch]);

	const autoFillHandler = useCallback(() => {
		dispatch(isGameDay ? autoFillGameDayRequest() : autoFillRequest());
	}, [dispatch, isGameDay]);

	const saveTeamHandle = useCallback(() => {
		if (isGameDay) {
			dispatch(saveTeamGameDay());
		} else {
			dispatch(editModalSavingMode(true));
			if (allTrue([!isHeadsFull, !isActualRoundLocked])) {
				dispatch(toggleNoHeadsModal(true));
				return;
			}
			if (!team?.name) {
				dispatch(toggleEditTeamNameModal(true));
				return;
			}
			dispatch(saveTeam());
		}
	}, [team, dispatch, isHeadsFull, isActualRoundLocked, isGameDay]);

	if (isTransferPage) {
		return null;
	}

	if (!isLockout) {
		return (
			<NonLockoutButtons
				saveTeamHandle={saveTeamHandle}
				clearTeam={clearTeam}
				autoFillHandler={autoFillHandler}
			/>
		);
	}
	return (
		<LockoutButtons
			saveTeamHandle={saveTeamHandle}
			clearTeam={clearTeam}
			autoFillHandler={autoFillHandler}
		/>
	);
};
