import React, {useCallback} from "react";
import styled from "styled-components";
import {PureButton} from "components/styled";
import {StatsTab} from "modules/types/enums";
import {setActiveTabAction} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getIsTeamLockoutInner, getProject, getShownStats, getSport} from "modules/selectors";
import {NavLink} from "react-router-dom";
import {Exist} from "components/Exist";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	margin-bottom: -19px;
`;

const TeamTab = styled(PureButton)`
	min-width: 120px;
	display: flex;
	color: ${({theme}) => theme.text_color};
	padding: 14px 12px;
	font-size: 14px;
	letter-spacing: 0;
	text-align: center;
	font-weight: 400;
	border: 1px solid transparent;

	&.active {
		border-color: #d8d8d8;
		border-top: 5px solid ${({theme}) => theme.field.tab_color};
		border-radius: 5px 5px 0 0;
	}

	&:disabled {
		color: #dddddd;
		pointer-events: none;
	}
	text-decoration: none;
`;

export const TeamTabs: React.FC = () => {
	const dispatch = useDispatch();
	const activeTab = useSelector(getShownStats);
	const isStarted = useSelector(getIsTeamLockoutInner);

	const sport = useSelector(getSport);
	const project = useSelector(getProject);

	const changeTab = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const tab = e.currentTarget.dataset.tab;
			if (tab === undefined) {
				return;
			}
			dispatch(setActiveTabAction(tab as unknown as StatsTab));
		},
		[dispatch]
	);

	const isTabActive = (tab: StatsTab): string => (tab === activeTab ? "active" : "");

	return (
		<Wrapper>
			<TeamTab
				onClick={changeTab}
				data-tab={StatsTab.Weekly}
				className={isTabActive(StatsTab.Weekly)}>
				Weekly/Overall Points
			</TeamTab>
			<TeamTab
				onClick={changeTab}
				data-tab={StatsTab.TransferValue}
				className={isTabActive(StatsTab.TransferValue)}>
				Transfer Value
			</TeamTab>
			<Exist when={isStarted}>
				<TeamTab as={NavLink} to={`/${project}/${sport}/team/transfers/history`}>
					Transfers History
				</TeamTab>
			</Exist>
		</Wrapper>
	);
};
