import styled from "styled-components";
import {media} from "assets/media";

export const PageTitle = styled.h1`
	color: ${({theme}) => theme.text_color};
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 29px;
`;

export const LeagueStatsBlock = styled.div`
	h3 {
		text-transform: capitalize;
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin: 0 0 5px 0;
		font-weight: 400;
	}

	p {
		text-transform: capitalize;
		color: #808080;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 18px;
		margin: 0 0 15px 0;
		font-weight: 300;
	}

	@media screen and ${media.lessThanMD} {
		h3 {
			font-size: 14px;
		}

		p {
			font-size: 14px;
		}
	}
`;

export const LoadMoreWrapper = styled.div`
	padding: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	button {
		max-width: 120px;
		width: 100%;
	}

	@media screen and ${media.lessThanSM} {
		button {
			max-width: unset;
		}
	}
`;
