import React, {useCallback, useEffect} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
	ModalHeaderTitle,
	ModalText,
} from "components";
import {IconRemove} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {SecondaryButton, WarningButton} from "components/styled";
import {getLeaveLeagueModal} from "modules/selectors";
import warningImage from "assets/images/icons/warning.png";
import {closeLeagueLeaveModal} from "modules/actions/modals";
import {useParams} from "react-router-dom";
import {leaveLeagueGameDayRequest} from "modules/actions";

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		margin: 18px 0 24px 0;
	}

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin-top: 24px;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin-bottom: 30px;
	}
`;

// const SModalActions = styled(ModalActions)`
// 	button {
// 		margin: 0 !important;
// 	}
// `;

export const LeaveLeagueGameDayModal: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const leagueId = params.id;
	const isModalOpen = useSelector(getLeaveLeagueModal);

	const closeModal = useCallback(() => {
		dispatch(closeLeagueLeaveModal());
	}, [dispatch]);

	const leave = useCallback(() => {
		closeModal();
		dispatch(leaveLeagueGameDayRequest(Number(leagueId)));
	}, [closeModal, leagueId, dispatch]);

	useEffect(() => {
		if (!leagueId) {
			closeModal();
		}
	}, [leagueId, closeModal]);

	if (!isModalOpen) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerContainer>
					<img src={warningImage} alt="warning" />
					<ModalHeaderTitle>League leave</ModalHeaderTitle>
					<ModalText>Are you sure you want leave this league?</ModalText>
					<ModalActions>
						<SecondaryButton onClick={closeModal}>Go Back</SecondaryButton>
						<WarningButton onClick={leave}>Leave</WarningButton>
					</ModalActions>
				</InnerContainer>
			</ModalContent>
		</ModalBackdrop>
	);
};
