import React, {useCallback} from "react";
import styled from "styled-components";
import {MenuItem} from "@mui/material";
import {Input} from "components/styled";
import {useSelector} from "react-redux";
import {getRoundsFromStartTillScheduled} from "modules/selectors";
import {media} from "assets/media";
import {Exist} from "components/Exist";
import {formatGameWeek} from "modules/utils";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;

	padding: 0 0 30px 0;

	${Input} {
		max-width: 300px;
	}

	@media screen and ${media.lessThanSM} {
		flex-direction: column;

		${Input} {
			max-width: unset;
			width: 100%;
		}
	}
`;

interface IProps {
	leagueStartRound: number;
	round: number;
	setRound: (round: number) => void;
}

export const LeagueTableFilters: React.FC<IProps> = ({leagueStartRound, round, setRound}) => {
	const rounds = useSelector(getRoundsFromStartTillScheduled)(leagueStartRound);
	const onRoundChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setRound(Number(e.target.value));
		},
		[setRound]
	);

	return (
		<Wrapper>
			<Exist when={rounds.length > 0}>
				<Input select name="round" value={round} onChange={onRoundChange}>
					<MenuItem value="0" selected={true}>
						Overall
					</MenuItem>
					{rounds.map((round) => (
						<MenuItem key={round.id} value={round.id}>
							Game Week {formatGameWeek(round.number)}
						</MenuItem>
					))}
				</Input>
			</Exist>
		</Wrapper>
	);
};
