import React, {useEffect} from "react";
import {LandingHeader} from "components/Header/LandingHeader";
import {AppHeader} from "components/Header/AppHeader";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {LANDING_PATH, NO_HEADER_PATHS} from "modules/constants";
import {AlertBanner} from "components/AlertBanner";
import {Exist} from "components/Exist";
import {useDispatch, useSelector} from "react-redux";
import {fetchNotificationBar} from "modules/actions/jsons";
import {getNotificationBar} from "modules/selectors/json";

const HeaderWrapper = styled.div`
	width: 100%;
	position: relative;
	z-index: 10;
`;

export const Header: React.FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const notificationBar = useSelector(getNotificationBar);
	const isLanding = LANDING_PATH.includes(location.pathname);
	const alertBannerEnabled = notificationBar.isEnabled || false;
	const alertBannerText = notificationBar.message || "";

	useEffect(() => {
		dispatch(fetchNotificationBar());
	}, [dispatch]);

	if (NO_HEADER_PATHS.includes(location.pathname)) {
		return null;
	}

	if (isLanding) {
		return (
			<React.Fragment>
				<Exist when={alertBannerEnabled}>
					<AlertBanner>
						<div
							//eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: String(alertBannerText),
							}}
						/>
					</AlertBanner>
				</Exist>
				<HeaderWrapper>
					<LandingHeader />
				</HeaderWrapper>
			</React.Fragment>
		);
	}

	return (
		<HeaderWrapper>
			<Exist when={alertBannerEnabled}>
				<AlertBanner>
					<div
						//eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: String(alertBannerText),
						}}
					/>
				</AlertBanner>
			</Exist>
			<AppHeader />
		</HeaderWrapper>
	);
};
