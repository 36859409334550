import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	getAutoFillRequestState,
	getGameDaySelectedTeam,
	getTeamLineupGameDay,
	getTeamRequestState,
	isTeamFullFilledGameDay,
} from "modules/selectors";
import {Exist} from "components/Exist";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {
	autoFillGameDayRequest,
	clearHeads,
	editModalSavingMode,
	removePlayerFromTeam,
	saveTeamGameDay,
	toggleEditTeamNameModal,
} from "modules/actions";
import {flatMap} from "lodash";

export const NonCompleteTeamButtons: React.FC = () => {
	const dispatch = useDispatch();
	const autofillState = useSelector(getAutoFillRequestState);
	const saveTeamRequestState = useSelector(getTeamRequestState)("saveTeam");
	const isTeamFull = useSelector(isTeamFullFilledGameDay);
	const lineup = useSelector(getTeamLineupGameDay);
	const flatLineUp = flatMap(lineup);
	const team = useSelector(getGameDaySelectedTeam);
	const isSaveDisabled = !isTeamFull || saveTeamRequestState.isLoading;
	const isAutoDisabled = autofillState.isLoading || saveTeamRequestState.isLoading;

	const clearTeam = useCallback(() => {
		flatLineUp.forEach((playerId) => {
			dispatch(removePlayerFromTeam(playerId));
			dispatch(clearHeads());
		});
	}, [flatLineUp, dispatch]);

	const autoFillHandler = useCallback(() => {
		dispatch(autoFillGameDayRequest());
	}, [dispatch]);

	const saveTeamHandle = useCallback(() => {
		dispatch(editModalSavingMode(true));
		if (!team?.name) {
			dispatch(toggleEditTeamNameModal(true));
			return;
		}
		dispatch(saveTeamGameDay());
	}, [team, dispatch]);

	return (
		<React.Fragment>
			<Exist when={isTeamFull}>
				<SecondaryButton onClick={clearTeam} disabled={isAutoDisabled}>
					Clear All
				</SecondaryButton>
			</Exist>

			<Exist when={!isTeamFull}>
				<SecondaryButton onClick={autoFillHandler} disabled={isAutoDisabled}>
					Auto fill
				</SecondaryButton>
			</Exist>

			<PrimaryButton disabled={isSaveDisabled} onClick={saveTeamHandle}>
				Save
			</PrimaryButton>
		</React.Fragment>
	);
};
