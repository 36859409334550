import React, {useEffect} from "react";
import {setProject, setSport} from "modules/actions";
import {ProjectType, SportType} from "modules/types/enums";
import {useDispatch} from "react-redux";

interface IProps {
	sport: SportType;
	project: ProjectType;
}

export const HOCSportSelect: React.FC<IProps> = ({sport, project, children}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setSport(sport));
		dispatch(setProject(project));
	}, [dispatch, sport, project]);

	return <React.Fragment>{children}</React.Fragment>;
};
