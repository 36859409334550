import {chain, filter, get, isEmpty, memoize, reduce} from "lodash";
import {
	getActiveMatches,
	getMatchesById,
	getPlayersById,
	getPublicTeam,
	getScoreRound,
	getTeam,
	getTeamLineupIDs,
	isPreseason,
} from "modules/selectors";
import {MatchStatus} from "modules/types/enums";
import {createSelector} from "reselect";
import {IPlayer} from "modules/types/json";

const getSelectedRoundId = (startRound?: number, roundID?: number, scoreRoundID?: number) => {
	return startRound || roundID || scoreRoundID || 1;
};

const getDefaultPlayerScore = ({
	player,
	selectedRoundID,
}: {
	player: IPlayer;
	selectedRoundID: number;
}) => {
	if (player?.stats?.round_scores) {
		return (
			Object.keys(player.stats.round_scores)
				.map((value) => value)
				.filter((item) => Number(item) >= selectedRoundID)
				.reduce((acc, key) => acc + Number(player.stats.round_scores![key]) || 0, 0) || "-"
		);
	}
	return get(player, `stats.round_scores[${selectedRoundID}]`, "-") as number | string;
};

export const getPlayerGameWeekScores = createSelector(
	isPreseason,
	getScoreRound,
	getPlayersById,
	getTeam,
	getPublicTeam,
	getMatchesById,
	(isPreseasonState, round, playersById, team, publicTeam, matchesByID) =>
		memoize((playerId: number, roundID?: number, isPublic: boolean = false) => {
			if (isPreseasonState) {
				return;
			}

			const player = playersById[playerId];
			const scoreRoundID = get(round, "id", 1);
			const playerTeam = isPublic ? publicTeam : team;
			const selectedRoundID = getSelectedRoundId(
				Number(playerTeam?.startRound),
				roundID,
				scoreRoundID
			);
			const customScoringForRound: Record<number, number> = get(
				playerTeam,
				`custom_scoring[${selectedRoundID}]`,
				{}
			) as Record<string, number>;
			const hasNoCustomScoring =
				isEmpty(customScoringForRound) || !(playerId in customScoringForRound);

			if (hasNoCustomScoring) {
				return getDefaultPlayerScore({player, selectedRoundID});
			}

			const playerScores = filter(
				// Disabled for push, unused code for now
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				customScoringForRound[playerId],
				(matchID) => matchesByID[matchID]?.status !== MatchStatus.Scheduled
			);

			if (isEmpty(playerScores)) {
				return "-";
			}

			return reduce(
				playerScores,
				(acc, matchID) => {
					acc += Number(player?.stats?.match_scores?.[matchID]) || 0;
					return acc;
				},
				0
			);
		})
);

export const getScoringPlayersIDs = createSelector(
	getTeamLineupIDs,
	getActiveMatches,
	getPlayersById,
	getPlayerGameWeekScores,
	(lineupIDs, activeMatches, playersById, getPlayerScoreSelector) => {
		const playingTeams = chain(activeMatches)
			.map(({home_squad_id, away_squad_id}) => [home_squad_id, away_squad_id])
			.flatten()
			.uniq()
			.value();

		return lineupIDs.filter((playerID) => {
			const player = playersById[playerID];

			if (player) {
				return playingTeams.includes(player.squadId);
			}
			return false;
		});
	}
);

export const isPlayerPlaying = createSelector(getScoringPlayersIDs, (playingPlayersIdList) =>
	memoize((playerID: number = 0) => playingPlayersIdList.includes(playerID))
);
