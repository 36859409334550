import React from "react";
import {useSelector} from "react-redux";
import {getActualRound} from "modules/selectors";
import {toString, range, eq} from "lodash";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {formatGameWeek} from "modules/utils";

interface IRoundSelector {
	roundId: number;
	onChange: (e: SelectChangeEvent) => void;
}

export const RoundSelector: React.FC<IRoundSelector> = ({roundId, onChange}) => {
	const actualRound = useSelector(getActualRound);
	const endRoundID = (actualRound?.id || 1) + 1;

	return (
		<Select onChange={onChange} value={toString(roundId)}>
			<MenuItem value={0} aria-selected={!roundId}>
				All Game Weeks
			</MenuItem>
			{range(1, endRoundID).map((round) => (
				<MenuItem key={round} value={round} aria-selected={eq(round, roundId)}>
					Game Week {formatGameWeek(round)}
				</MenuItem>
			))}
		</Select>
	);
};
