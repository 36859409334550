import React from "react";
import {useSelector} from "react-redux";
import {ThemeProvider as MUIThemeProvider} from '@mui/material';
import {DefaultTheme, ThemeProvider} from "styled-components";
import {getTheme} from "modules/selectors";

export const HOCThemeAdapter: React.FC = ({children}) => {
	const theme = useSelector(getTheme);
	return (
		<ThemeProvider theme={theme as unknown as DefaultTheme}>
			<MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
		</ThemeProvider>
	);
};
