import {IDictionary, IStore} from "modules/types";
import {createSelector} from "reselect";
import {MatchStatus, RoundStatus} from "modules/types/enums";
import {filter, find, findLast, first, flatMap, keyBy, last, memoize, property} from "lodash";
import {IMatch} from "modules/types/json";

const getState = ({round}: IStore) => round;

export const getRounds = createSelector(getState, (state) => state.rounds);

export const getRoundsById = createSelector(getRounds, (rounds) => keyBy(rounds, "id"));

export const getActualRound = createSelector(getRounds, (rounds) =>
	rounds.find(({status}) => [RoundStatus.Active, RoundStatus.Scheduled].includes(status))
);

export const getIsActualRoundLocked = createSelector(getActualRound, (round) => {
	if (!round) {
		return true;
	}

	return round.status !== RoundStatus.Scheduled;
});

export const getIsActualRoundLastMatchActive = createSelector(getActualRound, (round) => {
	if (!round) {
		return false;
	}

	const lastMatch = last(round.matches);

	if (!lastMatch) {
		return false;
	}

	return lastMatch.status === MatchStatus.Active;
});

export const getRoundsFromStartTillScheduled = createSelector(getRounds, (rounds) =>
	memoize((roundId: number = 0) => {
		return rounds.filter(
			(round) => round.number >= roundId && round.status !== RoundStatus.Scheduled
		);
	})
);

export const getIsRoundLockedById = createSelector(getRounds, (rounds) =>
	memoize((id: number = 0) => {
		const round = find(rounds, {id});
		if (!round) {
			return true;
		}

		return round.status !== RoundStatus.Scheduled;
	})
);

export const getSelectedRoundState = createSelector(getState, (state) => state.selectedRound);

export const getSelectedRoundId = createSelector(
	getActualRound,
	getSelectedRoundState,
	(actualRound, reducerRound) => reducerRound || actualRound?.id
);

export const getSelectedRound = createSelector(
	getRoundsById,
	getSelectedRoundId,
	(rounds, id) => rounds[id as number]
);

export const getScoreRound = createSelector(
	getRounds,
	(rounds) =>
		findLast(rounds, ({status}) =>
			[RoundStatus.Active, RoundStatus.Complete].includes(status)
		) || first(rounds)
);

export const isPreseason = createSelector(
	getRounds,
	(rounds) => first(rounds)?.status === RoundStatus.Scheduled
);

export const getActiveMatches = createSelector(getActualRound, (actualRound) =>
	filter(actualRound?.matches, {status: MatchStatus.Active})
);

export const getIsSomeRoundActive = createSelector(getRounds, (rounds) => {
	const activeRound = rounds.find(({status}) => [RoundStatus.Active].includes(status));
	return Boolean(activeRound);
});

export const getScheduleRounds = createSelector(getRounds, (rounds) => {
	return rounds.filter((round) => round.status === RoundStatus.Scheduled);
});

export const getFirstScheduledRound = createSelector(getScheduleRounds, (rounds) => first(rounds));

export const getScheduledRoundsWithLeagueRound = createSelector(getRounds, (rounds) =>
	memoize((id: number = 0) => {
		const scheduledRounds = rounds.filter((round) => round.status === RoundStatus.Scheduled);
		const roundFromAll = find(rounds, {id});
		const round = find(scheduledRounds, {id});

		if (round || !roundFromAll) {
			return scheduledRounds;
		}

		return [roundFromAll, ...scheduledRounds];
	})
);

export const getNonCompleteRounds = createSelector(getRounds, (rounds) => {
	return rounds.filter((round) =>
		[RoundStatus.Scheduled, RoundStatus.Active].includes(round.status)
	);
});

export const getMatchesArray = createSelector(getRounds, (rounds) =>
	flatMap(rounds, ({matches}) => matches)
);

export const getMatchesById = createSelector(
	getRounds,
	(rounds): IDictionary<IMatch> => keyBy(flatMap(rounds, property("matches")), "id")
);

export const getMatchById = createSelector(
	getMatchesById,
	(matchesById) =>
		(matchId: number = 0) =>
			matchesById[matchId]
);

export const getNonScheduledRounds = createSelector(getRounds, (rounds) =>
	rounds.filter(({status}) => [RoundStatus.Complete, RoundStatus.Active].includes(status))
);
