import {ILeagueRankItem} from "modules/types";
import React from "react";

interface IProps {
	isOverall: boolean;
	rankItem: ILeagueRankItem;
}

export const LeagueTableItem: React.FC<IProps> = ({isOverall, rankItem}) => {
	const {overall_rank, overall_points, round_rank, round_points} = rankItem;
	const roundPoints = isOverall ? overall_points : round_points;
	const roundRank = isOverall ? overall_rank : round_rank;

	return (
		<tr>
			<td className="position">{roundRank || "-"}</td>
			<td>{rankItem.team_name}</td>
			<td className="total">{roundPoints ?? "-"}</td>
		</tr>
	);
};
