import {IStore} from "modules/types";
import {IUserReducer} from "modules/types/reducers";
import {createSelector} from "reselect";
import {memoize} from "lodash";
import {RequestStateType} from "modules/types/enums";

const getUserState = ({user}: IStore) => user;

export const getUser = createSelector(getUserState, (state: IUserReducer) => state.user);

export const getIsUserChecked = createSelector(
	getUserState,
	(state: IUserReducer) => state.isUserChecked
);

export const getIsUserLogged = createSelector(
	getUserState,
	(state: IUserReducer) => state.isUserLogged
);

export const getUserRequestState = createSelector(getUserState, (state) => {
	return memoize((key: keyof IUserReducer["userState"]) => {
		return {
			isIdle: state.userState[key] === RequestStateType.Idle,
			isLoading: state.userState[key] === RequestStateType.Loading,
			isSuccess: state.userState[key] === RequestStateType.Success,
			isError: state.userState[key] === RequestStateType.Error,
			isLoadingOrSuccess:
				state.userState[key] === RequestStateType.Loading ||
				state.userState[key] === RequestStateType.Success,
		};
	});
});
