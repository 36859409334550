import React from "react";
import styled from "styled-components";
import {PureButton} from "components/styled";
import {Exist} from "components/Exist";
import {useNavigate} from "react-router-dom";
import {IconChevron} from "components/Icons";
import {CircularPreloaded} from "components/Preloader";
import {media} from "assets/media";
import {useSelector} from "react-redux";
import {getProject, getSport} from "modules/selectors";

const Wrapper = styled.div`
	padding-top: 12px;

	button {
		background: transparent;
	}

	.accordion {
		padding: 12px 0;

		.faq-title {
			color: ${({theme}) => theme.text_color};
			font-size: 18px;
			letter-spacing: 0;
			line-height: 22px;
		}

		.accordion-button {
			width: 24px;
		}
	}
`;

const Title = styled(PureButton)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;

	h2 {
		color: ${({theme}) => theme.text_color};
		font-size: 28px;
		letter-spacing: 0;
		line-height: 34px;
		font-weight: normal;
		text-transform: capitalize;
		margin: 0;
		padding: 12px 0 14px 0;
	}

	@media screen and ${media.lessThanSM} {
		padding-left: 20px;
		padding-right: 20px;
		h2 {
			font-size: 16px;
			letter-spacing: 0;
			line-height: 19px;
			margin: 0;
			padding: 12px 0 11px 0;
		}
	}
`;

const Content = styled.div`
	color: ${({theme}) => theme.text_color};
	font-size: 14px;
	letter-spacing: 0;
	line-height: 18px;

	p {
		font-size: 14px;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 0;
	}

	strong {
		font-weight: bold;
	}

	h1 {
		color: ${({theme}) => theme.text_color};
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 22px;
		padding: 5px 0;
	}

	h2 {
		color: ${({theme}) => theme.text_color};
		font-size: 22px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 22px;
	}

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 22px;
	}

	h4 {
		color: ${({theme}) => theme.text_color};
		font-size: 18px;
		letter-spacing: 0;
		line-height: 22px;
		margin-bottom: 8px;
		font-weight: normal;
	}

	ul {
		list-style: inside;
	}

	figure img {
		max-width: 100%;
	}

	a {
		color: #f02f42;
	}

	@media screen and ${media.lessThanMD} {
		padding-left: 20px;
		padding-right: 20px;

		h4 {
			font-size: 16px;
			letter-spacing: 0;
			line-height: 19px;
			margin-bottom: 0;
			font-weight: normal;
		}

		p {
			font-size: 14px;
			letter-spacing: 0;
			line-height: 18px;
			margin-bottom: 10px;
		}
	}
`;

interface IProps {
	title?: string;
	isOpen: boolean;
	section: string;
	onClick?: () => void;
}

export const HOCHelpSection: React.FC<IProps> = ({title, section, isOpen, onClick, children}) => {
	const navigate = useNavigate();
	const sport = useSelector(getSport);
	const project = useSelector(getProject);

	const chevronDirection = isOpen ? "top" : "bottom";

	const onSectionClick = () => {
		const to = isOpen ? "/help" : `/help/${section}`;
		const link = `/${project}/${sport}${to}`;
		navigate(link);
		if (onClick) {
			onClick();
		}
	};

	return (
		<Wrapper>
			<Title onClick={onSectionClick}>
				<h2>{title ?? <CircularPreloaded />}</h2>
				<IconChevron width={20} height={20} to={chevronDirection} color="#000151" />
			</Title>
			<Exist when={isOpen}>
				<Content>{children ? children : <CircularPreloaded />}</Content>
			</Exist>
		</Wrapper>
	);
};
