import {createReducer} from "redux-act";
import {IPlayerReducer} from "modules/types/reducers";
import * as actions from "modules/actions/players";
import {IPlayer, IPlayerStat} from "modules/types/json";
import {IStatsCentrePlayer} from "modules/types/shared";

/**
 * Players are media names are coming in all caps
 * regex makes all lower case
 * Then uppercase for first letter
 * first letter after space
 * first letter after -
 */

const formatPlayerNames = (players: IPlayer[]) =>
	players.map((player) => {
		const mediaNameData = player.mediaName
			? {
					mediaName: player.mediaName
						.toLowerCase()
						.replace(/(^| |-)(\w)/g, (s) => s.toUpperCase()),
			  }
			: {};
		return {
			...player,
			...mediaNameData,
		};
	});

const defaultState: IPlayerReducer = {
	players: [],
	playerStats: [],
	statsCentrePlayerStats: {},
};

const onFetchPlayersSuccess = (state: IPlayerReducer, payload: IPlayer[]): IPlayerReducer => ({
	...state,
	players: formatPlayerNames(payload),
});

const onFetchPlayerStatsSuccess = (
	state: IPlayerReducer,
	payload: IPlayerStat[]
): IPlayerReducer => ({
	...state,
	playerStats: payload,
});

const onPlayerStatsClear = (state: IPlayerReducer): IPlayerReducer => ({
	...state,
	playerStats: [],
});

const onFetchAllPlayersStatsSuccess = (
	state: IPlayerReducer,
	payload: Record<number, IStatsCentrePlayer>
): IPlayerReducer => ({
	...state,
	statsCentrePlayerStats: payload,
});

export const player = createReducer<IPlayerReducer>({}, defaultState)
	.on(actions.fetchPlayersSuccess, onFetchPlayersSuccess)
	.on(actions.fetchPlayerStatsSuccess, onFetchPlayerStatsSuccess)
	.on(actions.playerStatsClear, onPlayerStatsClear)
	.on(actions.fetchAllPlayersStatsSuccess, onFetchAllPlayersStatsSuccess);
