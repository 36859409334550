import React from "react";
import {StepTitle, StepWrapper} from "./styled";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/media";
import styled from "styled-components";
import {SecondaryButton} from "components/styled";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getProject, getSport} from "modules/selectors";
import useClipboard from "react-use-clipboard";
import {Exist} from "components/Exist";
import {SocialSharing} from "components/SocialSharing";
import {InviteForm} from "components/Leagues/InviteForm";
import {ILeagueInvitePayload} from "modules/types";

const InnerWrapper = styled.div`
	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 19px;
		font-weight: normal;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
	}
`;

const CopyButton = styled(SecondaryButton)`
	width: 100%;
	max-width: unset;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	margin-bottom: 24px;

	&:disabled {
		background: transparent !important;
		opacity: 0.6;
	}

	@media screen and ${media.lessThanMD} {
		width: 100%;
		max-width: unset;
	}
`;

const SkipLink = styled(Link)`
	display: block;
	color: #f02f42;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 17px;
	text-decoration: none;
	margin-top: 38px;

	&:hover,
	&:active,
	&:focus {
		text-decoration: underline;
	}
`;

interface IProps {
	isDisabled: boolean;
	onSkip: () => void;
	leagueId?: number;
	leagueCode?: string;
	invitesState: {isLoading: boolean; isSuccess: boolean};
	onSendInvites: (controls: ILeagueInvitePayload[]) => void;
}

export const LeagueCreateStepTwo: React.FC<IProps> = ({
	isDisabled,
	onSkip,
	leagueId,
	leagueCode,
	invitesState,
	onSendInvites,
}) => {
	const project = useSelector(getProject);
	const sport = useSelector(getSport);
	const isMobile = useMediaQuery(media.lessThanMD);

	const mobileClass = isMobile ? "mobile" : "";
	const disabledClass = isDisabled ? "disabled" : "";

	const [isCopied, setCopied] = useClipboard(String(leagueCode || ""), {
		successDuration: 3000,
	});

	const skipLink = `/${project}/${sport}/leagues`;
	return (
		<StepWrapper className={`${disabledClass} ${mobileClass}`}>
			<StepTitle>
				<p>Step 2: Invite your friends</p>
				<div />
			</StepTitle>
			<InnerWrapper>
				<h3>Invite via code</h3>
				<p>
					Great, now that you have created a league, invite others to join. Your unique
					league code for MFL Official League is: {leagueCode}
				</p>
				<CopyButton disabled={isCopied} onClick={setCopied}>
					{isCopied ? "Copied" : "Copy code"}
				</CopyButton>
				<InviteForm
					onSendInvites={onSendInvites}
					invitesState={invitesState}
					leagueId={leagueId}
				/>

				<div>
					<h3>Invite via social</h3>
					<Exist when={Boolean(leagueId)}>
						<SocialSharing code={leagueCode || ""} leagueId={leagueId || 0} />
					</Exist>
				</div>

				<SkipLink onClick={onSkip} to={skipLink}>
					Skip this step
				</SkipLink>
			</InnerWrapper>
		</StepWrapper>
	);
};
