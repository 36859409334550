import {IError, IFetchRanksRequestPayload, IGameBarPayload, ISagaAction} from "modules/types";
import {
	fetchGameBarGameDaySuccess,
	fetchLeaderboardGameDayConcat,
	fetchLeaderboardGameDaySuccess,
	globalError,
	rankingsRequestStateHandler,
} from "modules/actions";
import {getSport} from "modules/selectors";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {RequestStateType} from "modules/types/enums";

export const fetchGameBarGameDaySaga = function* ({
	payload,
}: ISagaAction<IGameBarPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.RankingsGameDay.fetchGameBar, {sport, payload});
		yield* put(fetchGameBarGameDaySuccess(response.success.gamebar));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchLeaderboardGameDaySaga = function* ({
	payload,
}: ISagaAction<IFetchRanksRequestPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.RankingsGameDay.fetchLeaderboard, {sport, payload});

		const requestData = {
			next: response.success.next,
			payload: response.success.rankings,
		};

		if (payload.offset !== 0) {
			yield* put(fetchLeaderboardGameDayConcat(requestData));
		} else {
			yield* put(fetchLeaderboardGameDaySuccess(requestData));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			rankingsRequestStateHandler({
				key: "leaderboard",
				state: RequestStateType.Idle,
			})
		);
	}
};
