import {createAction} from "redux-act";
import {IInfoModal, IRemovePlayerModalPayload} from "modules/types/modal";

export const openInfoModal = createAction<IInfoModal>();
export const closeInfoModal = createAction();

export const openRemovePlayerModal = createAction<IRemovePlayerModalPayload>();
export const closeRemovePlayerModal = createAction();

export const openRemoveLeagueGameDayUserModal = createAction<IRemovePlayerModalPayload>();
export const closeRemoveLeagueGameDayUserModal = createAction();

export const openPlayerInfoModal = createAction<number>();
export const closePlayerInfoModal = createAction();

export const openTransferModal = createAction();
export const closeTransferModal = createAction();

export const openLeagueLeaveModal = createAction();
export const closeLeagueLeaveModal = createAction();
