import field_classic_bg from "assets/images/field_classic_bg.png";
import field_classic_bg_mobile from "assets/images/field_classic_bg_mobile.png";
import {themeGlobal} from "assets/theme";
import {createTheme} from "@mui/material";
import HeaderRightClassic from "assets/images/HeaderRightClassic.png";

export const MSLClassicThemeObject = {
	...themeGlobal,
	primary: "#000151",
	secondary: "#00FFFF",
	text_color: "#000151",
	text_red: "#F02F42",
	other_project: "#F02F42",
	text: {
		dark: "#222222",
		light: "F0F0F0",
		white: "#FFFFFF",
	},
	input: {
		label: "#000151",
		error: "#FF0000",
		border: "#000151",
	},
	gradient: {
		start: "#0033a0",
		stop: "#010452",
	},
	button: {
		disabled_opacity: 0.5,
		primary: {
			default: "#000151",
			hover: "#333474",
			disabled: "#CED1DD",
			disabled_color: "#999ab8",
			disabled_border: "#CED1DD",
			color: "#FFFFFF",
		},
		secondary: {
			default: "#FFFFFF",
			hover: "#333474",
			disabled: "#CED1DD",
			color: "#000151",
		},
		approval: {
			default: "#000151",
			hover: "#333474",
			disabled: "#CED1DD",
			color: "#FFFFFF",
		},
		warning: {
			default: "#F02F42",
			hover: "#FF4343",
			disabled: "#F9ACB3",
			color: "#FFFFFF",
		},
		primary_blue: {
			background: "#00ffff",
			color: "#000151",
		},
	},
	header: {
		right_image: HeaderRightClassic,
		menu: {
			opened_item_color: "#000151",
			second_menu_color: "#00FFFF",
		},
		main: {
			background: "#000151",
			color: "#FFFFFF",
			border: "#00316B",
		},
		sub: {
			background: "#00FFFF",
			color: "#000151",
		},
		color: "#fff",
		active_color: "#00FFFF",
		active_bg_color: "transparent",
		sport_selector_color: "#00FFFF",
	},
	footer: {
		background: "#000151",
		color: "#FFFFFF",
		border_color: "#00FFFF",
	},

	player_profile: {
		active_tab_border: "#00FFFF",
	},

	field: {
		lockoutBg: "#000151",
		tab_color: "#00316b",
		legend: {
			background: "#00316b",
			color: "#FFFFFF",
		},
		formation: {
			background: "#00316b",
			color: "#FFFFFF",
			dots_color: "#000000",
		},
		background: {
			desktop: field_classic_bg,
			mobile: field_classic_bg_mobile,
		},
	},
	landing: {
		sign_in_color: "#F02F42",
		guideCards: {
			gradient: "linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #000151 50%);",
		},
	},
	stats_centre: {
		selected_color: "#333474",
	},
	preloader: "#000151",
} as const;
export type IDefaultTheme = typeof MSLClassicThemeObject;
export const ClassicMSLTheme = createTheme(MSLClassicThemeObject);
