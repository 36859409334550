import React, {useCallback} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
	ModalHeaderTitle,
	ModalText,
} from "components/Modals/styled";
import {IconInfo, IconRemove} from "components/Icons";
import styled from "styled-components";
import {PrimaryLink, SecondaryButton} from "components/styled";
import {useDispatch, useSelector} from "react-redux";
import {toggleTeamSavedModal} from "modules/actions";
import {getProject, getSport, getTeamSavedModalState} from "modules/selectors";

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;
`;

export const TeamCreatedModal: React.FC = () => {
	const dispatch = useDispatch();
	const isModalVisible = useSelector(getTeamSavedModalState);
	const sport = useSelector(getSport);
	const project = useSelector(getProject);

	const closeModal = useCallback(() => {
		dispatch(toggleTeamSavedModal(false));
	}, [dispatch]);

	if (!isModalVisible) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerWrapper>
					<IconInfo color="#000151" width={66} height={66} />
					<ModalHeaderTitle>Your team is saved</ModalHeaderTitle>
					<ModalText>
						Your team has saved successfully. You have time to edit your team before the
						next round kicks off. Why not create a league and invite your friends while
						you wait.
					</ModalText>
				</InnerWrapper>
				<ModalActions>
					<SecondaryButton onClick={closeModal}>Edit my team</SecondaryButton>
					<PrimaryLink onClick={closeModal} to={`/${project}/${sport}/leagues/create`}>
						Create a league
					</PrimaryLink>
				</ModalActions>
			</ModalContent>
		</ModalBackdrop>
	);
};
