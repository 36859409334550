import React, {useState} from "react";
import styled from "styled-components";
import {PaddingContainer, PrimaryLink, PureButton, SecondaryLink} from "components/styled";
import {IconInfo, IconRemove} from "components/Icons";
import {media} from "assets/media";
import {useSelector} from "react-redux";
import {getIsUserChecked, getIsUserLogged} from "modules/selectors";

const NonLoggedBlockWrapper = styled.div`
	position: absolute;
	height: 64px;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	transform: translateY(100%);
	z-index: 4;

	@media screen and (max-width: ${media.md}) {
		height: unset;
		padding: 4px 0;
	}
`;

const InnerWrapper = styled(PaddingContainer)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 9px;
	padding-bottom: 9px;
	height: 100%;
`;

const ActionsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 18px;
	align-items: center;
	height: 100%;
	margin: 0 16px;

	p {
		color: #222222;
		font-size: 14px;
		line-height: 18px;
		margin: 0;
	}

	@media screen and (max-width: ${media.md}) {
		flex-direction: column;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 16px;
`;

const IconWrapper = styled.div``;

export const NonLoggedHeaderBlock: React.FC = () => {
	const isLogged = useSelector(getIsUserLogged);
	const isChecked = useSelector(getIsUserChecked);
	const [isMenuVisible, setIsMenuVisible] = useState(true);

	const hideMenu = () => {
		setIsMenuVisible(false);
	};

	if (!isMenuVisible || isLogged || !isChecked) {
		return null;
	}

	return (
		<NonLoggedBlockWrapper>
			<InnerWrapper>
				<IconWrapper>
					<IconInfo width={22} height={22} />
				</IconWrapper>

				<ActionsWrapper>
					<div>
						<p>
							<b>Welcome to Malaysian Football Fantasy.</b>
						</p>
						<p>Log in or register to save your team</p>
					</div>
					<ButtonWrapper>
						<SecondaryLink to="/login">Log in</SecondaryLink>
						<PrimaryLink to="/register">Register</PrimaryLink>
					</ButtonWrapper>
				</ActionsWrapper>
				<IconWrapper>
					<PureButton onClick={hideMenu}>
						<IconRemove width={20} height={20} />
					</PureButton>
				</IconWrapper>
			</InnerWrapper>
		</NonLoggedBlockWrapper>
	);
};
