import {isArray, sortBy} from "lodash";
import {
	fetchAllPlayersStatsSuccess,
	fetchAllTeamsStatsSuccess,
	fetchHelpSuccess,
	fetchMRECSuccess,
	fetchNotificationBarSuccess,
	fetchPlayerStatsSuccess,
	fetchRoundsSuccess,
	fetchSquadsSuccess,
	globalError,
	IMRECSuccess,
	INotification,
} from "modules/actions";
import {Api} from "modules/utils/Api";
import {call, delay, put, select} from "typed-redux-saga";
import {getSport} from "modules/selectors";
import {IError, ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";
import {IHelpReducer} from "modules/types/reducers";

export const fetchSquadsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.JSON.squads, sport.toLocaleLowerCase());
		yield* put(fetchSquadsSuccess(sortBy(response, "name")));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchAllPlayersStatsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const players = yield* call(Api.JSON.all_players_stats, sport.toLocaleLowerCase());
		yield* put(fetchAllPlayersStatsSuccess(players));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchAllTeamsStatsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const teams = yield* call(Api.JSON.all_teams_stats, sport.toLocaleLowerCase());
		yield* put(fetchAllTeamsStatsSuccess(teams));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchRoundsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.JSON.rounds, sport.toLocaleLowerCase());
		yield* put(fetchRoundsSuccess(response));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchRoundsDelayedSaga = function* () {
	try {
		const sport = yield* select(getSport);
		yield delay(15000);
		const response = yield* call(Api.JSON.rounds, sport.toLocaleLowerCase());
		yield put(fetchRoundsSuccess(response));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchPlayerStatsSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const stats = yield* call(Api.JSON.player_stats, {sport, payload});
		if (isArray(stats)) {
			stats.sort((a, b) => a.number - b.number);
			yield* put(fetchPlayerStatsSuccess(stats));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchHelpsSaga = function* ({payload}: ISagaAction<keyof IHelpReducer>): SagaIterator {
	try {
		const response = yield* call(Api.JSON.help_pages, payload);
		yield* put(
			fetchHelpSuccess({
				key: payload,
				value: response,
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchNotificationBarSaga = function* (): SagaIterator {
	try {
		const result = (yield* call(Api.JSON.notification_bar)) as INotification;
		yield* put(fetchNotificationBarSuccess(result.notifications));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchMRECSaga = function* (): SagaIterator {
	try {
		const response = (yield* call(Api.JSON.mrec)) as IMRECSuccess[];
		yield* put(fetchMRECSuccess(response));
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};
