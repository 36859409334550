import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	getLeagueGameDayById,
	getLeagueGameDayRequestStateByKey,
	getLeagueGameDayUsers,
	getUser,
} from "modules/selectors";
import {PreloaderWrapper, PrimaryButton, PureButton} from "components/styled";
import {CircularPreloaded} from "components/Preloader";
import {Exist} from "components/Exist";
import {fetchLeagueGameDayUsersRequest, leagueGameDayUsersClear} from "modules/actions";
import {IconRemove} from "components/Icons";
import {openRemoveLeagueGameDayUserModal} from "modules/actions/modals";
import {RemoveLeagueUserGameDayModal} from "components/Modals";
import {useLocation} from "react-router-dom";

const Wrapper = styled.div``;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const THead = styled.thead`
	tr {
		border-radius: 4px 4px 0 0;
		background: linear-gradient(
			225deg,
			${({theme}) => theme.gradient.start} 0%,
			${({theme}) => theme.gradient.stop} 99.97%
		);
	}

	th {
		text-align: left;
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		padding: 12px 0 14px 12px;

		&.position {
			width: 1%;
		}

		&.action {
			width: 1%;
			padding-right: 30px;
		}
	}
`;

const TBody = styled.tbody`
	tr {
		border: 1px solid #dddddd;
		border-top: 0;
	}

	td {
		padding: 16px 0 16px 12px;
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 14px;

		&.action-td {
			text-align: right;
			padding-right: 14px;
		}
	}

	tr.preloader {
		text-align: center;

		td {
			padding: 12px 0;
		}
	}
`;

const LoadMoreWrapper = styled.div`
	width: 100%;
	text-align: center;
	button {
		margin: 24px auto;
	}
`;

interface IProps {
	leagueId: number;
}

export const LeagueGameDayManageTable: React.FC<IProps> = ({leagueId}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const league = useSelector(getLeagueGameDayById)(leagueId);
	const {users, nextPage} = useSelector(getLeagueGameDayUsers);
	const user = useSelector(getUser);
	const {isLoading} = useSelector(getLeagueGameDayRequestStateByKey)("fetchLeagueUsers");
	const [filter, setFilter] = useState({
		limit: 20,
		page: 1,
	});

	const loadMore = useCallback(() => {
		setFilter({...filter, page: filter.page + 1});
	}, [filter]);

	useEffect(() => {
		dispatch(leagueGameDayUsersClear());
	}, [dispatch, location]);

	useEffect(() => {
		dispatch(fetchLeagueGameDayUsersRequest({id: leagueId, payload: filter}));
	}, [dispatch, leagueId, filter]);

	const removeUser = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const userId = Number(e.currentTarget.dataset.id);
			const user = users.find((user) => user.userId === userId);
			if (!userId || !user) {
				return;
			}

			dispatch(
				openRemoveLeagueGameDayUserModal({
					userId,
					leagueId,
					userName: `${user.first_name} ${user.last_name}`,
				})
			);
		},
		[dispatch, leagueId, users]
	);

	if (!league) {
		return (
			<PreloaderWrapper>
				<CircularPreloaded />
			</PreloaderWrapper>
		);
	}

	return (
		<Wrapper>
			<Table>
				<THead>
					<tr>
						<th className="position">Pos</th>
						<th className="manager">Manager</th>
						<th className="action">
							<span className="desktop-only">Remove</span>
							<span className="mobile-only">&nbsp;</span>
						</th>
					</tr>
				</THead>
				<TBody>
					{users.map((leagueUser, index) => (
						<tr key={leagueUser.userId}>
							<td>{index + 1}</td>
							<td>
								{leagueUser.first_name} {leagueUser.last_name}
							</td>
							<td className="action-td">
								<Exist when={leagueUser.userId !== user?.id}>
									<PureButton onClick={removeUser} data-id={leagueUser.userId}>
										<IconRemove color="#F02F42" />
									</PureButton>
								</Exist>
							</td>
						</tr>
					))}
					<tr className="preloader">
						<Exist when={isLoading}>
							<td colSpan={3}>
								<CircularPreloaded />
							</td>
						</Exist>
					</tr>
				</TBody>
			</Table>
			<Exist when={nextPage}>
				<LoadMoreWrapper>
					<PrimaryButton disabled={isLoading} className="w-220" onClick={loadMore}>
						Load more
					</PrimaryButton>
				</LoadMoreWrapper>
			</Exist>
			<RemoveLeagueUserGameDayModal />
		</Wrapper>
	);
};
