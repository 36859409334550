import {
	IContactUsRequestPayload,
	IError,
	IResetPasswordPayload,
	ISagaAction,
	IUserUpdatePasswordPayload,
	IUserUpdatePayload,
} from "modules/types";
import {ILoginPayload, IRegisterPayload} from "modules/types/user";
import {Api, ApiError} from "modules/utils/Api";
import {SagaIterator} from "redux-saga";
import {call, delay, put, select} from "typed-redux-saga";
import {
	globalError,
	handleAuthModal,
	saveTeam,
	saveTeamGameDay,
	showNotification,
	userChecked,
	userLoginSuccess,
	userRegisterSuccess,
	userStateChangeAction,
	userUpdateSuccess,
	userLogoutAndClearSuccess,
} from "modules/actions";
import {getIsSavingMode, isGameDayGame} from "modules/selectors";
import {RequestStateType} from "modules/types/enums";
import {openInfoModal} from "modules/actions/modals";

export const userRegisterSaga = function* ({payload}: ISagaAction<IRegisterPayload>): SagaIterator {
	try {
		const isSaving = yield* select(getIsSavingMode);
		const isGameDay = yield* select(isGameDayGame);
		const response = yield* call(Api.Auth.register, payload);
		ApiError.CHECK(response);
		yield* put(userRegisterSuccess(response.success.user));
		if (isSaving) {
			const saveTeamAction = isGameDay ? saveTeamGameDay : saveTeam;
			yield* put(saveTeamAction());
			yield* put(handleAuthModal({showAuthModal: false}));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			userStateChangeAction({
				key: "register",
				state: RequestStateType.Idle,
			})
		);
		yield* put(
			userStateChangeAction({
				key: "update",
				state: RequestStateType.Idle,
			})
		);
		yield* put(userChecked());
	}
};

export const userLoginSaga = function* ({payload}: ISagaAction<ILoginPayload>): SagaIterator {
	try {
		const isSaving = yield* select(getIsSavingMode);
		const response = yield* call(Api.Auth.login, payload);
		const isGameDay = yield* select(isGameDayGame);

		ApiError.CHECK(response);
		yield* put(userLoginSuccess(response.success.user));
		if (isSaving) {
			const saveTeamAction = isGameDay ? saveTeamGameDay : saveTeam;
			yield* put(saveTeamAction());

			yield* put(handleAuthModal({showAuthModal: false}));
		}
		yield* delay(2000);
		yield* put(userChecked());
	} catch (e) {
		console.log(e);
		yield* put(globalError(e as IError));
		yield* put(userChecked());
	} finally {
		yield* put(
			userStateChangeAction({
				key: "login",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userLogoutSaga = function* (): SagaIterator {
	try {
		yield* call(Api.Auth.logout);
		yield* put(userLogoutAndClearSuccess());
		window.location.href = "/";
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			userStateChangeAction({
				key: "login",
				state: RequestStateType.Idle,
			})
		);
		window.location.href = "/";
	}
};

export const userFetchSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.User.getUser);
		ApiError.CHECK(response);
		yield* put(userLoginSuccess(response.success.user));
		yield* put(userChecked());
		yield* delay(2000);
	} catch (e) {
		yield* put(userChecked());
	} finally {
		yield* put(
			userStateChangeAction({
				key: "login",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userUpdateSaga = function* ({payload}: ISagaAction<IUserUpdatePayload>): SagaIterator {
	try {
		const response = yield* call(Api.User.updateUser, payload);
		yield* put(userUpdateSuccess(response.success.user));
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "update",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userUpdatePasswordSaga = function* ({
	payload,
}: ISagaAction<IUserUpdatePasswordPayload>): SagaIterator {
	try {
		yield* call(Api.User.changePassword, payload);
		yield* put(
			userStateChangeAction({
				key: "changePassword",
				state: RequestStateType.Success,
			})
		);
		yield* put(
			showNotification({
				message: "Password has been successfully changed",
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "changePassword",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const requestPasswordChangeSaga = function* ({payload}: ISagaAction<string>): SagaIterator {
	try {
		yield* call(Api.User.passwordChangeRequest, payload);
		yield* put(
			userStateChangeAction({
				key: "forgotPassword",
				state: RequestStateType.Success,
			})
		);
		yield* put(
			openInfoModal({
				text:
					"A link has been sent to your email address to reset your password. <br />" +
					"If it was not received please contact us or try with a different email address.",
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* put(
			userStateChangeAction({
				key: "forgotPassword",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userResetPasswordSaga = function* ({
	payload,
}: ISagaAction<IResetPasswordPayload>): SagaIterator {
	try {
		yield* call(Api.User.passwordResetRequest, payload);
		yield* put(
			userStateChangeAction({
				key: "resetPassword",
				state: RequestStateType.Success,
			})
		);
		yield* put(
			openInfoModal({
				text: "Your password has been changed successfully",
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "resetPassword",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const contactUsSaga = function* ({
	payload,
}: ISagaAction<IContactUsRequestPayload>): SagaIterator {
	try {
		yield* call(Api.User.contactUs, payload);
		yield* put(
			openInfoModal({
				title: "Thank you",
				text: "Your support request has been received.",
			})
		);
		yield* put(
			userStateChangeAction({
				key: "contactUs",
				state: RequestStateType.Success,
			})
		);
	} catch (e) {
		yield* put(globalError(e as IError));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "contactUs",
				state: RequestStateType.Idle,
			})
		);
	}
};
