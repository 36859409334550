import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {useIsPathIncludes, useNavigateWithSport} from "modules/utils/hooks";
import {
	getSport,
	getTeamRequestState,
	getTradePairsAccordingPosition,
	isTeamFullFilled,
} from "modules/selectors";
import {flatMap} from "lodash";
import {openTransferModal} from "modules/actions/modals";
import {resetTradeByIndex} from "modules/actions";
import {PrimaryButton, SecondaryButton} from "components/styled";

export const TransferButtons: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const sportNavigate = useNavigateWithSport();
	const sport = useSelector(getSport);
	const {isLoading, isSuccess} = useSelector(getTeamRequestState)("trade");
	const isTeamFull = useSelector(isTeamFullFilled);
	const isTransferPage = useIsPathIncludes("transfers");
	const trades = useSelector(getTradePairsAccordingPosition);
	const isIncompleteTrades = flatMap(trades).some((trade) => trade === undefined);

	const isMakeTradeDisabled = !isTeamFull || isLoading || isIncompleteTrades;

	const makeTransfer = useCallback(() => {
		dispatch(openTransferModal());
	}, [dispatch]);

	const resetAll = useCallback(() => {
		for (let i = 0; i < trades.length; i++) {
			dispatch(resetTradeByIndex(0));
		}
	}, [trades, dispatch]);

	useEffect(() => {
		if (isSuccess) {
			sportNavigate(`/team`);
		}
	}, [isSuccess, sport, sportNavigate]);

	useEffect(() => {
		if (!isTransferPage) {
			resetAll();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, location, isTransferPage]);

	if (!isTransferPage || trades.length === 0) {
		return null;
	}

	return (
		<React.Fragment>
			<SecondaryButton disabled={isLoading} onClick={resetAll}>
				Reset
			</SecondaryButton>
			<PrimaryButton disabled={isMakeTradeDisabled} onClick={makeTransfer}>
				Make Transfer
			</PrimaryButton>
		</React.Fragment>
	);
};
