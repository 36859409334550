import {IMRECSuccess, IStore} from "modules/types";
import {createSelector} from "reselect";

const DEFAULT_MREC = {
	sport: "msl",
	type: "",
	desktopImage: "",
	mobileImage: "",
	url: "",
};

const DEFAULT_NOTIFICATION = {
	sport: "",
	isEnabled: false,
	message: "",
};

export const getState = ({jsons}: IStore) => jsons;
export const getMRECState = ({jsons: {mrec}}: IStore) => mrec;

export const getNotificationBar = createSelector(getState, ({notificationBar}) => {
	return notificationBar.find((el) => el.sport !== "") || DEFAULT_NOTIFICATION;
});

export const getLeaderboard = createSelector(
	getMRECState,
	(mrec) => mrec.find((el: IMRECSuccess) => el.type === "leaderboard") || DEFAULT_MREC
);

export const getMREC = createSelector(
	getMRECState,
	(mrec) => mrec.find((el) => el.type === "mrec") || DEFAULT_MREC
);
