import React from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {PrimaryButton} from "components/styled";
import {JoinRow} from "components/Leagues/Join/JoinRow";
import {media} from "assets/media";
import {CircularPreloaded} from "components/Preloader";
import {IJoinLeague, IJoinLeagueGameDay} from "modules/types";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	margin-bottom: 50px;

	@media screen and ${media.lessThanMD} {
		margin-bottom: 20px;
		gap: 20px;
	}
`;

const SPrimaryButton = styled(PrimaryButton)`
	max-width: 320px;
	width: 100%;

	@media screen and ${media.lessThanMD} {
		max-width: calc(100% - 40px);
	}
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;

	thead {
		border-radius: 4px 4px 0 0;
		background: linear-gradient(
			225deg,
			${({theme}) => theme.gradient.start} 0%,
			${({theme}) => theme.gradient.stop} 99.97%
		);
		border: 1px solid transparent;

		th {
			color: #ffffff;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 14px;
			height: 40px;
			padding-left: 20px;
			text-align: right;

			&.join-league-head-name {
				text-align: left;
			}
		}

		@media screen and ${media.lessThanMD} {
			border: unset;

			th.join-league-head-start-week {
				display: none;
			}
		}
	}
`;

interface IProps {
	isLoadMoreAvailable: boolean;
	isLoading: boolean;
	leagues: IJoinLeague[] | IJoinLeagueGameDay[];
	onLoadMore: () => void;
}

export const JoinLeagueTable: React.FC<IProps> = ({
	isLoadMoreAvailable,
	onLoadMore,
	isLoading,
	leagues,
}) => {
	const isLoadMoreVisible = isLoadMoreAvailable && !isLoading;

	return (
		<Wrapper>
			<Table>
				<thead>
					<tr>
						<th className="join-league-head-name">League name</th>
						<th className="join-league-head-start-week">Starting Week</th>
						<th>Players</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{leagues
						.filter((league) => league.class !== "overall")
						.map((league) => (
							<JoinRow key={league.id} league={league} />
						))}
				</tbody>
			</Table>
			<Exist when={isLoading}>
				<CircularPreloaded color="#FFFFFF" />
			</Exist>
			<Exist when={isLoadMoreVisible}>
				<SPrimaryButton className="join-league-more" onClick={onLoadMore}>
					Show more
				</SPrimaryButton>
			</Exist>
		</Wrapper>
	);
};
