import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

export const HOCLiveScores: React.FC = ({children}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		// dispatch(subscribeToLiveScores());
		// dispatch(unsubscribeFromLiveScores());

		return () => {
			// dispatch(unsubscribeFromLiveScores());
		};
	}, [dispatch]);

	return <React.Fragment>{children}</React.Fragment>;
};
