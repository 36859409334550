import React, {ReactNode} from "react";
import styled from "styled-components";
import {media} from "assets/media";

const BannerWrap = styled.div`
	background: ${({theme}) => theme.secondary};
	box-sizing: border-box;
	color: ${({theme}) => theme.primary};
	display: flex;
	padding: 0 10px;
	text-align: center;
	width: 100%;

	@media screen and ${media.lessThanSM} {
		font-size: 12px;
	}
`;

const BannerText = styled.div`
	margin: auto;
`;

interface IAlertBanner {
	children: ReactNode;
}

export const AlertBanner: React.FC<IAlertBanner> = ({children}) => (
	<BannerWrap>
		<BannerText>{children}</BannerText>
	</BannerWrap>
);
