import {SagaIterator} from "redux-saga";
import {call, put, select} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchPlayersSuccess} from "modules/actions";
import {getSport} from "modules/selectors";

export const fetchPlayersSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const players = yield* call(Api.JSON.players, sport);
		yield* put(fetchPlayersSuccess(players));
	} catch (e) {
		console.log(e);
	}
};
