import {useDispatch, useSelector} from "react-redux";
import {
	getGameDaySelectedTeam,
	getIsGameDayTeamCompleted,
	isGameDayTeamLockout,
} from "modules/selectors";
import {flatMap} from "lodash";
import {Exist} from "components/Exist";
import {NonCompleteTeamButtons} from "./NonCompleteTeamButtons";
import React, {useCallback} from "react";
import {
	StatusBarButtonsBlock,
	StatusBarInnerContainer,
	StatusBarSelectedBlock,
	StatusBarSmallContainer,
	StatusBarWrapper,
} from "components/Team/styled";
import {
	autoFillGameDayRequest,
	clearHeads,
	removePlayerFromTeam,
	saveTeamGameDay,
} from "modules/actions";
import {NonLockoutButtons} from "./NonLockoutButtons";
import {LockoutButtons} from "./LockoutButtons";

const TeamSaveButtons: React.FC = () => {
	const dispatch = useDispatch();

	const isLockout = useSelector(isGameDayTeamLockout);
	const team = useSelector(getGameDaySelectedTeam);
	const flatLineUp = flatMap(team.lineup);

	const clearTeam = useCallback(() => {
		flatLineUp.forEach((playerId) => {
			dispatch(removePlayerFromTeam(playerId));
			dispatch(clearHeads());
		});
	}, [flatLineUp, dispatch]);

	const autoFillHandler = useCallback(() => {
		dispatch(autoFillGameDayRequest());
	}, [dispatch]);

	const saveTeamHandle = useCallback(() => {
		dispatch(saveTeamGameDay());
	}, [dispatch]);

	if (!isLockout) {
		return (
			<NonLockoutButtons
				saveTeamHandle={saveTeamHandle}
				clearTeam={clearTeam}
				autoFillHandler={autoFillHandler}
			/>
		);
	}
	return (
		<LockoutButtons
			saveTeamHandle={saveTeamHandle}
			clearTeam={clearTeam}
			autoFillHandler={autoFillHandler}
		/>
	);
};

export const GameDayBottomStatusBar: React.FC = () => {
	const team = useSelector(getGameDaySelectedTeam);
	const flatLineUpGameDay = flatMap(team.lineup);
	const total = flatLineUpGameDay.length;
	const selected = flatLineUpGameDay.filter((item) => item !== 0).length;
	const isTeamComplete = useSelector(getIsGameDayTeamCompleted);

	return (
		<StatusBarWrapper>
			<StatusBarInnerContainer>
				<StatusBarSmallContainer>
					<StatusBarSelectedBlock>
						<p>Selected</p>
						<p>
							<b>
								{selected} / {total}
							</b>
						</p>
					</StatusBarSelectedBlock>
					<StatusBarButtonsBlock>
						<Exist when={!isTeamComplete}>
							<NonCompleteTeamButtons />
						</Exist>
						<Exist when={isTeamComplete}>
							<TeamSaveButtons />
						</Exist>
					</StatusBarButtonsBlock>
				</StatusBarSmallContainer>
			</StatusBarInnerContainer>
		</StatusBarWrapper>
	);
};
