import {chain, get, isString, matches, overEvery, partial, stubTrue} from "lodash";
import {IPoolFilters, ISortBy} from "modules/types";
import {IPlayer} from "modules/types/json";
import {SALARY_CUP_DELIMITER} from "modules/constants";

export const getDisplayName = (player: IPlayer, shortFirstName = false) => {
	if (player) {
		if (shortFirstName) {
			return `${player.firstName[0]}. ${player.lastName}`;
		}

		if (player.mediaName) {
			return player.mediaName;
		}

		return `${player.firstName} ${player.lastName}`;
	}
	return "";
};

export const getFirstLetter = (player_name?: string) => {
	if (!player_name) {
		return "";
	}

	return player_name.length ? `${player_name[0]}.` : player_name;
};

export const maybeGetFilter = (value: string | number, filter: (player: IPlayer) => boolean) => {
	return value ? filter : stubTrue;
};

export const orderComparator = (
	sortByParams: ISortBy,
	player_one: IPlayer,
	player_two: IPlayer
) => {
	const {sortBy, order} = sortByParams;
	const orderBy = order ? -1 : 1;
	const minimal = Number.MAX_SAFE_INTEGER * orderBy;
	const getResult = (
		condition: (value: string | number) => boolean,
		min: number,
		value: number
	) => (condition(value) ? min : value);
	const isStringOrZeroOrUndefined = (val: string | number | undefined) =>
		isString(val) || val === 0 || !val;
	const getResultOrMin = partial(getResult, isStringOrZeroOrUndefined, minimal * -1);

	const a_result = getResultOrMin(Number(get(player_one, sortBy)));
	const b_result = getResultOrMin(Number(get(player_two, sortBy)));
	const is_bigger = a_result < b_result;
	const is_less = a_result > b_result;

	if (is_bigger) {
		return orderBy;
	}

	if (is_less) {
		return -1 * orderBy;
	}

	return 0;
};

export const getFilteredPlayers = (players: IPlayer[], filters: IPoolFilters) => {
	if (!players) {
		return [];
	}
	const search = filters.search;
	const position = Number(filters.position);
	const price = filters.price;

	return chain(players)
		.filter(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			overEvery([
				maybeGetFilter(search, ({mediaName}: IPlayer) => {
					return `${mediaName}`.toLowerCase().includes(search.toLowerCase());
				}),
				maybeGetFilter(position, matches({position})),

				maybeGetFilter(price, ({cost}: IPlayer) => {
					if (!price || Number(price) === -1) {
						return true;
					}

					const [lowest, highest] = price.split("-").map(Number);
					const player_cost = cost / SALARY_CUP_DELIMITER;

					return lowest <= player_cost && player_cost <= highest;
				}),
			])
		)
		.value();
};
