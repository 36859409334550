import React from "react";
import {useSelector} from "react-redux";
import {getTradePairsAccordingPosition} from "modules/selectors";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {TransferRow} from "components";

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Thead = styled.thead`
	border-radius: 4px 4px 0 0;
	background: linear-gradient(
		225deg,
		${({theme}) => theme.gradient.start} 0%,
		${({theme}) => theme.gradient.stop} 99.97%
	);

	th {
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		padding: 6px 10px;
		font-weight: normal;

		&:first-of-type {
			text-align: left;
		}

		&:last-of-type {
			text-align: right;
		}
	}
`;

const Tbody = styled.tbody`
	tr {
		border: 1px solid #dddddd;
		border-top: none;

		td.icon {
			padding: 0 12px;
		}

		td p.price {
			color: #f02f42;
		}

		td p.price.reverse {
			color: #298109;
		}
	}
`;

export const TransferViewResult: React.FC = () => {
	const trades = useSelector(getTradePairsAccordingPosition);

	return (
		<Table>
			<Thead>
				<tr>
					<th>Transferred out</th>
					<th>&nbsp;</th>
					<th>Transferred in</th>
				</tr>
			</Thead>
			<Exist when={trades.length > 0}>
				<Tbody>
					{trades.map(([tradeOut, tradeIn], tradeIndex) => (
						<TransferRow
							key={tradeOut}
							tradeOut={tradeOut}
							tradeIn={tradeIn}
							tradeIndex={tradeIndex}
							hasAction={false}
						/>
					))}
				</Tbody>
			</Exist>
		</Table>
	);
};
