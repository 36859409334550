import React, {SyntheticEvent} from "react";
import defaultBadge from "assets/images/badge.png";
import {getSquadById} from "modules/selectors";
import {useSelector} from "react-redux";
import {REACT_APP_LOCAL_IMAGE_URL} from "modules/constants";

interface IProps {
	squadId: number | undefined;
	alt?: string;
	className?: string;
}

export const SquadLogo: React.FC<IProps> = ({squadId, alt, className}) => {
	const squad = useSelector(getSquadById)(squadId);
	const squadBadge = `${REACT_APP_LOCAL_IMAGE_URL}squads/logo/${String(squadId)}.png`;

	const altText = alt || squad?.short_name;

	const loadFallback = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		const image = e.target as HTMLImageElement;
		if (image.src !== defaultBadge) {
			image.src = defaultBadge;
		}
	};

	return <img src={squadBadge} alt={altText} className={className} onError={loadFallback} />;
};
