import {Input, PrimaryButton} from "components/styled";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getUser, getUserRequestState} from "modules/selectors";
import {MenuItem} from "@mui/material";
import {media} from "assets/media";
import {contactUsRequest} from "modules/actions";
import {Exist} from "components/Exist";

const Form = styled.form`
	padding-top: 26px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 20px;

	${Input} {
		max-width: 300px;
		width: 100%;
	}

	@media screen and ${media.lessThanSM} {
		padding: 20px 0 0 0;
		box-sizing: border-box;
		gap: 15px;

		${Input} {
			max-width: unset;
		}
	}
`;

const TextAreaWrapper = styled.div`
	width: 100%;

	${Input} {
		max-width: unset !important;
	}
`;

const SubmitWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;

	button {
		max-width: 280px;
		width: 100%;
		margin: 0;
		background: ${({theme}) => theme.button.primary.default};
	}

	@media screen and ${media.lessThanSM} {
		justify-content: center;

		button {
			max-width: unset;
		}
	}
`;

export const ContactUs: React.FC = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const {isIdle, isLoading, isSuccess, isLoadingOrSuccess} =
		useSelector(getUserRequestState)("contactUs");
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		type: "",
		question: "",
	});

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLFormElement>) => {
			const name = e.target.name;
			const value = String(e.target.value);

			if (!name) {
				return;
			}

			setFormData({...formData, [name]: value});
		},
		[formData]
	);

	const selectAdapter = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onChange(e as unknown as React.ChangeEvent<HTMLFormElement>);
		},
		[onChange]
	);

	useEffect(() => {
		if (user?.id) {
			setFormData({
				...formData,
				name: `${user.firstName} ${user.lastName}`,
				email: user.email,
			});
		}
		// Disabled due need update form only if user updated, formData as dependency will affect loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (isSuccess) {
			setFormData({
				name: user ? `${user.firstName} ${user.lastName}` : "",
				email: user?.email || "",
				type: "",
				question: "",
			});
		}
	}, [isSuccess, user]);

	const onSubmit = useCallback(
		(e: React.SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();
			dispatch(contactUsRequest(formData));
		},
		[dispatch, formData]
	);

	const disableSubmit = isLoadingOrSuccess || formData.question === "";

	return (
		<Form onChange={onChange} onSubmit={onSubmit}>
			<Input
				name="name"
				label="Full Name"
				value={formData.name}
				disabled={Boolean(user?.firstName || user?.lastName)}
				required={true}
			/>
			<Input
				name="email"
				label="Email Address"
				value={formData.email}
				disabled={Boolean(user?.email)}
				required={true}
			/>
			<Input
				select
				name="type"
				label="Question Type"
				value={formData.type}
				onChange={selectAdapter}
				required={true}>
				<MenuItem value="My Account">My Account</MenuItem>
				<MenuItem value="Logging in/Registration">Logging in/Registration</MenuItem>
				<MenuItem value="My Team">My Team</MenuItem>
				<MenuItem value="My Leagues">My Leagues</MenuItem>
				<MenuItem value="Leaderboards">Leaderboards</MenuItem>
				<MenuItem value="Other">Other</MenuItem>
			</Input>
			<TextAreaWrapper>
				<Input
					name="question"
					label="Your Question"
					value={formData.question}
					multiline={true}
					rows={7}
					placeholder="300 characters"
					inputProps={{maxLength: 300}}
					required={true}
				/>
			</TextAreaWrapper>

			<SubmitWrapper>
				<PrimaryButton disabled={disableSubmit}>
					<Exist when={isIdle}>Submit</Exist>
					<Exist when={isLoading}>Sending....</Exist>
					<Exist when={isSuccess}>Sent</Exist>
				</PrimaryButton>
			</SubmitWrapper>
		</Form>
	);
};
