import {createSelector} from "reselect";
import {IRankingsGameDay, IStore} from "modules/types";
import {memoize} from "lodash";
import {RequestStateType} from "modules/types/enums";

const getState = ({rankingsGameDay}: IStore) => rankingsGameDay;
export const getLeaderboardGameDay = createSelector(getState, (state) => state.leaderboardData);

export const getRankingsGameDayRequestState = createSelector(getState, (state) => {
	return memoize((key: keyof IRankingsGameDay["requestState"]) => {
		return {
			isIdle: state.requestState[key] === RequestStateType.Idle,
			isLoading: state.requestState[key] === RequestStateType.Loading,
			isSuccess: state.requestState[key] === RequestStateType.Success,
			isError: state.requestState[key] === RequestStateType.Error,
		};
	});
});

export const getTeamGameDayGameBar = createSelector(getState, ({gameBar}) => {
	return {
		round_rank: gameBar.round_rank ?? "-",
		overall_rank: gameBar.overall_rank ?? "-",
		round_points: gameBar.round_points ?? "-",
		overall_points: gameBar.overall_points ?? "-",
	};
});
