import React from "react";
import {useSelector} from "react-redux";
import {getMatchesById, getPlayerById, getPlayerStats} from "modules/selectors";
import {getPlayerPositionMap} from "modules/utils";
import styled from "styled-components";
import {Exist, IconChecked, IconRemove} from "components";
import {media} from "assets/media";

const Wrapper = styled.div`
	width: 100%;
	overflow: auto;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	min-width: 100%;

	@media screen and ${media.lessThanMD} {
		.sticky {
			left: 0;
			position: sticky;
			z-index: 9999;
			background: #ffffff;

			&.second {
				left: 64px;
				padding-right: 12px;

				&:after {
					content: "";
					position: absolute;
					right: -20px;
					top: 0;
					width: 20px;
					height: 100%;
					opacity: 0.55;
					background: linear-gradient(90deg, #dddddd 0%, rgba(238, 238, 238, 0) 100%);
				}
			}
		}
		td,
		th {
			min-width: 64px;
		}
	}
`;

const Thead = styled.thead`
	border-bottom: 1px solid #dddddd;

	tr th {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 17px;
		padding: 10px 0;
		text-align: center;
	}
`;

const TBody = styled.tbody`
	tr {
		border-bottom: 1px solid #dddddd;

		td {
			color: ${({theme}) => theme.text_color};
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 17px;
			padding: 10px 0;
			text-align: center;

			&.empty {
				padding: 12px 0;
				font-weight: 400;
			}
		}
	}
`;

interface IProps {
	playerId: number;
}

export const PlayerDetails: React.FC<IProps> = ({playerId}) => {
	const player = useSelector(getPlayerById)(playerId);
	const stats = useSelector(getPlayerStats);
	const matches = useSelector(getMatchesById);
	const {isGoalkeeper, isMidfielder, isForward} = getPlayerPositionMap(player?.position);
	const isMidfielderOrForward = isMidfielder || isForward;

	const getOpposition = (id: number) => {
		const match = matches[id];

		if (!match) {
			return "-";
		}

		const isHome = match.home_squad_id === player.squadId;
		return isHome ? match.away_squad_name : match.home_squad_name;
	};

	const isStatsVisible = stats.length !== 0 && Array.isArray(stats);

	return (
		<Wrapper className="detail">
			<Table>
				<Thead>
					<tr>
						<th className="sticky">GW</th>
						<th className="sticky second">Opposition</th>
						<th>Points</th>
						<th>1s XI</th>
						<th>SUB</th>
						<Exist when={!isGoalkeeper}>
							<th>Goals</th>
							<th>Assists</th>
						</Exist>
						<Exist when={!isMidfielderOrForward}>
							<th>CS</th>
						</Exist>
						<th>OGs</th>
						<th>YCs</th>
						<th>RCs</th>
					</tr>
				</Thead>
				<TBody>
					<Exist when={stats.length === 0}>
						<tr>
							<td className="empty" colSpan={11}>
								Nothing to show here...
							</td>
						</tr>
					</Exist>
					<Exist when={isStatsVisible}>
						{stats.map((statItem) => (
							<tr key={statItem.match_id}>
								<td className="sticky">{statItem.number}</td>
								<td className="sticky second">
									{getOpposition(statItem.match_id)}
								</td>
								<td>{statItem.points}</td>
								<td>
									<Exist when={statItem.stats.SXI}>
										<IconChecked color="#067F06" />
									</Exist>
									<Exist when={!statItem.stats.SXI}>
										<IconRemove color="#A82727" />
									</Exist>
								</td>
								<td>
									<Exist when={statItem.stats.SUB_IN}>
										<IconChecked color="#067F06" />
									</Exist>
									<Exist when={!statItem.stats.SUB_IN}>
										<IconRemove color="#A82727" />
									</Exist>
								</td>
								<Exist when={!isGoalkeeper}>
									<td>{statItem.stats.GS}</td>
									<td>{statItem.stats.AS}</td>
								</Exist>
								<Exist when={!isMidfielderOrForward}>
									<td>{statItem.stats.CS}</td>
								</Exist>
								<td>{statItem.stats.OG}</td>
								<td>{statItem.stats.YC}</td>
								<td>{statItem.stats.RC}</td>
							</tr>
						))}
					</Exist>
				</TBody>
			</Table>
		</Wrapper>
	);
};
