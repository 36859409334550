import {
	fetchMRECSuccess,
	fetchNotificationBarSuccess,
	IMRECSuccess,
	INotificationBar,
} from "modules/actions";
import {createReducer} from "redux-act";

export interface IJsons {
	notificationBar: INotificationBar[];
	mrec: IMRECSuccess[];
}

const defaultNotification = [
	{
		sport: "",
		isEnabled: false,
		message: "",
	},
];

const defaultMREC = [
	{
		sport: "mfl",
		type: "leaderboard",
		desktopImage: "",
		mobileImage: "",
		url: "",
	},
];

const initialState: IJsons = {
	notificationBar: defaultNotification,
	mrec: defaultMREC,
};

export const jsons = createReducer<IJsons>({}, initialState)
	.on(fetchNotificationBarSuccess, (state, payload) => {
		return {
			...state,
			notificationBar: payload,
		};
	})
	.on(fetchMRECSuccess, (state, payload) => ({
		...state,
		mrec: payload,
	}));
