import React from "react";
import {useSelector} from "react-redux";
import {
	getAutoFillRequestState,
	getIsHeadsWereChanged,
	getIsTeamSaved,
	getTeamRequestState,
	isTeamFullFilledGameDay,
} from "modules/selectors";
import {Exist} from "components/Exist";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {someTrue} from "modules/utils";

interface IProps {
	saveTeamHandle: () => void;
	clearTeam: () => void;
	autoFillHandler: () => void;
}
export const NonLockoutButtons: React.FC<IProps> = ({
	saveTeamHandle,
	clearTeam,
	autoFillHandler,
}) => {
	const autofillState = useSelector(getAutoFillRequestState);
	const saveTeamRequestState = useSelector(getTeamRequestState)("saveTeam");
	const isTeamFull = useSelector(isTeamFullFilledGameDay);
	const isTeamSaved = useSelector(getIsTeamSaved);
	const isHeadsHasChanges = useSelector(getIsHeadsWereChanged);
	const isSaveDisabled = someTrue([!isTeamFull, isTeamSaved, saveTeamRequestState.isLoading]);
	const isAutoDisabled = someTrue([autofillState.isLoading, saveTeamRequestState.isLoading]);

	return (
		<React.Fragment>
			<Exist when={isTeamFull}>
				<SecondaryButton onClick={clearTeam} disabled={isAutoDisabled}>
					Clear All
				</SecondaryButton>
			</Exist>

			<Exist when={!isTeamFull}>
				<SecondaryButton onClick={autoFillHandler} disabled={isAutoDisabled}>
					Auto fill
				</SecondaryButton>
			</Exist>

			<Exist when={!isHeadsHasChanges}>
				<PrimaryButton disabled={isSaveDisabled} onClick={saveTeamHandle}>
					Save
				</PrimaryButton>
			</Exist>
			<Exist when={isHeadsHasChanges}>
				<PrimaryButton onClick={saveTeamHandle}>Save</PrimaryButton>
			</Exist>
		</React.Fragment>
	);
};
