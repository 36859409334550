import React from "react";
import {useSelector} from "react-redux";
import {getPlayerMatchFixture} from "modules/selectors";
import styled from "styled-components";
import {IMatch} from "modules/types/json";
import {SquadLogo} from "components/SquadLogo";
import {Nullable} from "modules/types";
import {DateTime} from "luxon";
import {media} from "assets/media";

const Wrapper = styled.div``;

interface IProps {
	playerId: number;
}

export const PlayerFixtures: React.FC<IProps> = ({playerId}) => {
	const fixtures = useSelector(getPlayerMatchFixture);

	return (
		<Wrapper>
			{fixtures.map((fixture) => (
				<Fixture key={fixture.id} fixture={fixture} />
			))}
		</Wrapper>
	);
};

const SFixture = styled.div`
	border-bottom: 1px solid #dddddd;
	gap: 12px;
	padding: 17px 26px 12px 26px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	> div {
		max-width: 33%;
		width: 100%;
	}
`;

const Stadium = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 14px;

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 10px;
		letter-spacing: 0;
		line-height: 12px;
		margin: 0;
	}

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		padding-left: 12px;
		margin: 0;
		font-weight: normal;
	}

	> div {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;

		img {
			height: 30px;
			width: auto;
		}
	}

	&.reverse {
		align-items: flex-end;

		> div {
			flex-direction: row-reverse;
		}

		h3 {
			text-align: right;
		}
	}

	@media screen and ${media.lessThanMD} {
	}
`;

const Info = styled.div`
	p {
		color: ${({theme}) => theme.text_color};
		text-transform: capitalize;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		text-align: center;
	}
`;

const Fixture: React.FC<{fixture: IMatch}> = ({fixture}) => {
	const fixtureDate = new Date(fixture.date);
	const dateTime = DateTime.fromJSDate(fixtureDate, {zone: "Asia/Hong_Kong"});

	const leftDate = dateTime.toFormat("ccc d MMM");
	const rightDate = dateTime.toFormat("T");

	const hasScore = (score: Nullable<number> | undefined) => {
		return score === null || score === undefined;
	};

	const homeScore = hasScore(fixture.home_score) ? " " : fixture.home_score;
	const awayScore = hasScore(fixture.away_score) ? " " : fixture.away_score;

	return (
		<SFixture>
			<Stadium className="stadium">
				<p>{leftDate}</p>
				<div>
					<SquadLogo squadId={fixture.home_squad_id} />
					<h3>{fixture.home_squad_name}</h3>
				</div>
			</Stadium>
			<Info>
				<p>{fixture.venue_name}</p>
				<p>
					{homeScore}-{awayScore}
				</p>
			</Info>
			<Stadium className="stadium reverse">
				<p>{rightDate} MYT</p>
				<div>
					<SquadLogo squadId={fixture.away_squad_id} />
					<h3>{fixture.away_squad_name}</h3>
				</div>
			</Stadium>
		</SFixture>
	);
};
