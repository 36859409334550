import {createReducer} from "redux-act";
import {ITrade} from "modules/types";
import {fetchTradeHistorySuccess} from "modules/actions";

export interface ITransferHistory {
	trades: ITrade[];
}

const initial: ITransferHistory = {
	trades: [],
};

export const tradeHistory = createReducer<ITransferHistory>({}, initial).on(
	fetchTradeHistorySuccess,
	(state, payload) => ({
		...state,
		trades: payload,
	})
);
