import React, {useCallback, useEffect, useMemo, useState} from "react";
import {PlayersPoolBody, PlayersPoolContainer, PlayersPoolHeader} from "./styled";
import {useSelector} from "react-redux";
import {getActivePlayers, getTeamRemainingSalary, isGameDayGame} from "modules/selectors";
import {IPoolFilters} from "modules/types/team";
import {List, ListRowProps, AutoSizer} from "react-virtualized";
import {PlayerPollHeader, PlayersPollRow} from "components";
import {PoolFilters} from "./PoolFilters";
import {partial} from "lodash";
import {getFilteredPlayers, orderComparator} from "modules/utils/players";
import {SALARY_CUP_DELIMITER} from "modules/constants";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/media";
import {PlayerGameDayPoolRow} from "components/Team/PlayerPoll/PlayerGameDayPoolRow";

const initialFilters: IPoolFilters = {
	search: "",
	position: "",
	country: "",
	price: "",
};

interface IProps {
	pickedPosition?: number;
}

export const PlayerPoll: React.FC<IProps> = ({pickedPosition, ...props}) => {
	const players = useSelector(getActivePlayers);
	const [filters, setFilters] = useState(initialFilters);
	const affordablePrice = useSelector(getTeamRemainingSalary) / SALARY_CUP_DELIMITER;
	const isMobile = useMediaQuery(media.lessThanMD);
	const poolHeight = isMobile ? 600 : 1000;
	const isGameDay = useSelector(isGameDayGame);

	const [sortBy, setSortBy] = useState({
		sortBy: "cost",
		order: false,
	});

	const updateSortOnCLick = useCallback(
		(e: React.SyntheticEvent<HTMLElement>) => {
			const sortValue = e.currentTarget.dataset.sort;
			if (!sortValue) {
				return;
			}

			setSortBy({
				sortBy: sortValue,
				order: !sortBy.order,
			});
		},
		[sortBy, setSortBy]
	);

	useEffect(() => {
		if (pickedPosition) {
			setFilters((prevFilters) => ({
				...prevFilters,
				position: pickedPosition.toString(),
			}));
		}
	}, [pickedPosition]);

	const updateFilter = useCallback(
		(data: IPoolFilters) => {
			setFilters(data);
		},
		[setFilters]
	);

	const filteredPlayers = useMemo(() => {
		return getFilteredPlayers(players, {
			...filters,
			price: filters.price === "affordable" ? `0-${affordablePrice}` : filters.price,
		}).sort(partial(orderComparator, sortBy));
	}, [players, filters, sortBy, affordablePrice]);

	const playerRowRenderer = ({index, key, style}: ListRowProps) => {
		const props = {
			style,
			key,
			sortBy,
			playerId: filteredPlayers[index].id,
		};
		return isGameDay ? <PlayerGameDayPoolRow {...props} /> : <PlayersPollRow {...props} />;
	};

	return (
		<PlayersPoolContainer {...props}>
			<PlayersPoolHeader>Select your team from the player pool</PlayersPoolHeader>
			<PoolFilters updateFilter={updateFilter} filters={filters} />
			<PlayerPollHeader
				playersLength={filteredPlayers.length}
				sortBy={sortBy}
				updateSortOnCLick={updateSortOnCLick}
			/>
			<PlayersPoolBody>
				<AutoSizer defaultHeight={poolHeight}>
					{({height, width}) => (
						<List
							rowCount={filteredPlayers.length}
							rowHeight={45}
							rowRenderer={playerRowRenderer}
							height={height}
							width={width}
						/>
					)}
				</AutoSizer>
			</PlayersPoolBody>
		</PlayersPoolContainer>
	);
};
