import React, {useCallback} from "react";
import styled from "styled-components";
import {Exist, LockoutTimer, TeamCaptainSelect, TeamStats} from "components";
import {useDispatch, useSelector} from "react-redux";
import {
	getRounds,
	getSelectedRound,
	getSport,
	getTeamForGame,
	isGameDayGame,
} from "modules/selectors";
import {toggleEditTeamNameModal} from "modules/actions";
import {PureButton} from "components/styled";
import {useMediaQuery} from "@mui/material";
import {ShareTarget, ShareType} from "modules/types/share";
import {getLinkForShare, getShareText, share} from "modules/utils/Share";
import {useIsPathIncludes} from "modules/utils/hooks";
import {media} from "assets/media";

import FacebookIcon from "assets/images/Facebook.svg";
import TwitterIcon from "assets/images/Twitter.svg";
import shareIcon from "assets/images/icons/share.png";
import {GameWeekSelector} from "./GameWeekSelector";
import {GameDayTeamStats} from "components/Team/GameDayTeamStats";
import {RoundStatus} from "modules/types/enums";

const Wrapper = styled.div``;

const TeamHeadInfo = styled.div`
	box-sizing: border-box;
	display: flex;
	border-radius: 5px 5px 0 0;
	background: linear-gradient(
		225deg,
		${({theme}) => theme.gradient.start} 0%,
		${({theme}) => theme.gradient.stop} 99.97%
	);
	padding: 20px 19px;
	gap: 12px;
	justify-content: space-between;

	h2 {
		color: #ffffff;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		margin: 0;
	}

	button {
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		text-decoration: none;
		align-self: flex-end;

		&.edit-button {
			position: relative;
			top: -4px;
			left: 2px;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.team-name {
		display: flex;
		gap: 8px;
		align-items: center;

		@media screen and ${media.lessThanMD} {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0;

			h2 {
				font-size: 20px !important;
				font-weight: normal !important;
			}

			button {
				left: unset;
				top: unset;
				text-align: left;
				font-size: 10px !important;
				letter-spacing: 0;
				line-height: 12px;
				align-self: unset;
			}
		}
	}
	@media screen and ${media.lessThanMD} {
		border-radius: 0;
	}
`;

const SocialButton = styled(PureButton)`
	cursor: pointer;
	width: 40px;
	height: 40px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center !important;
	margin: 0;

	img {
		width: auto;
		height: 100%;
	}
`;

const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const TeamCaptainSelectWrapper = styled.div`
	padding: 8px 20px;
`;

export const TeamInfo: React.FC = () => {
	const dispatch = useDispatch();
	const team = useSelector(getTeamForGame);
	const rounds = useSelector(getRounds);
	const isUserHasTeam = Boolean(team?.isCompleted);
	const selectedRound = useSelector(getSelectedRound);
	const isMobile = useMediaQuery(media.lessThanMD);
	const isCaptainsWidth = useMediaQuery("(max-width: 750px)");
	const isTransferFlag = useIsPathIncludes("transfers");
	const openModal = useCallback(() => {
		dispatch(toggleEditTeamNameModal(true));
	}, [dispatch]);
	const isGameDay = useSelector(isGameDayGame);

	const teamName = team?.name || "Nameyourteam";
	return (
		<Wrapper>
			<TeamHeadInfo>
				<div className="team-name">
					<h2>{teamName}</h2>
					<Exist when={selectedRound && selectedRound.status !== RoundStatus.Complete}>
						<PureButton className="edit-button" onClick={openModal}>
							Edit
						</PureButton>
					</Exist>
				</div>
				<ActionWrapper>
					<Exist when={isUserHasTeam}>
						<ShareComponent />
					</Exist>
					<Exist when={isGameDay && rounds.length > 0}>
						<GameWeekSelector />
					</Exist>
				</ActionWrapper>
			</TeamHeadInfo>
			<Exist when={isGameDay}>
				<GameDayTeamStats />
			</Exist>
			<Exist when={!isGameDay}>
				<TeamStats />
			</Exist>

			<Exist when={!isGameDay && isCaptainsWidth && !isTransferFlag}>
				<TeamCaptainSelectWrapper>
					<TeamCaptainSelect />
				</TeamCaptainSelectWrapper>
			</Exist>
			<Exist when={isMobile}>
				<LockoutTimer />
			</Exist>
		</Wrapper>
	);
};

const ShareComponent: React.FC = () => {
	const isMobile = useMediaQuery(media.lessThanMD);
	const isNavigatorAccessible = "share" in navigator && isMobile;
	const sport = useSelector(getSport);

	const shareHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const target = e.currentTarget.dataset.target;

		if (!target) {
			return;
		}

		share({
			target: target as ShareTarget,
			type: ShareType.General,
			sport,
		});
	};

	const shareNative = useCallback(async () => {
		try {
			const url = getLinkForShare({type: ShareType.General}, ShareType.General);
			const text = getShareText(ShareType.General, "", sport);

			const shareData = {url, text};
			await navigator.share(shareData);
		} catch (err) {
			console.log(err);
		}
	}, [sport]);

	if (isNavigatorAccessible) {
		return (
			<SocialButton onClick={shareNative}>
				<img src={shareIcon} alt="shareIcon" />
			</SocialButton>
		);
	}

	return (
		<React.Fragment>
			<SocialButton data-target={ShareTarget.Facebook} onClick={shareHandler}>
				<img src={FacebookIcon} alt="FacebookIcon" />
			</SocialButton>
			<SocialButton data-target={ShareTarget.Twitter} onClick={shareHandler}>
				<img src={TwitterIcon} alt="TwitterIcon" />
			</SocialButton>
		</React.Fragment>
	);
};
