import DOMPurify from "dompurify";
import {PlayerPosition} from "modules/types/enums";
import {IPlayerForStats} from "modules/types/json";
import {IFilter, ISortBy, Nullable} from "modules/types";
import {sortBy} from "lodash";

export const currencyFormatDecimal = (value: number, withoutCurrency: boolean = false) => {
	if (!value) {
		return "0";
	}

	let letter = "";
	let abs = Math.abs(value);

	const million = Math.pow(10, 6);
	const thousand = Math.pow(10, 3);

	const is_number_thousand = abs < million && abs >= thousand;
	const is_number_million = abs >= million;

	if (is_number_thousand) {
		abs = abs / thousand;
		letter = "k";
	} else if (is_number_million) {
		abs = abs / million;
		letter = "m";
	}

	// abs = round10(abs, -1);
	const currency = withoutCurrency ? "" : "€";
	return `${currency}${abs}${letter}`;
};

export const purify = (input: string | undefined, props: DOMPurify.Config = {}) => {
	if (!input) {
		return "";
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
	return String(DOMPurify.sanitize(input, props));
};

export const getSplitString = (input: string | number): string => {
	const value = String(input).split(".");
	value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return value.join(".");
};

export const getPlayerPositionMap = (position: number | undefined) => {
	if (position === undefined) {
		return {
			isGoalkeeper: false,
			isDefender: false,
			isMidfielder: false,
			isForward: false,
		};
	}

	return {
		isGoalkeeper: position === PlayerPosition.Goalkeeper,
		isDefender: position === PlayerPosition.Defender,
		isMidfielder: position === PlayerPosition.Midfielder,
		isForward: position === PlayerPosition.Forward,
	};
};

export const statsCentreSort = <T>(items: T[], sort: ISortBy): T[] => {
	if (sort.order) {
		return sortBy<T>(items, sort.sortBy);
	}
	return sortBy<T>(items, sort.sortBy).reverse();
};

export const statsCenterFilterByName = (players: IPlayerForStats[], name: Nullable<string>) => {
	if (!name) {
		return players;
	}

	return players.filter((player) =>
		player.mediaName.toLocaleLowerCase().includes(name.toLocaleLowerCase())
	);
};

export const statsCentrePlayerSortBySelects = (players: IPlayerForStats[], sort: IFilter) => {
	if (!sort.team && !sort.position) {
		return players;
	}

	console.log(players.filter((player) => !sort.team || player.squadId === Number(sort.team)));
	console.log(players, sort.team);
	return players
		.filter((player) => !sort.position || Number(player.position) === Number(sort.position))
		.filter((player) => !sort.team || player.squadId === Number(sort.team));
};

export const allTrue = (items: boolean[]) => items.every((val) => val);
export const someTrue = (items: boolean[]) => items.some((val) => val);

export const formatGameWeek = (roundNo: number | null) => {
	if (roundNo === 11) {
		return `11 & 12`;
	}
	return roundNo;
};
