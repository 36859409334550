import React, {useCallback, useEffect} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
	ModalHeaderTitle,
	ModalText,
} from "components/Modals/styled";
import {IconRemove} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import {closeRemoveLeagueGameDayUserModal} from "modules/actions/modals";
import {SecondaryButton, WarningButton} from "components/styled";
import styled from "styled-components";
import warningImage from "assets/images/icons/warning.png";
import {
	getLeagueGameDayRequestStateByKey,
	getRemoveLeagueGameDayUserModalState,
} from "modules/selectors";
import {leagueGameDayRemoveUserRequest} from "modules/actions";
import {Exist} from "components/Exist";
import {CircularPreloaded} from "components/Preloader";

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;

	img {
		padding-top: 20px;
	}
`;

export const RemoveLeagueUserGameDayModal: React.FC = () => {
	const modalData = useSelector(getRemoveLeagueGameDayUserModalState);
	const {isLoading, isSuccess} = useSelector(getLeagueGameDayRequestStateByKey)(
		"removeLeagueUser"
	);
	const dispatch = useDispatch();
	const closeModal = useCallback(() => {
		dispatch(closeRemoveLeagueGameDayUserModal());
	}, [dispatch]);

	const removePlayer = useCallback(() => {
		const {userId, leagueId} = modalData.data;

		if (!userId || !leagueId) {
			closeModal();
			return;
		}

		dispatch(
			leagueGameDayRemoveUserRequest({
				userId: Number(userId),
				leagueId: Number(leagueId),
			})
		);
	}, [closeModal, modalData, dispatch]);

	useEffect(() => {
		if (isSuccess) {
			closeModal();
		}
	}, [isSuccess, closeModal]);

	if (!modalData.isShow) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerWrapper>
					<img src={warningImage} alt="warning" />
					<ModalHeaderTitle>Are you sure you want to remove this user?</ModalHeaderTitle>
					<ModalText>
						You are about to remove {modalData.data.userName} from your league. <br />
						Are you sure you want to do this? This action cannot be reversed.
					</ModalText>
				</InnerWrapper>
				<ModalActions>
					<SecondaryButton disabled={isLoading} onClick={closeModal}>
						No, cancel
					</SecondaryButton>
					<WarningButton disabled={isLoading} onClick={removePlayer}>
						<Exist when={isLoading}>
							<CircularPreloaded size={16} color="#FFFFFF" />
						</Exist>
						<Exist when={!isLoading}>Yes, remove user</Exist>
					</WarningButton>
				</ModalActions>
			</ModalContent>
		</ModalBackdrop>
	);
};
