import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsUserLogged, getProject, getSport} from "modules/selectors";
import {ILocationState} from "modules/types";
import {ProjectType, SportType} from "modules/types/enums";

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const project = useSelector(getProject) ?? ProjectType.Classic;
	const sport = useSelector(getSport) ?? SportType.MSL;

	const loggedInLink = `/${project}/${sport}/team`;
	const backURL = String((location.state as ILocationState)?.from || loggedInLink);
	const isLoggedIn = useSelector(getIsUserLogged);

	if (!backURL && isLoggedIn) {
		return <Navigate to={loggedInLink} />;
	}

	if (isLoggedIn) {
		return <Navigate to={backURL} />;
	}
	return <Outlet />;
};
