import styled from "styled-components";
import {media} from "assets/media";
import React from "react";

const StatsWrapper = styled.div`
	border-right: 1px solid #dddddd;
	padding-left: 9px;
	padding-right: 40px;

	&:last-of-type {
		border-right: none;
		padding-right: 0;
	}

	h4 {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		font-weight: normal;
		margin: 0 0 4px 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 19px;
		margin: 0;
	}

	@media screen and ${media.lessThanSM} {
		padding-right: 24px;
		h4 {
			font-size: 12px;
		}

		p {
			font-size: 14px;
		}
	}
`;

interface IStatsProps {
	title: string;
	value: string | number;
}

export const StatsBlock: React.FC<IStatsProps> = ({title, value}) => {
	return (
		<StatsWrapper>
			<h4>{title}</h4>
			<p>{value}</p>
		</StatsWrapper>
	);
};
