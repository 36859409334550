import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {media} from "assets/media";
import {TextField} from "@mui/material";

export const Main = styled.main`
	min-height: calc(100vh - 245px - 132px);
`;

export const getButtonStyle = css`
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	background: ${({theme}) => theme.button.primary.default};
	border: 1px solid ${({theme}) => theme.button.primary.default};
	color: ${({theme}) => theme.button.primary.color};
	max-width: 168px;
	padding: 14px 30px;
	margin: 0 auto;
	text-decoration: none;
	transition: 0.25s;

	&:hover,
	&:active,
	&:focus {
		cursor: pointer;
		background: ${({theme}) => theme.button.primary.hover};
		border-color: ${({theme}) => theme.button.primary.hover};
	}

	&:disabled {
		background: ${({theme}) => theme.button.primary.disabled} !important;
		border-color: ${({theme}) => theme.button.primary.disabled_border} !important;
		color: ${({theme}) => theme.button.primary.disabled_color} !important;
		pointer-events: none !important;
		opacity: ${({theme}) => theme.button.disabled_opacity} !important;
	}
`;

export const PrimaryButton = styled.button`
	${getButtonStyle};
	padding: 14px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 4px;

	&.blue {
		background: ${({theme}) => theme.button.primary_blue.background};
		border-color: ${({theme}) => theme.button.primary_blue.background};
		color: ${({theme}) => theme.button.primary_blue.color};
	}

	&.w-220 {
		max-width: 220px;
		width: 100%;
	}

	@media screen and ${media.lessThanMD} {
		&.w-220 {
			max-width: unset;
		}
	}
`;

export const SecondaryButton = styled.button`
	${getButtonStyle};
	background: ${({theme}) => theme.button.secondary.default};
	border: 1px solid ${({theme}) => theme.button.secondary.default};
	color: ${({theme}) => theme.button.secondary.color};
	border-color: ${({theme}) => theme.button.primary.default};

	padding: 14px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 4px;

	&:hover,
	&:active,
	&:focus {
		color: ${({theme}) => theme.button.primary.color};
	}

	&.active {
		background: ${({theme}) => theme.button.primary.default};
		color: ${({theme}) => theme.button.primary.color};
	}

	&.w-220 {
		max-width: 220px;
		width: 100%;
	}

	&:disabled {
		background: ${({theme}) => theme.button.secondary.default} !important;
		border: 1px solid ${({theme}) => theme.button.secondary.default} !important;
		color: ${({theme}) => theme.button.secondary.color} !important;
		border-color: ${({theme}) => theme.button.primary.default} !important;
		opacity: ${({theme}) => theme.button.disabled_opacity} !important;
	}

	@media screen and ${media.lessThanMD} {
		&.w-220 {
			max-width: unset;
		}
	}
`;

export const WarningButton = styled.button`
	${getButtonStyle};
	background: ${({theme}) => theme.button.warning.default};
	border: 1px solid ${({theme}) => theme.button.warning.default};
	color: ${({theme}) => theme.button.warning.color};

	padding: 14px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 4px;

	&:hover,
	&:active,
	&:focus {
		background: ${({theme}) => theme.button.warning.hover};
		border-color: ${({theme}) => theme.button.warning.hover};
		color: ${({theme}) => theme.button.warning.color};
	}
`;

export const Container = styled.div`
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
`;

export const PaddingContainer = styled.div`
	width: 100%;
	max-width: 1236px;
	margin: 0 auto;
	padding: 0 18px;
	box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
	width: 100%;
	max-width: 1380px;
	margin: 0 auto;
	padding: 0 18px;
	box-sizing: border-box;
`;

export const HeaderNavLink = styled(NavLink)`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 28px;
	height: 100%;
	text-decoration: none;
	letter-spacing: 0;

	color: ${({theme}) => theme.header.color};
	transition: 0.25s;
	white-space: nowrap;
	font-size: 16px;
	text-align: center;

	&.active,
	&:hover,
	&:active,
	&:focus {
		background-color: ${({theme}) => theme.header.active_bg_color};
		color: ${({theme}) => theme.header.active_color};
	}

	&.active {
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);

			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;

			border-bottom: 10px solid ${({theme}) => theme.header.active_color};
		}
	}

	@media screen and (max-width: 1080px) {
		padding: 0 22px;
	}

	&.align-right {
		margin-left: auto;
		color: #fff;
	}
`;

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

export const FirstLevelExternalNavLink = styled.a`
	padding: 18px;
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${({theme}) => theme.header.menu.opened_item_color};
	color: #ffffff;
	text-decoration: none;
	border-bottom: 1px solid #ffffff;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;

	&:active,
	&:focus {
		color: ${({theme}) => theme.header.menu.second_menu_color};
	}
`;

export const FirstLevelNavLink = styled(NavLink)`
	padding: 18px;
	flex: 1;
	display: flex;
	background: ${({theme}) => theme.header.menu.opened_item_color};
	color: #ffffff;
	text-decoration: none;
	border-bottom: 1px solid #ffffff;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;

	&.active {
		background-color: ${({theme}) => theme.header.active_bg_color};
		color: ${({theme}) => theme.header.active_color};
	}

	&:active,
	&:focus {
		color: ${({theme}) => theme.header.menu.second_menu_color};
	}
`;

export const SecondLevelNavLink = styled(FirstLevelNavLink)`
	padding-left: 36px;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 18px;
	font-weight: normal;

	&:not(:last-of-type) {
		border-bottom: none;
	}

	+ button {
		background: ${({theme}) => theme.header.menu.second_menu_color};
	}

	&:active,
	&:focus {
		color: ${({theme}) => theme.header.menu.second_menu_color};
	}
`;

export const PrimaryLink = styled(NavLink)`
	${getButtonStyle};
`;

export const SecondaryLink = styled(NavLink)`
	${getButtonStyle};
	background: ${({theme}) => theme.button.secondary.default};
	color: ${({theme}) => theme.button.primary.default};

	&:hover {
		background: ${({theme}) => theme.button.primary.default};
		border-color: ${({theme}) => theme.button.primary.default};
		color: ${({theme}) => theme.button.secondary.default};
	}

	&.active {
		background: ${({theme}) => theme.button.primary.default};
		border-color: ${({theme}) => theme.button.primary.default};
		color: ${({theme}) => theme.button.secondary.default};
	}
`;

export const LogoWrapper = styled.div`
	box-sizing: border-box;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding-top: 14px;
	gap: 20px;

	p {
		color: #ffffff;
		font-size: 10px;
		letter-spacing: 0;
		line-height: 12px;
		margin: 0 0 4px 0;
	}

	@media screen and (max-width: 1000px) {
		padding-top: 0;

		p {
			font-size: 8px;
			margin-bottom: 0;
		}
	}
`;

export const HeaderLogo = styled(NavLink)`
	img {
		height: 50px;
		width: auto;
	}

	@media screen and (max-width: 1000px) {
		img {
			width: auto;
			height: 36px;
		}
	}
`;

export const MockSponsorLogo = styled.img`
	@media screen and (max-width: 1000px) {
		height: 24px;
		width: auto;
	}
`;

export const TextFieldControl = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 80px;

	&.sized {
		max-width: 300px;
		width: 100%;
		margin: 0 auto;
	}

	&.checkbox {
		min-height: unset;
		margin-bottom: 18px;

		svg {
			color: ${({theme}) => theme.primary};
		}

		label.MuiFormControlLabel-root {
			display: flex;
			align-items: flex-start;

			> span {
				padding: 0 9px;
			}
		}

		&.center-align {
			label.MuiFormControlLabel-root {
				align-items: center;
			}
		}

		.MuiTypography-root {
			color: ${({theme}) => theme.text_color};
			font-size: 14px;
			letter-spacing: 0;
			line-height: 18px;
		}
	}

	&.start-justify {
		justify-content: flex-start;
		margin: 0;
	}
`;

export const ErrorText = styled.div`
	color: ${({theme}) => theme.button.warning.default};
	font-size: 0.75rem;
	line-height: 1.66;
	letter-spacing: 0.03333em;
	font-weight: bold;
	text-align: left;

	&.center {
		text-align: center;
	}
`;

export const SubmitButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;

	button {
		${getButtonStyle};
		max-width: 300px;
		width: 100%;
		margin: 20px 0 20px 0;

		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
	}
`;

export const NavigateToAnotherAuthPageLink = styled.p`
	color: ${({theme}) => theme.text_color};
	font-size: 14px;
	letter-spacing: 0;
	line-height: 18px;
	text-align: center;

	a,
	button {
		text-decoration: underline;
		color: ${({theme}) => theme.text_color};
	}
`;

export const TwoSideWrapper = styled(PaddingContainer)`
	display: flex;
	justify-content: space-between;
	gap: 44px;

	@media screen and (max-width: ${media.md}) {
		flex-direction: column;
		align-items: center;
		padding: 0;
		gap: 16px;

		&.reversed {
			flex-direction: column-reverse;
		}
	}
`;

export const TwoSideMainBlock = styled.div`
	box-sizing: border-box;
	max-width: 785px;
	padding: 0 20px;
	width: 100%;

	@media screen and ${media.lessThanMD} {
		&.no-padding {
			padding-left: 0;
			padding-right: 0;
		}
	}
`;

export const TwoSideTitle = styled.h1`
	color: ${({theme}) => theme.text_color};
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 29px;

	@media screen and ${media.lessThanMD} {
		font-size: 20px;
		line-height: 24px;
		padding: 0 20px;
	}
`;

export const TwoSideBar = styled.div`
	box-sizing: border-box;
	max-width: 370px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&.player-poll {
		padding-bottom: 20px;
	}

	@media screen and ${media.lessThanMD} {
		&.player-poll {
			padding-bottom: 0;
		}
	}
`;

export const AuthLogo = styled.img`
	height: 120px;
	width: 84px;
	display: block;
	margin: 0 auto 24px auto;
`;
export const FieldWrapper = styled.div`
	padding: 25px 20px;
	border: 1px solid #ddd;

	@media (max-width: 700px) {
		padding: 0;
		border: none;
		margin-bottom: 0;
	}
`;

export const Input = styled(TextField)`
	width: 100%;
	font-family: "Lato", sans-serif !important;

	* {
		font-family: "Lato", sans-serif !important;
	}

	.MuiFormHelperText-root {
		margin-bottom: 10px;
	}

	.MuiOutlinedInput-input {
		padding: 12px 14px 14px 14px;
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: ${({theme}) => theme.input.border} !important;
	}

	.Mui-disabled {
		opacity: 0.5;
	}

	legend,
	label {
		color: ${({theme}) => theme.input.label} !important;
	}

	.MuiInputLabel-root[data-shrink="false"] {
		top: -4px;
	}

	@media screen and ${media.lessThanMD} {
		.MuiOutlinedInput-input {
			padding: 12px 14px 12px 14px;
		}

		.MuiOutlinedInput-root {
			padding: 0 !important;
		}

		.MuiInputAdornment-root {
			margin-right: 12px;
		}
	}
`;

export const RadioListWrapper = styled.div`
	.MuiRadio-root {
		padding: 4px 0;

		> span {
			color: ${({theme}) => theme.text_color};
		}

		.MuiSvgIcon-root {
			width: 16px;
			height: 16px;
		}
	}

	.MuiTypography-root {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin-left: 6px;
	}

	.MuiFormControlLabel-root {
		margin-left: 0;
		margin-right: 0;
	}
`;

export const PreloaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
