import {createAction} from "redux-act";

export interface INotificationBar {
	sport: string;
	isEnabled: boolean;
	message: string;
}

export interface INotification {
	notifications: INotificationBar[];
}

export interface IMRECSuccess {
	sport: string;
	type: string;
	desktopImage: string;
	mobileImage: string;
	url: string;
}

export const fetchNotificationBar = createAction();
export const fetchNotificationBarSuccess = createAction<INotificationBar[]>();
export const fetchNotificationBarFailed = createAction<Error>();

export const fetchMREC = createAction();
export const fetchMRECSuccess = createAction<IMRECSuccess[]>();
export const fetchMRECFailed = createAction<Error>();
